import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Button } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import WorkspaceSwitcher from '../../../components/WorkspaceSwitcher';
import ProductSwitcher from '../../../components/ProductSwitcher';

import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';
import * as subscriptionActions from '../../../models/subscription/actions';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const { translate } = useLocales();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);

  const activeSiyahaSubscription = activeSubscriptions?.find((sub) => sub.solution === 'siyaha');
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const currentTenantRole = parseInt(localStorage.getItem('tenant_role'), 10);
  const userRoles = [currentTenantRole];

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const selectedProduct = localStorage.getItem('selected_product');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    dispatch(subscriptionActions.getActiveSubscriptionRequest());
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        {isDesktop && !isCollapse && (
          <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
        )}
      </Stack>

      <ProductSwitcher isCollapse={isCollapse} />
      {/* <NavbarAccount isCollapse={isCollapse} /> */}

      <Stack
        spacing={1}
        sx={{
          pt: 2,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <WorkspaceSwitcher isCollapse={isCollapse} />
      </Stack>
      <Stack
        spacing={3}
        sx={{
          pt: 1,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        {/* if selected_product = maktb */}

        {selectedProduct === 'maktb' && !isCollapse && activeMaktbSubscription?.is_trial && (
          <Button
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={() => {
              navigate(`/cart/maktb`);
            }}
          >
            {translate('navbar.upgradeButton')}
          </Button>
        )}

        {selectedProduct === 'siyaha' && !isCollapse && activeSiyahaSubscription?.is_trial && (
          <Button
            color="siyaha"
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={() => {
              navigate(`/cart/siyaha`);
            }}
          >
            {translate('navbar.upgradeButton')}
          </Button>
        )}
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} userRoles={userRoles} />

      <Box sx={{ flexGrow: 1 }} />

      <Stack
        spacing={3}
        sx={{
          pt: 1,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              background: isCollapse ? 'white' : 'radial-gradient(circle at center, transparent 0, white 100%)',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
