import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { selectors as dealSelectors } from '../../../models/deals/reducers';
import useLocales from '../../../hooks/useLocales';
import { Loader } from '../../../components/Loader';

import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';

const AddListingsModal = ({ formik, open, onClose, showSearchbar = true, isBuy = true, title }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();

  const createLoading = useSelector(dealSelectors.createLoading);

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const residentialOnly = [
    { value: 'tower_apartment', text: translate('deal.properties.tower_apartment') },
    { value: 'building_apartment', text: translate('deal.properties.building_apartment') },
    { value: 'villa_apartment', text: translate('deal.properties.villa_apartment') },
    { value: 'villa_floor', text: translate('deal.properties.villa_floor') },
    { value: 'townhouse', text: translate('deal.properties.townhouse') },
    { value: 'duplex', text: translate('deal.properties.duplex') },
    { value: 'villa', text: translate('deal.properties.villa') },
    { value: 'mansion', text: translate('deal.properties.mansion') },
    { value: 'farm', text: translate('deal.properties.farm') },
    { value: 'istraha', text: translate('deal.properties.istraha') },
  ];

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: 1, p: 2, minWidth: '320px' }}>
        <Typography sx={{ p: 2, mb: 4 }} variant="h5">
          {title}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth sx={{ mt: 2, mb: 2 }} error={formik.touched.type && formik.errors.type}>
            <InputLabel id="deal-type" shrink>
              {translate('deal.realEstateType')}
            </InputLabel>
            <Select
              labelId="deal-type"
              input={<OutlinedInput notched name="type" label={translate('deal.realEstateType')} />}
              value={formik.values.type}
              onChange={formik.handleChange}
            >
              {residentialOnly.map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button
            disabled={createLoading}
            variant="outlined"
            sx={{ color: 'black', borderColor: '#919EAB66', mr: 2 }}
            onClick={onClose}
          >
            {translate('deal.cancelButton')}
          </Button>

          <Button
            disabled={!formik.isValid || createLoading}
            variant="contained"
            sx={{ color: 'white' }}
            onClick={formik.submitForm}
          >
            {!createLoading && translate('deal.createButton')}
            {createLoading && <Loader />}
          </Button>
        </Box>
      </Box>

      {!showSearchbar && activeMaktbSubscription?.is_trial && (
        <Box
          style={{
            backgroundColor: theme.palette.grey[300],
            paddingTop: 15,
            paddingBottom: 15,
            textAlign: 'center',
            color: 'black',
          }}
        >
          <Typography variant="h7" paragraph>
            {translate('addListingsModal.trialMessage')}
          </Typography>
          <Button
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={() => {
              navigate(`/cart/siyaha`);
            }}
          >
            {translate('navbar.upgradeButton')}
          </Button>
        </Box>
      )}
    </Dialog>
  );
};
export default AddListingsModal;
