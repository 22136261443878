// @mui
import { Container, Typography, Box, Grid, Card } from '@mui/material';
// hooks
import { useNavigate } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';
import useLocales from '../../hooks/useLocales';

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

export default function Website() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const navigate = useNavigate();

  //
  return (
    <Page title="Website">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('website')}
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                mt: 2,
                py: 2,
                textAlign: 'start',
                px: 4,
                cursor: 'pointer',
                borderRadius: '7px',
                border: 'solid rgba(255, 255, 255, 0.0)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.4)', // Example hover background color
                  border: 'solid white',
                },
              }}
              onClick={() => {
                navigate('domain-settings');
              }}
            >
              <Typography variant="subtitle2" color={'secondary'}>
                {translate('web.domain.title')}
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.64 }}>
                {translate('web.domain.subtitle')}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                mt: 2,
                py: 2,
                textAlign: 'start',
                px: 4,
                cursor: 'pointer',
                borderRadius: '7px',
                border: 'solid rgba(255, 255, 255, 0.0)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.4)',
                  border: 'solid white',
                },
              }}
              onClick={() => {
                navigate('social-media-links');
              }}
            >
              <Typography variant="subtitle2" color={'secondary'}>
                {translate('web.socialMediaLinks.title')}
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.64 }}>
                {translate('web.socialMediaLinks.subtitle')}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
