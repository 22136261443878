import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
  loading: false,
  createLoading: false,
  updateLoading: false,
  deals: null,
  totalDeals: null,
  deal: null,
  stages: {
    new: {
      data: [],
      page: 0,
      lastPage: 1,
      total: 0,
      loading: false,
    },
    visit: {
      data: [],
      page: 0,
      lastPage: 1,
      total: 0,
      loading: false,
    },
    negotiation: {
      data: [],
      page: 0,
      lastPage: 1,
      total: 0,
      loading: false,
    },
    won: {
      data: [],
      page: 0,
      lastPage: 1,
      total: 0,
      loading: false,
    },
    lost: {
      data: [],
      page: 0,
      lastPage: 1,
      total: 0,
      loading: false,
    },
  },
};

const reducer = createReducer(initialState, {
  [actions.getDealsRequest]: (state) => {
    state.loading = true;
  },
  [actions.getDealsSuccess]: (state, action) => {
    state.loading = false;
    state.deals = action.payload.data;
    state.totalDeals = action.payload.meta.total;
  },
  [actions.getDealsFailure]: (state) => {
    state.loading = false;
  },
  [actions.loadMoreDealsRequest]: (state, action) => {
    const { stage } = action.payload;
    state.stages[stage].loading = true;
  },
  [actions.loadMoreDealsSuccess]: (state, action) => {
    const { stage } = action.payload;
    state.stages[stage].loading = false;
    state.stages[stage].total = action.payload.data.meta.total;
    state.stages[stage].lastPage = action.payload.data.meta.last_page;
    state.stages[stage].page = action.payload.data.meta.current_page;
    state.stages[stage].data = [...state.stages[stage].data, ...action.payload.data.data];
    state.stages[stage].data = state.stages[stage].data.filter(
      (deal, index, self) => index === self.findIndex((t) => t.id === deal.id)
    );
  },
  [actions.loadMoreDealsFailure]: (state, action) => {
    const { stage } = action.payload;
    state.stages[stage].loading = false;
  },
  [actions.editDealRequest]: (state, action) => {
    state.updateLoading = true;

    let previousStage = action.payload.from;

    if (!previousStage) {
      // eslint-disable-next-line no-restricted-syntax
      for (const stage in state.stages) {
        if (state.stages[stage].data.find((deal) => deal.id === action.payload.id)) {
          previousStage = stage;
          break;
        }
      }
    }

    if (previousStage && previousStage !== action.payload.stage) {
      const dealData = state.stages[previousStage].data.find((deal) => deal.id === action.payload.id);

      state.stages[previousStage].data = state.stages[previousStage].data.filter(
        (deal) => deal.id !== action.payload.id
      );
      state.stages[previousStage].total -= 1;

      state.stages[action.payload.stage].data = [dealData, ...state.stages[action.payload.stage].data];
      state.stages[action.payload.stage].total += 1;
    }
  },
  [actions.editDealSuccess]: (state, action) => {
    state.updateLoading = false;
    state.deal = action.payload.data;

    if (!state.deals) {
      return;
    }

    const dealIndex = state.deals.findIndex((deal) => deal.id === action.payload.data.id);

    if (dealIndex && dealIndex !== -1) {
      state.deals[dealIndex] = action.payload.data;
    }
  },
  [actions.editDealFailure]: (state) => {
    state.updateLoading = false;
  },
  [actions.getDealByIdRequest]: (state) => {
    state.loading = true;
  },
  [actions.getDealByIdSuccess]: (state, action) => {
    state.loading = false;
    state.deal = action.payload.data;
  },
  [actions.getDealByIdFailure]: (state) => {
    state.loading = false;
  },
  [actions.createDealSuccess]: (state, action) => {
    state.createLoading = false;
    state.deal = action.payload.data;
  },
  [actions.createDealFailure]: (state) => {
    state.createLoading = false;
  },
  [actions.createDealRequest]: (state) => {
    state.createLoading = true;
  },

  [actions.toggleArchiveDealRequest]: (state) => {
    state.loading = true;
  },
  [actions.toggleArchiveDealSuccess]: (state, action) => {
    state.loading = false;
    state.deal = action.payload.data;
  },
  [actions.toggleArchiveDealFailure]: (state) => {
    state.loading = false;
  },
});

const loading = (state) => state.deals.loading;
const createLoading = (state) => state.deals.createLoading;
const updateLoading = (state) => state.deals.updateLoading;
const deals = (state) => state.deals.deals;
const totalDeals = (state) => state.deals.totalDeals;
const deal = (state) => state.deals.deal;
const stages = (state) => state.deals.stages;

const selectors = {
  createLoading,
  updateLoading,
  loading,
  deals,
  totalDeals,
  deal,
  stages,
};

export { initialState, reducer, selectors };
export default reducer;
