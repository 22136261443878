import { useEffect } from 'react';

// @mui
import { Container, Typography } from '@mui/material';
// hooks
import { useNavigate } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';
import useLocales from '../../hooks/useLocales';

// components
import Page from '../../components/Page';

import { ROLES } from '../../utils/constants';

// ----------------------------------------------------------------------

export default function Expired() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const navigate = useNavigate();

  useEffect(() => {
    if (parseInt(localStorage.getItem('tenant_role'), 10) === ROLES.companyOwner) {
      console.log('im owner');
      navigate('/subscription/plans');
    }
  });
  return (
    <Page title={translate('expired')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          {translate('expired')}
        </Typography>
      </Container>
    </Page>
  );
}
