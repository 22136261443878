import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  CardHeader,
  Link,
  IconButton,
  Tooltip,
} from '@mui/material';

import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';

import useLocales from '../../hooks/useLocales';
import Iconify from '../../components/Iconify';

const commonFieldsProps = {
  InputLabelProps: {
    shrink: true,
  },
};

const RequestForm = ({ formik = false }) => {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const { purpose, type, propertyType } = formik.values;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        '& .MuiFormHelperText-root': {
          textAlign: 'end',
        },
      }}
    >
      <Grid item xs={12} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      {translate('contact.information')}
                    </Typography>
                    {!activeMaktbSubscription?.is_trial && (
                      <Link href={`/maktb/crm/contacts/${formik.values.contact.id}`} underline="hover">
                        <Tooltip title={translate('contact.navigateToContact')}>
                          <IconButton variant="outlined" color="secondary">
                            <Iconify icon="icon-park-outline:share" />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    )}
                  </Box>
                }
              />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="client"
                      label={translate('deal.clientName')}
                      value={formik.values.contact.name}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="mobile_number"
                      label={translate('deal.clientMobileNumber')}
                      value={formik.values.contact.mobile_number}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={4} />

                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="purpose"
                      label={translate('deal.purpose')}
                      value={translate(`deal.${formik.values.purpose}`)}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="category"
                      label={translate('deal.category')}
                      value={translate(`deal.${formik.values.category}`)}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="type"
                      label={translate('deal.listingType')}
                      value={translate(`deal.${formik.values.propertyType}`)}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    {formik.values.districts?.map((district) => (
                      <Chip
                        key={district.id}
                        sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                        label={district.name_ar}
                      />
                    ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph>
                  {translate('request.message')}
                </Typography>
                <Box>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      {...commonFieldsProps}
                      name="note"
                      value={formik.values.note}
                      disabled
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph>
                  {translate('deal.preferredFeatures')}
                </Typography>

                <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                  <Grid container spacing={6}>
                    {purpose === 'rent' && (
                      <Grid item xs={12} lg={4}>
                        <FormControl fullWidth error={formik.touched.type && formik.errors.rent_period}>
                          <InputLabel id="deal-period" shrink>
                            {translate('deal.period')}
                          </InputLabel>
                          <Select
                            labelId="deal-period"
                            value={formik.values.rent_period || 'daily'}
                            onChange={formik.handleChange}
                            input={<OutlinedInput notched name="rent_period" label={translate('deal.period')} />}
                          >
                            <MenuItem value="daily">{translate('deal.daily')}</MenuItem>
                            <MenuItem value="weekly">{translate('deal.weekly')}</MenuItem>
                            <MenuItem value="monthly">{translate('deal.monthly')}</MenuItem>
                            <MenuItem value="quarterly">{translate('deal.quarterly')}</MenuItem>
                            <MenuItem value="semi_annually">{translate('deal.semiAnnually')}</MenuItem>
                            <MenuItem value="annually">{translate('deal.annually')}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item xs={12} lg={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="min_price"
                        label={translate('deal.minPrice')}
                        value={formik.values.min_price}
                        error={formik.touched.min_price && formik.errors.min_price}
                        onChange={formik.handleChange}
                        helperText={translate('Saudi Riyals')}
                        fullWidth
                      />
                      {formik.errors.min_price ? <div>{formik.errors.min_price}</div> : null}
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="max_price"
                        label={translate('deal.maxPrice')}
                        value={formik.values.max_price}
                        error={formik.touched.max_price && formik.errors.max_price}
                        onChange={formik.handleChange}
                        helperText={translate('Saudi Riyals')}
                        fullWidth
                      />
                      {formik.errors.max_price ? <div>{formik.errors.max_price}</div> : null}
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <FormControl fullWidth>
                        <InputLabel id="deal-facade" shrink>
                          {translate('unit.facade')}
                        </InputLabel>
                        <Select
                          label="Facade"
                          labelId="deal-facade"
                          value={formik.values.facade}
                          error={formik.touched.facade && formik.errors.facade}
                          onChange={formik.handleChange}
                          fullWidth
                          input={<OutlinedInput notched name="facade" label={translate('unit.facade')} />}
                        >
                          <MenuItem value="north">{translate('unit.north')}</MenuItem>
                          <MenuItem value="east">{translate('unit.east')}</MenuItem>
                          <MenuItem value="east_west">{translate('unit.eastWest')}</MenuItem>
                          <MenuItem value="west">{translate('unit.west')}</MenuItem>
                          <MenuItem value="south">{translate('unit.south')}</MenuItem>
                          <MenuItem value="north_east">{translate('unit.northEast')}</MenuItem>
                          <MenuItem value="north_west">{translate('unit.northWest')}</MenuItem>
                          <MenuItem value="north_south">{translate('unit.northSouth')}</MenuItem>
                          <MenuItem value="south_east">{translate('unit.southEast')}</MenuItem>
                          <MenuItem value="south_west">{translate('unit.southWest')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="min_area"
                        label={translate('deal.minArea')}
                        value={formik.values.min_area}
                        error={formik.touched.min_area && formik.errors.min_area}
                        onChange={formik.handleChange}
                        helperText={translate('sqm')}
                        fullWidth
                      />
                      {formik.errors.min_area ? <div>{formik.errors.min_area}</div> : null}
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="max_area"
                        label={translate('deal.maxArea')}
                        value={formik.values.max_area}
                        error={formik.touched.max_area && formik.errors.max_area}
                        onChange={formik.handleChange}
                        helperText={translate('sqm')}
                        fullWidth
                      />
                      {formik.errors.max_area ? <div>{formik.errors.max_area}</div> : null}
                    </Grid>

                    {listingType !== 'land' && (
                      <>
                        <Grid item xs={12} lg={4}>
                          <TextField
                            {...commonFieldsProps}
                            name="bedrooms"
                            label={translate('unit.bedrooms')}
                            value={formik.values.bedrooms}
                            error={formik.touched.bedrooms && formik.errors.bedrooms}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                          {formik.errors.bedrooms ? <div>{formik.errors.bedrooms}</div> : null}
                        </Grid>

                        <Grid item xs={12} lg={4}>
                          <TextField
                            {...commonFieldsProps}
                            name="bathrooms"
                            label={translate('unit.bathrooms')}
                            value={formik.values.bathrooms}
                            error={formik.touched.bathrooms && formik.errors.bathrooms}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                          {formik.errors.bathrooms ? <div>{formik.errors.bathrooms}</div> : null}
                        </Grid>
                      </>
                    )}

                    {purpose === 'rent' && <></>}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                  {translate('request.statusTitle')}
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      {...commonFieldsProps}
                      name="type"
                      label={translate('request.status')}
                      value={formik.values.status && translate(`request.${formik.values.status}`)}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  {formik.values.status === 'closed' && (
                    <Grid item xs={12}>
                      <TextField
                        {...commonFieldsProps}
                        name="closing_reason"
                        label={translate('request.closingReason')}
                        value={formik.values.closing_reason}
                        disabled
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </Grid>
                  )}

                  {formik.values.status === 'closed' && (
                    <Grid item xs={12}>
                      <TextField
                        {...commonFieldsProps}
                        name="purpose"
                        label={translate('request.closedBy')}
                        value={formik.values.actioned_by.name}
                        disabled
                        fullWidth
                      />
                    </Grid>
                  )}

                  {formik.values.status === 'converted' && (
                    <Grid item xs={12}>
                      <TextField
                        {...commonFieldsProps}
                        name="purpose"
                        label={translate('request.convertedBy')}
                        value={formik.values.actioned_by.name}
                        disabled
                        fullWidth
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default RequestForm;
