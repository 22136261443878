import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
  loading: false,
  projects: null,
  totalProjects: null,
  project: null,
  search: null,
  filteredProjects: null,
  getProjectsLoading: false,
};

const reducer = createReducer(initialState, {
  [actions.getProjectsRequest]: (state) => {
    state.getProjectsLoading = true;
  },
  [actions.getProjectsSuccess]: (state, action) => {
    state.getProjectsLoading = false;
    state.projects = action.payload.data;
    state.totalProjects = action.payload.meta.total;
  },
  [actions.getProjectsFailure]: (state) => {
    state.getProjectsLoading = false;
  },
  [actions.editProjectRequest]: (state) => {
    state.loading = true;
  },
  [actions.editProjectSuccess]: (state, action) => {
    state.loading = false;
    state.project = action.payload.data;
  },
  [actions.editProjectFailure]: (state) => {
    state.loading = false;
  },
  [actions.searchProjectsRequest]: (state) => {
    state.loading = true;
  },
  [actions.searchProjectsSuccess]: (state, action) => {
    state.loading = false;
    state.search = action.payload.data;
  },
  [actions.searchProjectsFailure]: (state) => {
    state.loading = false;
  },
  [actions.filterProjectsRequest]: (state) => {
    state.loading = true;
  },
  [actions.filterProjectsSuccess]: (state, action) => {
    state.loading = false;
    state.filteredProjects = action.payload.data;
  },
  [actions.filterProjectsFailure]: (state) => {
    state.loading = false;
  },
  [actions.createProjectRequest]: (state) => {
    state.loading = false;
  },
  [actions.createProjectFailure]: (state) => {
    state.loading = false;
    state.getProjectsLoading = false;
  },
  [actions.createProjectSuccess]: (state, action) => {
    state.loading = false;
    state.project = action.payload.data;
  },
  [actions.getProjectRequest]: (state) => {
    state.loading = true;
  },
  [actions.getProjectFailure]: (state) => {
    state.loading = false;
  },
  [actions.getProjectSuccess]: (state, action) => {
    state.loading = false;
    state.project = action.payload.data;
  },
  [actions.toggleArchiveProjectRequest]: (state) => {
    state.loading = true;
  },
  [actions.toggleArchiveProjectSuccess]: (state, action) => {
    state.loading = false;
    state.project = action.payload.data;
  },
  [actions.toggleArchiveProjectFailure]: (state) => {
    state.loading = false;
  },
});

const loading = (state) => state.projects.loading;
const getProjectsLoading = (state) => state.projects.getProjectsLoading;
const projects = (state) => state.projects.projects;
const totalProjects = (state) => state.projects.totalProjects;
const project = (state) => state.projects.project;
const search = (state) => state.projects.search;
const filteredProjects = (state) => state.projects.filteredProjects;

const selectors = {
  loading,
  project,
  projects,
  totalProjects,
  search,
  filteredProjects,
  getProjectsLoading,
};

export { initialState, reducer, selectors };
export default reducer;
