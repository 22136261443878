import React, { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Card, CardContent, IconButton, Skeleton, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import ImageSelector from '../../../components/ImageSelector';
import useLocales from '../../../hooks/useLocales';
import useAsyncAction from '../../../hooks/useAsyncAction';
import UtilsServices from '../../../models/utils/services';
import PropertiesService from '../../../models/properties/services';
import * as propertiesActions from '../../../models/properties/actions';
import { Loader } from '../../../components/Loader';

const CoverPictureSelector = ({ formik }) => {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const [uploadCover, isUploading] = useAsyncAction(
    async (cover) =>
      // upload here
      UtilsServices.getPresignedUrl({ reference_id: formik.values.id, model: 'property', extension: 'png' })
        .then((response) => response.data)
        .then((data) => {
          setLoading(true);
          UtilsServices.uploadingToAPresignedUrl(data.presigned_url, cover)
            .then((response) => response)
            .then((upload) => {
              setLoading(false);
              formik.setFieldValue('cover_image_url', data.url);
              PropertiesService.setPropertyCover(formik.values.id, { cover_image_url: data.url });
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        })
        .catch((error) => {
          console.error('Error:', error);
        }),
    [formik.values.id, formik.values.cover_image_url]
  );

  const handleCoverUpload = (files) => {
    uploadCover(files[0]);
  };

  const handleCoverRemove = (e, id) => {
    e.stopPropagation();
    console.log('formik.values.cover_image_url', formik.values.cover_image_url);

    setLoading(true);
    PropertiesService.setPropertyCover(id, {
      cover_image_url: null,
    })
      .then((response) => response)
      .then((upload) => {
        setLoading(false);
        formik.setFieldValue('cover_image_url', null);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <ImageSelector
      name="cover"
      error={formik.touched.cover_image_url && formik.errors.cover_image_url}
      value={[]}
      onFiles={handleCoverUpload}
      multiple={false}
    >
      {(isDraggingOver, handleBrowse) => (
        <Card>
          <CardContent>
            <Typography variant="h6" paragraph sx={{ mb: 4 }}>
              {translate('unit.coverPhoto')}
            </Typography>
            <Box
              sx={{
                cursor: 'pointer',
                minHeight: formik.values.cover_image_url ? 'unset' : 250,
                display: 'flex',
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 1,
                border: '1px dashed #BCBCBC',
              }}
              onClick={handleBrowse}
            >
              {isLoading || (isUploading && <Skeleton variant="rectangular" width={1} height={1} />)}
              {(!isLoading || !isUploading) && formik.values.cover_image_url && (
                <>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      fontColor: 'white',
                      color: '#fff',
                      background: '#161C24',
                      opacity: 0.72,
                      height: 30,
                      width: 30,
                      mr: 0.5,
                      mt: 0.5,
                    }}
                    onClick={(e) => handleCoverRemove(e, formik.values.id)}
                  >
                    <CloseRoundedIcon sx={{ height: 16, width: 16 }} />
                  </IconButton>
                  <img
                    style={{
                      borderRadius: 8,
                    }}
                    src={formik.values.cover_image_url}
                    alt="logo"
                  />
                </>
              )}
              {!formik.values.cover_image_url && (
                <Typography sx={{ color: '#637381' }}>
                  {isLoading && <Loader />}

                  {!isLoading && (isDraggingOver ? 'Drop your image' : 'Upload a cover')}
                </Typography>
              )}
            </Box>
            <Typography sx={{ color: '#637381' }}>{translate('unit.coverPhotoFileSize')}</Typography>
          </CardContent>
        </Card>
      )}
    </ImageSelector>
  );
};

export default CoverPictureSelector;
