import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import parsePhoneNumber from 'libphonenumber-js';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography, Container, Tabs, Tab, Divider, Card, CardContent } from '@mui/material';
import { toast } from 'react-toastify';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import useLocales from '../../hooks/useLocales';
import { selectors as contactSelectors } from '../../models/contacts/reducers';
import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';
import * as contactActions from '../../models/contacts/actions';
import ContactForm from './ContactForm';
import DealsList from '../../components/DealsList';
import ContactOwnedPropertiesList from './ContactOwnedPropertiesList';
import ContactPropertyRequestsList from './ContactPropertyRequestsList';
import { Loader } from '../../components/Loader';
import Notes from '../notes/Notes';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const EditContact = () => {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const dispatch = useDispatch();
  const contact = useSelector(contactSelectors.contact);
  const [loading, setLoading] = useState(false);
  const [tabIdx, setTabIdx] = useState(0);

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  /* looks ugly, but without a rerender is better */
  let code = '';
  let phone = '';

  if (contact) {
    const parsedPhoneNumber = parsePhoneNumber(`+${contact.mobile_number}`);
    code = parsedPhoneNumber?.countryCallingCode || '';
    phone = parsedPhoneNumber?.nationalNumber || '';
  }
  const callback = (type) => {
    if (type === 'success') {
      toast.success(translate('addContactScreen.successUpdate'));
      setLoading(false);
    } else {
      toast(translate('Error'), { type: 'error' });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      phone,
      code,
      name: contact?.name || '',
      gender: contact?.gender || '',
      email: contact?.email || '',
      is_property_owner: !!contact?.is_property_owner,
      is_property_buyer: !!contact?.is_property_buyer,
      is_property_renter: !!contact?.is_property_renter,
      is_property_broker: !!contact?.is_property_broker,
      is_property_agent: !!contact?.is_property_agent,
      is_property_representative: !!contact?.is_property_representative,
      note: contact?.note || '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      const { ...rest } = values;
      dispatch(
        contactActions.editContactRequest({
          id: contact.id,
          callback,
          values: rest,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(contactActions.getContactByIdRequest(id));
  }, []);

  const toggleArchive = () => () => {
    dispatch(contactActions.toggleArchiveContactRequest({ id: contact.id, callback }));
  };

  const handleTabChange = (evt, value) => {
    setTabIdx(value);
  };

  return (
    <Page title={translate('contact.information')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
          <Typography variant="h3" component="h1">
            {translate('contacts')}
          </Typography>
          <Box>
            <Button
              onClick={toggleArchive()}
              variant="outlined"
              sx={{
                color: '#999',
                borderColor: '#999',
                marginLeft: 2,
                '&:hover': {
                  color: '#999',
                  borderColor: '#999',
                  backgroundColor: 'transparent',
                },
              }}
              disabled={loading}
            >
              {!loading && translate(contact?.is_archived ? 'contact.unarchiveButton' : 'contact.archiveButton')}
              {loading && <Loader />}
            </Button>
            <Button
              variant="contained"
              sx={{ color: 'white', marginLeft: 2 }}
              onClick={formik.submitForm}
              disabled={loading || !formik.dirty}
            >
              {!loading && translate('contact.saveButton')}
              {loading && <Loader />}
            </Button>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <ContactForm formik={formik} phoneNumberDisabled />
          <Grid item xs={12} lg={4}>
            <Notes id={id} type={'contact'} />
          </Grid>
        </Grid>
        {!activeMaktbSubscription?.is_trial && (
          <Box>
            <Tabs value={tabIdx} onChange={handleTabChange} sx={{ pt: 2 }}>
              <Tab label={`${translate('deals')} (${contact?.total_deals})`} {...a11yProps(0)} />
              <Tab
                label={`${translate('contact.ownedProperties')} (${contact?.total_owned_properties})`}
                {...a11yProps(1)}
              />
              <Tab label={`${translate('requests')} (${contact?.total_property_requests})`} {...a11yProps(2)} />
            </Tabs>

            <Divider sx={{ mb: 2 }} />

            <TabPanel value={tabIdx} index={0}>
              {contact?.total_deals > 0 ? (
                <DealsList id={contact?.id} idKey="tenant_contact_id" />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">{translate('contact.noDeals')}</Typography>
                  </CardContent>
                </Card>
              )}
            </TabPanel>

            <TabPanel value={tabIdx} index={1}>
              {contact?.total_owned_properties > 0 ? (
                <ContactOwnedPropertiesList contactId={contact?.id} />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">{translate('contact.noOwnedProperties')}</Typography>
                  </CardContent>
                </Card>
              )}
            </TabPanel>

            <TabPanel value={tabIdx} index={2}>
              {contact?.total_property_requests > 0 ? (
                <ContactPropertyRequestsList contactId={contact?.id} />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">{translate('contact.noPropertyRequests')}</Typography>
                  </CardContent>
                </Card>
              )}
            </TabPanel>
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default EditContact;
