// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  attachFile: 'Attach file',
  clickToCopy: 'Click to copy',
  copied: 'Copied',
  paidPlan: 'Paid Plan',
  freePlan: 'Free Plan',
  yes: 'Yes',
  no: 'No',
  'Load More': 'Load More',

  cart: {
    paidPlan: 'Paid Plan',
    freePlan: 'Free Plan',
    features: 'Features',
    'Coupon applied successfully': 'Coupon applied successfully',
    'Invalid coupon code': 'Invalid coupon code',
  },
  year: 'Year',
  Jan: 'Jan',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Apr',
  May: 'May',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Aug',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dec',
  loginScreen: {
    mobile_number: 'Mobile number',
    password: 'Password',
    'The password field is required.': 'The password field is required.',
    'The provided credentials are incorrect.': 'The provided credentials are incorrect.',
    'The mobile number field is required.': 'The mobile number field is required.',
  },
  verifyCodeScreen: {
    'Please provide correct code.': 'Please provide correct code.',
    'Attempts exceeded, request another code.': 'Attempts exceeded, request another code.',
    'You cannot send verification now, try again after 1 minute.':
      'You cannot send verification now, try again after 1 minute.',
  },
  resetPasswordScreen: {
    'After checking the mobile number was not found. Kindly register first.':
      'After checking the mobile number was not found. Kindly register first.',
  },
  completeAccountScreen: {
    email: 'Email',
    name: 'Name',
    password: 'Password',
    mobile_number: 'Mobile number',
    token: 'Session',
    'The mobile number has already been taken.': 'The mobile number has already been used before.',
    'The selected token is invalid.': 'Registration session expired. Please restart the registration process.',
    'The name field is required.': 'The name field is required.',
    'The email field is required.': 'The email field is required.',
    'The password field is required.': 'The password field is required.',
    'The name must not be greater than 50 characters.': 'The name must not be greater than 50 characters.',
    'The email must be a valid email address.': 'The email must be a valid email address.',
    'The email has already been taken.': 'The email has already been used before.',
  },
  addContactScreen: {
    successCreation: 'Client created successfully',
    successUpdate: 'Client information updated successfully',
    'Contact already exists.': 'Contact already exists.',
  },
  navbar: {
    upgradeButton: 'Upgrade 🚀',
  },
  'Save 20% when you pay annually': 'Save 20% when you pay annually',
  '/a month': '/Monthly',
  'Visit your website': 'Visit your website',
  'Activate your Nuzul Business': 'Activate your Nuzul Business 🚀',
  'SAR 199': 'SAR 199',
  '/per user a month': '/per user a month',
  'Buy now': 'Buy now',
  'Your trial will expire on ': 'Your trial will expire on ',
  'Please enter a valid number format (e.g., 25000.34)': 'Please enter a valid number format (e.g., 25000.34)',
  Users: 'Users',
  'Billing period': 'Billing period',
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  Annually: 'Annually',
  SAR: 'SAR',
  Subtotal: 'Subtotal',
  VAT: 'VAT',
  Total: 'Total',
  'Next billing will be': 'Next billing will be',
  'Start now': 'Start now',
  'By activating trial, you agree to the': 'By activating trial, you agree to the',
  'By proceeding, you agree to the': 'By proceeding, you agree to the',
  'Terms and Conditions': 'Terms and Conditions',
  'Saudi Riyals': 'Saudi Riyals',
  'Nuzul Business': 'Nuzul Business',
  'Order Information': 'Order Information',
  'Online payment': 'Online payment',
  'Bank transfer': 'Bank transfer',
  Discount: 'Discount',
  versionNumber: 'v',
  hi: 'Hi',
  'Member Insights Dashboard': 'Member Insights Dashboard',
  logout: 'Logout',
  sqm: 'sqm',
  meter: 'meter',
  unspecified: 'Unspecified',
  listingsViews: 'Website visits',
  growth: 'Growth in ',
  clients: 'Clients',
  total: 'Total',
  export_as_sheet: 'Export as sheet',
  export_as_sheet_count: 'Export ({{count}}) result as sheet',
  Workspaces: 'Account',
  'Coming soon!': 'Coming soon!',
  remove: 'Remove',
  cancel: 'Cancel',
  removalConfirmation: 'Are you sure you want to remove this match?',
  for: {
    sell: 'For sale',
    rent: 'For rent',
  },
  auth: {
    login: {
      heading: 'Log in to Nuzul',
      subtitle: 'Welcome back!',
      countryCode: 'Country code',
      mobileNumber: 'Mobile number',
      password: 'Password',
      noAccount: "Don't have an account?",
      signUp: 'Sign up',
      loginButton: 'Log in',
      mobileExample: 'Example: 501234567',
      forgotPassword: 'Forgot Password?',
    },
    completeAccount: {
      subtitle: 'Account Information',
      name: 'Name',
      email: 'Email',
      createAccountBtn: 'Create account',
      byProceeding: 'By proceeding, you agree to the',
      termsCondition: 'Terms and Conditions',
    },
    registration: {
      heading: 'Activate your digital transformation now 🚀',
      subtitle: '',
      alreadyAccount: 'Already have an account?',
      login: 'Login',
      verifyMobile: 'Verify Mobile',
    },
    forgotPassword: {
      heading: 'Forgot your password?',
      subtitle: 'Please enter the mobile number associated with your account.',
    },
    verifyCode: {
      subtitle: 'Please enter the 4-digit code to {{mobileNumber}}.',
      verificationCode: 'Verification code',
      resendCode: 'Resend code',
      back: 'Back',
      verifyCodeBtn: 'Verify Code',
      noVerificationCode: "Didn't receive the code?",
    },
    passwordReset: {
      heading: 'Password reset',
      subtitle: 'Please enter the new password',
      changePasswordBtn: 'Change password',
      success: 'Password updated successfully!',
    },
  },
  userMenu: {
    yourProfile: 'Your profile',
    yourWorkspaces: 'Your accounts',
    yourInvitations: 'Your invitations',
  },
  subscription: {
    plans: {},
    checkout: {
      title: 'Payment',
      subtitle: 'How would like to pay?',
      payOnline: 'Pay Now',
      payOffline: 'Create Order',
      applyButton: 'Apply',
      couponTitle: 'Got a coupon?',
      couponCode: 'Coupon code',
    },
  },
  units: 'Units',
  unit: {
    downloadAsPdf: 'Download as PDF',
    createdBy: 'Created by',
    sort: 'Sort',
    sortHelperText: 'Higher number will be displayed first',
    views: 'Views',
    apartments: 'Units',
    stores: 'Stores',
    unit_number: 'Unit number',
    commercial: 'Commercial',
    residential: 'Residential',
    information: 'Unit information',
    createUnitButton: 'Create unit',
    archive: 'Archive',
    creationTitle: 'Unit information',
    facts: 'Facts',
    type: 'Type',
    area: 'Area',
    district: 'District',
    city: 'City',
    purpose: 'Purpose',
    rent: 'Rent',
    sell: 'Sell',
    buy: 'Buy',
    publishedStatus: 'Visibility',
    publishedOnWebsite: 'Publish on your website',
    publishedOnNuzulApp: 'Publish on Nuzul map (soon)',
    features: 'Features',
    category: 'Category',
    listingType: 'Type',
    rentingPrice: 'Renting Price',
    monthlyPrice: 'Price monthly',
    quarterlyPrice: 'Price quarterly',
    semiAnnuallyPrice: 'Price semi-annually',
    annuallyPrice: 'Price annually',
    sellingPrice: 'Selling Price',
    photos: 'Photos',
    video: 'Video',
    building: 'Building',
    mansion: 'Mansion',
    duplex: 'Duplex',
    villa: 'Villa',
    tower_apartment: 'Apartment in a tower',
    building_apartment: 'Apartment in a building',
    villa_apartment: 'Apartment in a villa',
    land: 'Land',
    townhouse: 'Townhouse',
    villa_floor: 'Floor in a villa',
    farm: 'Farm',
    istraha: 'Istraha',
    hotel: 'Hotel',
    resort: 'Resort',
    compound: 'Compound',
    store: 'Store',
    office: 'Office',
    storage: 'Storage',
    streetWidthNorth: 'Street width north',
    streetWidthEast: 'Street width east',
    streetWidthWest: 'Street width west',
    streetWidthSouth: 'Street width south',
    facade: 'Facade',
    length: 'Unit length',
    width: 'Unit width',
    north: 'North',
    east: 'East',
    west: 'West',
    south: 'South',
    northSouth: 'North South',
    eastWest: 'East West',
    northEast: 'North East',
    northWest: 'North West',
    southEast: 'South East',
    southWest: 'South West',
    northEastWest: 'North East West',
    northSouthEast: 'North South East',
    northSouthWest: 'North South West',
    southEastWest: 'South East West',
    northSouthEastWest: 'North South East West',
    yearBuilt: 'Year Built',
    owner: 'Owner',
    availability: 'Availability',
    availabilityStatus: 'Availability status',
    coverPhoto: 'Cover Photo',
    coverPhotoFileSize: 'Maximum file size is 500KB, and the file type must be JPG, PNG or JPEG.',
    youtubeVideoUrl: 'YouTube video URL',
    location: 'Location',
    unavailable: 'Unavailable',
    available: 'Available',
    reserved: 'Reserved',
    sold: 'Sold',
    rented: 'Rented',
    soon: 'Soon',
    kingBeds: 'King beds',
    singleBeds: 'Single beds',
    numberOfGuests: 'Guests',
    numberOfKids: 'Kids',
    bedrooms: 'Bedrooms',
    bathrooms: 'Bathrooms',
    floors: 'Floors',
    floorNumber: 'Floor number',
    dailyLivingRoom: 'Daily Living room',
    formalLivingRoom: 'Formal Living room',
    diningRooms: 'Dining room',
    maidRooms: 'Maid rooms',
    driverRooms: 'Driver rooms',
    majlisRooms: 'Majlis',
    storageRooms: 'Storage',
    basement: 'Basment',
    pool: 'Pool',
    balconies: 'Balcony',
    kitchens: 'Kitchen',
    gardens: 'Garden',
    mulhaqRooms: 'Mulhag',
    elevators: 'Elevator',
    parkingBay: 'Parking',
    usageAndServices: 'Usage and services',
    usage: 'Usage',
    services: 'Services',
    electricity: 'Electricity',
    water: 'Water',
    sewage: 'Sewage',
    farming: 'Farming',
    health: 'Health',
    factory: 'Factory',
    education: 'Education',
    changeOwner: 'Change Owner',
    advertiser: {
      title: 'Advertiser information',
      name: 'Advertiser type',
      owner: 'Owner',
      broker: 'Broker',
      advertiser: 'Advertiser',
      licenseNumber: 'License number (from REGA)',
      adNumber: 'Advertisement number (from REGA)',
    },
    archiveButton: 'Archive',
    unarchiveButton: 'Unarchive',
    includeArchived: 'Include archived',
    saveButton: 'Save',
    plotNumber: 'Plot number',
    planNumber: 'Plan number',
    isWafiAd: 'Selling on map? (Wafi)',
    wafiLicenseNumber: 'License number (from Wafi)',
    clearAll: 'Clear all',
    searchLabel: 'Search by ID or unit number',
    navigateToUnit: 'Navigate to this property',
    noDeals: 'No deals for this property.',
    videoAndVirtualTour: 'Video and Virtual tour',
  },
  listings: 'Listings',
  properties: 'Properties',
  projects: 'Projects',
  project: {
    addProject: 'Add Project',
    name: 'Project name',
    information: 'Project information',
    nameEn: 'Project name (English)',
    nameAr: 'Project name (Arabic)',
    descriptionEn: 'Project description (English)',
    descriptionAr: 'Project description (Arabic)',
    features: 'Features',
    area: 'Area',
    plumbingWarranty: 'Plumbing warranty',
    electricWarranty: 'Electric warranty',
    structuralWarranty: 'Structural warranty',
    numberOfUnites: 'number of unites',
    numberOfVillas: 'number of villas',
    numberOfBuildings: 'number of buildings',
    numberOfApartments: 'number of apartments',
    numberOfStores: 'number of stores',
    numberOfTownhouse: 'number of townhouse',
    videoAndPdf: 'Project video and profile',
    projectPdfUrl: 'Project profile link',
    availabilityStatus: 'Availability status',
    units: 'Project units',
    addUnit: 'Add unit',
    addUnits: 'Add units',
    add: 'Add',
    successCreation: 'Project created successfully',
    successUpdate: 'Project information updated successfully',
    archiveButton: 'Archive',
    unarchiveButton: 'Unarchive',
    saveButton: 'Save',
    includeArchived: 'Include archived',
    searchLabel: 'Search by project name',
    views: 'Views',
    district: 'District',
    city: 'City',
    publishedOnWebsite: 'Publish on your website',
    standaloneUrl: 'Project exclusive link',
    navToProjectPage: 'Navigate to project page',
    copyUrl: 'Copy the link',
    copiedUrl: 'Copied!',
    project: 'Project',
    virtualTourUrl: 'Virtual tour url',
    addUnlimitedProjects: 'Add unlimited number of projects',
  },
  dashboard: 'Dashboard',
  membership: 'Membership',
  deals: 'Deals',
  deal: {
    commission: 'Commission',
    amount: 'Amount',
    minArea: 'Minimum area',
    maxArea: 'Maximum area',
    minPrice: 'Minimum price (budget)',
    maxPrice: 'Maximum price (budget)',
    noPropertySelected: 'No Property Selected',
    building: 'Building',
    mansion: 'Mansion',
    duplex: 'Duplex',
    villa: 'Villa',
    building_apartment: 'Apartment in a building',
    villa_apartment: 'Apartment in a villa',
    tower_apartment: 'Apartment in a tower',
    land: 'Land',
    townhouse: 'Townhouse',
    villa_floor: 'Floor in a villa',
    farm: 'Farm',
    istraha: 'Istraha',
    hotel: 'Hotel',
    resort: 'Resort',
    compound: 'Compound',
    store: 'Store',
    office: 'Office',
    storage: 'Storage',
    facts: 'Facts',
    matchedProperty: 'Matched Property',
    selectProperty: 'Select Property',
    searchByUnitNumber: 'Search by unit number',
    filterProperties: 'List only matched units',
    matchedPropertiesInfo:
      "We'll choose the best units for your client based on their requests, desired area and districts. Buy or rent? Is it for residential or commercial purposes? What type of property (apartment, land, etc.)?",
    rentPriceAnnually: 'Rental price annually',
    rentPriceSemiAnnually: 'Rental price semi annually',
    rentPriceQuarterly: 'Rental price quarterly',
    rentPriceMonthly: 'Rental price monthly',
    createDealButton: 'Create deal',
    searchLabel: 'Search',
    searchByNames: 'Search by contact name or sales person',
    contactName: 'Contact',
    information: 'Deal information',
    preferredLocations: 'Preferred locations',
    preferredFeatures: 'Preferred Features',
    saveButton: 'Save',
    clientName: 'Client Name',
    clientMobileNumber: 'Client Mobile Number',
    listingType: 'Listing type',
    period: 'Period',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    semiAnnually: 'Semi-Annually',
    annually: 'Annually',
    property: 'Property',
    stage: 'Stage',
    stageDescription: 'Change deal stage',
    city: 'City',
    purpose: 'Purpose',
    type: 'Type',
    salesPerson: 'Sales Person',
    creationTitle: 'Deal information',
    category: 'Category',
    rent: 'To rent',
    sell: 'To sell',
    buy: 'To buy',
    commercial: 'Commercial',
    residential: 'Residential',
    realEstateType: 'Type',
    cancelButton: 'Cancel',
    createButton: 'Create',
    changeSalesPerson: 'Change Sales Person',
    stages: {
      new: 'New',
      visit: 'Visit',
      negotiation: 'Negotiation',
      won: 'Won',
      lost: 'Lost',
    },
    properties: {
      building: 'Building',
      mansion: 'Mansion',
      duplex: 'Duplex',
      villa: 'Villa',
      building_apartment: 'Apartment in a building',
      villa_apartment: 'Apartment in a villa',
      tower_apartment: 'Apartment in a tower',
      land: 'Land',
      townhouse: 'Townhouse',
      villa_floor: 'Floor in a villa',
      farm: 'Farm',
      istraha: 'Istraha',
      hotel: 'Hotel',
      resort: 'Resort',
      compound: 'Compound',
      store: 'Store',
      office: 'Office',
      storage: 'Storage',
    },
    view: 'View',
    archiveButton: 'Archive',
    unarchiveButton: 'Unarchive',
    includeArchived: 'Include archived',
  },
  guests: 'Guests',
  guest: {
    includeArchived: 'Include archived',
    addGuest: 'Add guest',
    information: 'Guest information',
    createPageTitle: 'Create guest',
    createButton: 'Create guest',
    editGuest: 'Edit guest',
    saveButton: 'Save',
  },
  contacts: 'Clients',
  contact: {
    createdBy: 'Created by',
    searchLabel: 'Search',
    searchByNumLabel: 'Search by name or mobile',
    addContact: 'Create client',
    information: 'Client information',
    typeSelect: 'Select whatever apply',
    createPageTitle: 'Create contact',
    name: 'Full name',
    mobileNumber: 'Mobile number',
    createButton: 'Create client',
    saveButton: 'Save',
    archiveButton: 'Archive',
    unarchiveButton: 'Unarchive',
    includeArchived: 'Include archived',
    type: 'Client type',
    property_buyer: 'Buyer',
    property_renter: 'Renter',
    property_owner: 'Owner',
    property_agent: 'Agent',
    property_broker: 'Broker',
    property_representative: 'Representative',
    email: 'Email (Optional)',
    country: 'Country (Optional)',
    city: 'City (Optional)',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    editContact: 'Edit',
    note: 'Note',
    ownedProperties: 'Owned Properties',
    navigateToContact: 'Navigate to this client',
    noDeals: 'No deals for this contact.',
    noOwnedProperties: 'No owned properties for this contact.',
    noPropertyRequests: 'No property requests for this contact.',
  },
  workspaces: 'Accounts',
  workspaceTitle: 'Account',
  workspace: {
    leave: 'Leave',
    default: 'Default',
    setDefault: 'Set Default',
    settings: 'Settings',
    confirmLeave: 'Confirm Leave',
    generalInformation: 'General Information',
    subscriptions: 'Subscriptions',
    currentPackage: 'Current Package',
    daysLeftOnSubscription: 'Days left on subscription',
    paymentHistory: 'Payment History',
    companyLogo: 'Logo',
    uploadLogo: 'Upload Logo',
    save: 'Save',
    nameEn: 'Account Name (English)',
    nameAr: 'Account Name (Arabic)',
    plans: 'Payment plans',
    currentPlan: 'Current Plan',
    daysLeftOnPlan: 'Days left on plan',
    numberOfUsers: 'Number of users',
    table: {
      status: 'Status',
      period: 'Period',
      amount: 'Amount',
      orderDate: 'Order Date',
      package: 'Package',
      startDate: 'Start Date',
      endDate: 'End Date',
      active: 'Active',
      completed: 'Completed',
      canceled: 'Canceled',
      pending_payment: 'Pending Payment',
      expired: 'Expired',
      yearly: 'Yearly',
      quarterly: 'Quarterly',
      type: ' Type',
      one_time: '-',
      subscription_trial: ' Trial',
      subscription_quarterly: ' Quarterly',
      subscription_yearly: ' Yearly',
      subscription_monthly: ' Monthly',
      total_amount_with_tax: 'Total Amount with Tax',
    },
    day: ' Day',
  },
  invitations: 'Invitations',
  invitation: {
    accepted: 'Accepted',
    declined: 'Declined',
    expired: 'Expired',
    pending: 'Pending',
    accept: 'Accept',
    decline: 'Decline',
    dialog: {
      cancel: 'Cancel',
      heading: 'Are you sure?',
      descriptionAccept: 'By accepting the invitation, you will have access to any information related to the account.',
      descriptionDecline:
        'By declining the invitation, you will NOT have access to any information related to the account.',
    },
  },
  member: {
    inviteMember: 'Invite member',
    members: 'Members',
    invitations: 'Invitations',
    inviteTitle: 'Who are you inviting?',
    invite: 'Invite',
    cancel: 'Cancel',
    removeMemberConfirmation: 'Are you sure you want to remove this member?',
    successRemoveMember: 'Member removed successfully',
    failureRemoveMember: 'Error occurred while removing the member',
    edit: 'Edit',
    successEditMember: "Member's role edited successfully",
    failureEditMember: 'Error occurred while editing the member',
    memberLink: "Member's link on the website",
    card: 'Show membership card',
    saveButton: 'Save',
    editURLSuccessfully: 'The link has been edited successfully',
  },
  team: 'Team',
  teams: {
    mobileNumber: 'Mobile number',
    name: 'Name',
    role: 'Role',
    dateJoined: 'Date joined',
    dateSent: 'Date sent',
    roleChange: 'Change Role',
    remove: 'Remove',
    removeAll: 'Remove the member permanently',
    removeAllInfo: 'The member will be removed from all deals, requests, clients and properties.',
    status: 'Status',
    pending: 'Pending',
    declined: 'Declined',
    canceled: 'Canceled',
    accepted: 'Accepted',
    managerRole: 'Manager',
    associateRole: 'Associate',
    memberRole: 'Member',
    inviteScreen: {
      successCreation: 'Invitation sent successfully',
      'There is a already pending invitation.': 'There is a already pending invitation.',
      'The user is already a member.': 'The user is already a member.',
    },
  },
  general: 'General',
  CRM: 'Clients relations',
  GRM: 'Guest relations',
  'listings & services': 'Listings & Services',
  Financials: 'Financials',
  management: 'Management',
  website: 'Website',
  marketing: 'Marketing',
  requests: 'Property Requests',
  request: {
    editPageTitle: 'Property Request Information',
    addService: 'Add request',
    contactName: 'Client Name',
    new: 'New',
    converted: 'Converted to deal',
    closed: 'Closed',
    status: 'Status',
    facts: 'Facts',
    convertToDeal: 'Convert to deal',
    confirmConvertToDeal: 'Confirm convert to deal',
    confirmCloseRequest: 'Confirm close request',
    cancelButton: 'Cancel',
    closeButton: 'Close request',
    message: 'Client message',
    statusTitle: 'Status',
    closingReason: 'Closing reason',
    closedBy: 'Closed by',
    convertedBy: 'Converted by',
    closeRequestConfirmation: 'Are you sure you want to close this request?',
    convertToDealRequestConfirmation: 'Are you sure you want to convert this request to a deal?',
    closedSuccessfully: 'Request closed successfully',
    convertedSuccessfully: 'Request converted to deal successfully',
  },
  orders: 'Orders',
  sales: 'Sales',
  services: 'Services',
  service: {
    createPageTitle: 'Create service',
    editPageTitle: 'Edit service',
    createButton: 'Add service',
    saveButton: 'Save',
    addService: 'Add service',
    nameAr: 'Service name (Arabic)',
    descriptionAr: 'Service description (Arabic)',
    nameEn: 'Service name (English)',
    descriptionEn: 'Service description (English)',
    informationEn: 'Service information in English',
    informationAr: 'Service information in Arabic',
    price: 'Price (SAR)',
    publishedStatus: 'Visibility',
    publishedOnWebsite: 'Publish on your website',
    publishedOnNuzulApp: 'Publish on Nuzul map (soon)',
    pricing: 'Pricing',
    basePrice: 'Price',
    taxPercentage: 'Tax percentage',
    isFree: 'Free Service',
    successCreation: 'Service created successfully',
    successUpdate: 'Service information updated successfully',
  },
  'SMS Campaigns': 'SMS Campaigns (Soon)',
  web: {
    domain: {
      title: 'Domain name',
      subtitle: 'edit your domain settings',
      information: 'Domain information',
      access: 'Your current website is accessible through the domain name below:',
      contact: 'You can contact us to update your own domain name.',
      option1: 'Option 1',
      option2: 'Option 2',
      email: 'Email',
      whatsapp: 'WhatsApp',
    },
    socialMediaLinks: {
      title: 'Social media links',
      subtitle: 'edit your social media links',
      x: 'X platform',
      instagram: 'Instagram',
      snapchat: 'Snapchat',
      tiktok: 'TikTok',
      whatsapp: 'WhatsApp',
      whatsappFloatingButton: 'Activate WhatsApp floating button',
      email: 'Email',
      phoneNumber: 'Phone number',
      gTagRadio: 'Activate Google Tag Manager',
      googleTagManager: 'Google Tag Manager measurement ID',
      metaPixel: 'Facebook Pixel ID',
      metaPixelRadio: 'Activate Facebook Pixel',
      snapchatPixel: 'Snapchat Pixel ID',
      snapchatPixelRadio: 'Activate Snapchat Pixel',
    },
    licenses: {
      title: 'Government licenses',
      motIwaaNumber: 'MOT/IWAA number',
      motIwaaNumberDescription: 'Issuing authority: Ministry of Turism',
      regaFal: 'REGA/FAL number',
      regaFalDescription: 'Issuing authority: Real Estate General Authority',
      mcCrNumber: 'MCI/MCR number',
      mcCrNumberDescription: 'Issuing authority: Ministry of Commerce',
      zatcaCertificateNumber: 'ZATCA VAT certificate number',
      zatcaCertificateNumberDescription: 'Issuing authority: General Authority of Zakat and Tax',
      hrsdFreelanceNumber: 'HRSD freelance number',
      hrsdFreelanceNumberDescription: 'Issuing authority: Ministry of Human Resources and Social Development',
    },
    integrationTitle: 'Integration',
    integrationInformation: 'Integration information',
    appearance: {
      title: 'Appearance',
      subtitle: 'edit your website appearance',
    },
  },
  settings: 'Settings',
  setting: {
    accountInformationTitle: 'Account Information',
    accountInformation: 'Update Logo and account information',
    accountSubscriptionTitle: 'Subscriptions',
    accountSubscription: 'Manage your subscriptions',
    propertyRequestsTitle: 'Property Requests',
    propertyRequests: 'Manage your property requests features',
    propertyRequest: {
      title: 'Manage your property requests',
      activationTitle: 'Activation',
      publish: 'Publish',
      activationDescription: 'Activate property search request on your website',
      activationListingDescription: 'Activate property listing request on your website',
      editPageTitle: 'Edit request',
      createButton: 'Create request',
      saveButton: 'Save',
      addRequest: 'Add request',
    },
  },
  user: {
    profile: 'Profile',
    personalInformation: 'Personal Information',
    name: 'Name',
    email: 'Email',
    mobileNumber: 'Mobile number',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    saveButton: 'Save',
  },
  contracts: 'Contracts (Soon)',
  District: 'District',
  'Edited stage successfully': 'Edited stage successfully',
  'Successfully updated': 'Successfully updated',
  Districts: 'Districts',
  City: 'City',
  Country: 'Country',
  'Upload a logo': 'Upload a logo',
  'For best resolution and ratio use 500px X 500px as minimum 1:1 ratio. Only PNG and JPG files are supported.':
    'For best resolution and ratio use 500px X 500px as minimum 1:1 ratio. Only PNG and JPG files are supported.',
  'You have reached the limit of properties for your current plan.':
    'You have reached the limit of properties for your current plan.',
  notes: {
    notes: 'Notes',
    notesDescription: 'Add notes to keep track of your work.',
    logNoteButton: 'Log note',
    noteSent: 'Note logged successfully',
    deleteNote: 'Delete note',
    deleteNoteConfirmation: 'Are you sure you want to delete this note?',
    noteDeleted: 'Note deleted successfully',
    editNote: 'Edit Note',
    editSave: 'Save',
    cancelButton: 'Cancel',
    noteUpdated: 'Note Updated successfully',
    noteEdited: 'edited',
  },
  'View All': 'View All',
  Notifications: 'Notifications',
  notifications: {
    deleteSuccess: 'Notification deleted successfully',
    delete: 'Delete',
    deleteAllConfirmation: 'Are you sure you want to delete all notifications?',
    deleteAll: 'Delete all',
    empty: "No new notifications. You're all set!",
    unread: 'You have {{count}} unread notifications',
    markAllAsRead: 'Mark all as read',
    markAsRead: 'Mark as read',
    markAsUnread: 'Mark as unread',
    noteTitle: '📝 Note',
    note: '[{{content}}], by: [{{userName}}]',
    newClientDealTitle: '🏡 New Client Deal',
    newClientDeal: '[{{clientName}}] expressed interest in the [{{propertyType}}] property',
    newClientReservationTitle: '🏡 New Client Reservation',
    newClientReservation: '[{{clientName}}] wants to reserve the [{{propertyType}}] property',
    dealAssignedTitle: '📋 Deal Assigned',
    dealAssigned:
      '[{{clientName}}] has been assigned to you by [{{assignedByName}}] for the [{{propertyType}}] property',
    newPropertyRequestTitle: '🏠 New Property Request',
    newPropertyRequest: 'Client [{{clientName}}] is looking for [{{propertyType}}]',
    tenantInvitationTitle: '✉️ Team Invitation',
    tenantInvitation: 'You have been invited to join the team [{{workspaceName}}]',
    invitationAcceptedTitle: '✅ Invitation Accepted',
    invitationAccepted: 'User [{{userName}}] accepted your invitation to join the team [{{workspaceName}}]',
  },
  siyaha: {
    listingsAndReservations: 'Listings & Reservations',
    units: 'Units',
    reservations: 'Reservations',
    numberOfReservations: 'Number of Reservations',
    numberOfUnits: 'Number of Units',
    reservation: {
      cancelButton: 'Cancel Reservation',
      confirmButton: 'Confirm',
      confirmSuccess: 'Reservation confirmed successfully',
      cancelSuccess: 'Reservation canceled successfully',
      propertyInformation: 'Property Information',
      guestMobileNumber: 'Mobile Number',
      guestEmail: 'Email',
      guestName: 'Guest Name',
      unitNumber: 'Unit Number',
      status: 'Status',
      reservationDetails: 'Reservation Details',
      dates: 'Date',
      checkInDate: 'Check In Date',
      checkOutDate: 'Check Out Date',
      numberOfNights: 'Number of Nights',
      guestsInformation: 'Guests Information',
      dateOfBirth: 'Date of Birth',
      statues: {
        on_hold: 'On Hold',
        pending: 'Pending',
        confirmed: 'Confirmed',
        canceled: 'Canceled',
      },
      calendar: 'Calendar',
    },
    unit: {
      createUnitButton: 'Create unit',
      creationTitle: 'Unit information',
      dailyRatePrice: 'Daily Rate Price',
    },
  },
  createdAt: 'Created at',
  platformFeatures: 'Platform features',
  websiteFeatures: 'Website features',
  APIFeatures: 'API features',
  bestSeller: 'Best seller',
  soon: 'soon',
  plans: {
    basic: 'Basic',
    pro: 'Pro',
    enterprise: 'Enterprise',
  },
  siyahaFeatures: {
    platform: {
      users: 'Unlimited number of users',
      roles: 'Customize user roles',
      properties: 'Unlimited number of properties',
      bookings: 'Unlimited number of bookings',
      requests: 'Unlimited number of requests',
      typesOfProperties: 'Support all types of properties',
      language: 'Support Arabic and English',
      payments: 'Activate online payments',
      whatsapp: 'Activate Whatsapp',
      exportData: 'Export data to excel',
      propertyRequests: 'Activate property requests',
    },
    website: {
      customDomain: 'Custom domain',
      nuzulDomain: 'Nuzul domain www.xxx.nuzul.app',
      specialWebsiteUI: 'Special website & UI design',
    },
    API: {
      chatApps: 'API with chat apps',
      almosaferAPI: 'API with Almosafer',
      bookingAPI: 'API with Booking',
      airbnbAPI: 'API with Airb&b',
      smsApps: 'API with sms apps',
      marketingApps: 'API with marketing apps',
      accountingApps: 'API with accounting apps',
    },
    other: {
      buildingApps: 'Building app in iOS and Android *for an additional fee',
    },
  },
  maktbFeatures: {
    platform: {
      users: 'Unlimited number of users',
      roles: 'Customize user roles',
      properties: 'Unlimited number of properties',
      deals: 'Unlimited number of deals',
      requests: 'Unlimited number of requests',
      typesOfProperties: 'Support all types of properties',
      language: 'Support Arabic and English',
      payments: 'Activate online payments',
      whatsapp: 'Activate Whatsapp',
      exportData: 'Export data to excel',
      propertyRequests: 'Activate property requests',
    },
    website: {
      customDomain: 'Custom domain',
      nuzulDomain: 'Nuzul domain www.xxx.nuzul.app',
      specialWebsiteUI: 'Special website & UI design',
    },
    API: {
      chatApps: 'API with chat apps',
      smsApps: 'API with sms apps',
      marketingApps: 'API with marketing apps',
      accountingApps: 'API with accounting apps',
    },
    other: {
      buildingApps: 'Building app in iOS and Android *for an additional fee',
    },
  },
  search: 'Search',
};

export default en;
