import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
  loading: false,
  reservations: null,
  getReservationsLoading: false,
  reservationDetails: null,
  getReservationDetailsLoading: false,
};

const reducer = createReducer(initialState, {
  [actions.getReservationsRequest]: (state) => {
    state.getReservationsLoading = true;
  },
  [actions.getReservationsSuccess]: (state, action) => {
    state.getReservationsLoading = false;
    state.reservations = action.payload.data;
  },
  [actions.getReservationsFailure]: (state) => {
    state.getReservationsLoading = false;
  },

  [actions.getReservationDetailsRequest]: (state) => {
    state.getReservationDetailsLoading = true;
  },
  [actions.getReservationDetailsSuccess]: (state, action) => {
    state.getReservationDetailsLoading = false;
    state.reservationDetails = action.payload.data;
  },
  [actions.getReservationDetailsFailure]: (state) => {
    state.getReservationDetailsLoading = false;
  },
});

const getReservationsLoading = (state) => state.reservations.getReservationsLoading;
const loading = (state) => state.reservations.loading;
const reservations = (state) => state.reservations.reservations;
const reservationDetails = (state) => state.reservations.reservationDetails;
const getReservationDetailsLoading = (state) => state.reservations.getReservationDetailsLoading;

const selectors = {
  loading,
  reservations,
  getReservationsLoading,
  reservationDetails,
  getReservationDetailsLoading,
};

export { initialState, reducer, selectors };
export default reducer;
