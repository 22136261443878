import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
// @mui
import { Button, Card, CardContent, Container, Grid, TextField, Typography } from '@mui/material';
// hooks
import { useFormik } from 'formik';
import useSettings from '../../../hooks/useSettings';
import useLocales from '../../../hooks/useLocales';

// components
import Page from '../../../components/Page';

import * as workspaceActions from '../../../models/workspace/actions';
import * as userActions from '../../../models/user/actions';

import { selectors as workspaceSelectors } from '../../../models/workspace/reducers';

// ----------------------------------------------------------------------

export default function Subscriptions() {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();

  const workspaceInformationDataLoading = useSelector(workspaceSelectors.workspaceInformationDataLoading);
  const workspaceInformationData = useSelector(workspaceSelectors.workspaceInformationData);

  useEffect(() => {
    dispatch(workspaceActions.getWorkspaceInformationRequest());
  }, []);

  const callback = (type) => {
    if (type === 'success') {
      toast('Success!', { type: 'success' });
      dispatch(workspaceActions.getWorkspaceInformationRequest());
      dispatch(userActions.getUserWorkspacesRequest());
      const tenantIndex = localStorage.getItem('tenant_id');
      dispatch(userActions.getWorkspaceByIdRequest(tenantIndex));
    } else {
      toast('Error!', { type: 'error' });
    }
  };

  const formik = useFormik({
    onSubmit: (values) => {
      dispatch(workspaceActions.editWorkspaceInformationRequest({ ...values, callback }));
    },
    initialValues: {
      name_en: workspaceInformationData?.name_en || '',
      name_ar: workspaceInformationData?.name_ar || '',
      logo_url: workspaceInformationData?.logo_url || '',
    },
    enableReinitialize: true,
  });

  return (
    <Page title={translate('setting.accountSubscriptionTitle')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          {translate('setting.accountSubscriptionTitle')}
        </Typography>
        <Grid container justifyContent={'center'}>
          <Card sx={{ width: '80%', p: 2, mb: 2 }}>
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Typography variant="h4" paragraph>
                  {translate('workspace.generalInformation')}
                </Typography>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}
