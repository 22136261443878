/* eslint-disable import/no-named-as-default */
import { takeLatest, all, call, put } from 'redux-saga/effects';
import * as actions from './actions';
import ProductServices from './services';

export function* getProductsSaga() {
  try {
    const response = yield call(ProductServices.getProducts);
    yield put(actions.getProductsSuccess(response.data));
  } catch (err) {
    yield put(actions.getProductsFailure(err));
  }
}

export function* productSaga() {
  yield all([takeLatest(actions.getProductsRequest, getProductsSaga)]);
}
