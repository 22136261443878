import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  OutlinedInput,
  Divider,
  Tabs,
  Tab,
  Tooltip,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PropertyCard from '../../components/PropertyCard';
import AddPropertiesModal from './AddPropertiesModal';
import LocationSelector from '../../components/LocationSelector';
import CoverPictureSelector from '../properties/CoverPictureSelector';
import NumberInput from '../../components/NumberInput';
import useLocales from '../../hooks/useLocales';
import { ENV, CLIENT_PORT } from '../../config';
import { selectors as userSelectors } from '../../models/user/reducers';
import Iconify from '../../components/Iconify';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 1,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const ProjectForm = ({ formik }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();

  const [markerPosition, setMarkerPosition] = useState(null);

  const [youtubeLink, setYoutubeLink] = useState('');
  const [youtubeId, setYoutubeId] = useState('');
  const [showPropertiesModal, setShowPropertiesModal] = useState(false);
  const [tabIdx, setTabIdx] = useState(0);
  const [copied, setCopied] = useState(false);

  const tenantRole = localStorage.getItem('tenant_role');
  const isDisabled = tenantRole === '6';

  const protocol = ENV === 'local' ? 'http' : 'https';

  const workspace = useSelector(userSelectors.workspace);
  const clientDomain = workspace?.client_domain;

  let url = '';

  if (ENV === 'local') {
    url = `${protocol}://${clientDomain}${CLIENT_PORT}/projects/${formik?.values?.id}?standalone=true`;
  } else {
    url = `${protocol}://${clientDomain}/projects/${formik?.values?.id}?standalone=true`;
  }

  useEffect(() => {
    if (formik.values.latitude && formik.values.longitude) {
      setMarkerPosition({
        lat: parseFloat(formik.values.latitude),
        lng: parseFloat(formik.values.longitude),
      });
    }
  }, [formik.values.longitude, formik.values.latitude]);

  const handleYouTubeChange = (event) => {
    const inputUrl = event.target.value;
    setYoutubeLink(inputUrl);

    // Regular expression to extract the YouTube video ID from various YouTube URL formats
    const youtubeIdRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = inputUrl.match(youtubeIdRegex);

    if (match && match[1]) {
      setYoutubeId(match[1]);
      formik.setFieldValue('youtube_video_url', `https://www.youtube.com/embed/${match[1]}`);
    } else {
      formik.setFieldValue('youtube_video_url', event.target.value);
      setYoutubeId('');
    }
  };

  const handleChangeLocation = ({ districtId }) => {
    formik.setFieldValue('district_id', districtId);
  };

  const updateLatLng = ({ lat, lng }) => {
    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);

    setMarkerPosition({
      lat,
      lng,
    });
  };

  const handleMapClick = (mapEvent) => {
    updateLatLng(mapEvent);
  };

  const onPlaceChanged = (autoComplete) => {
    const place = autoComplete.getPlace();
    const { lat, lng } = place.geometry.location;
    updateLatLng({ lat: lat(), lng: lng() });
  };

  const navigateToPropertyPage = (id) => {
    navigate(`/maktb/properties/${id}`);
  };

  const handleRemoveProperty = (propertyToRemove) => {
    const updatedProperties = formik.values.properties.filter((property) => property.id !== propertyToRemove.id);
    formik.setFieldValue('properties', updatedProperties);
  };

  const handleTabChange = (evt, value) => {
    setTabIdx(value);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const numberInputFeatures = [
    {
      name: 'number_of_buildings',
      label: 'project.numberOfBuildings',
    },
    {
      name: 'number_of_units',
      label: 'project.numberOfUnites',
    },
    {
      name: 'number_of_apartments',
      label: 'project.numberOfApartments',
    },
    {
      name: 'number_of_villas',
      label: 'project.numberOfVillas',
    },
    {
      name: 'number_of_townhouse',
      label: 'project.numberOfTownhouse',
    },
    {
      name: 'number_of_stores',
      label: 'project.numberOfStores',
    },
  ];

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ width: '100%' }}>
      <AddPropertiesModal open={showPropertiesModal} onClose={() => setShowPropertiesModal(false)} formik={formik} />
      <Tabs value={tabIdx} onChange={handleTabChange} sx={{ mx: 3 }}>
        <Tab label={translate('project.information')} {...a11yProps(0)} />
        <Tab label={translate('project.units')} {...a11yProps(1)} />
      </Tabs>
      <Divider sx={{ mx: 3 }} />
      <TabPanel value={tabIdx} index={0}>
        <Grid container spacing={2} sx={{ px: 1 }}>
          <Grid
            item
            xs={12}
            lg={8}
            sx={{
              [theme.breakpoints.down('lg')]: {
                margin: 'auto',
              },
            }}
          >
            {/* info, name and description */}
            <Card>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 3 }}>
                  {translate('project.information')}
                </Typography>

                <Box sx={{ display: 'flex' }}>
                  <TextField
                    name="name_ar"
                    value={formik.values.name_ar}
                    label={translate('project.nameAr')}
                    fullWidth
                    sx={{ mx: 1 }}
                    onChange={formik.handleChange}
                    disabled={isDisabled}
                  />
                  <TextField
                    name="name_en"
                    value={formik.values.name_en}
                    label={translate('project.nameEn')}
                    fullWidth
                    sx={{ mx: 1 }}
                    onChange={formik.handleChange}
                    disabled={isDisabled}
                  />
                </Box>

                <Box sx={{ display: 'flex', paddingTop: 2 }}>
                  <TextField
                    name="description_ar"
                    type="text"
                    multiline
                    rows={6}
                    label={translate('project.descriptionAr')}
                    error={formik.touched.description_ar && formik.errors.description_ar}
                    value={formik.values.description_ar}
                    onChange={formik.handleChange}
                    sx={{ mx: 1 }}
                    fullWidth
                    disabled={isDisabled}
                  />
                  <TextField
                    name="description_en"
                    type="text"
                    multiline
                    rows={6}
                    label={translate('project.descriptionEn')}
                    error={formik.touched.description_en && formik.errors.description_en}
                    value={formik.values.description_en}
                    onChange={formik.handleChange}
                    sx={{ mx: 1 }}
                    fullWidth
                    disabled={isDisabled}
                  />
                </Box>
              </CardContent>
            </Card>
            {/* info, name and description */}

            {/* features, area warranty... */}
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Card>
                <CardContent>
                  <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                    {translate('unit.features')}
                  </Typography>

                  <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                    <Grid container spacing={2} rowSpacing={6}>
                      <Grid item xs={12} sm={6} md={3}>
                        <NumberInput
                          name="area"
                          label={translate('project.area')}
                          showControls={false}
                          fullWidth
                          formik={formik}
                          helperText={translate('sqm')}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <NumberInput
                          name="structural_warranty"
                          label={translate('project.structuralWarranty')}
                          showControls={false}
                          fullWidth
                          formik={formik}
                          helperText={translate('year')}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <NumberInput
                          name="electric_warranty"
                          label={translate('project.electricWarranty')}
                          showControls={false}
                          fullWidth
                          formik={formik}
                          helperText={translate('year')}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <NumberInput
                          name="plumbing_warranty"
                          label={translate('project.plumbingWarranty')}
                          showControls={false}
                          fullWidth
                          formik={formik}
                          helperText={translate('year')}
                          disabled={isDisabled}
                        />
                      </Grid>

                      {numberInputFeatures.map((props, i) => (
                        <Grid item xs={12} sm={6} md={4} key={i}>
                          <NumberInput
                            fullWidth
                            showControls={false}
                            positive
                            disabled={isDisabled}
                            formik={formik}
                            {...props}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            {/* features, area warranty... */}

            {/* Cover Picture */}
            {formik?.values?.id && !isDisabled && (
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <CoverPictureSelector formik={formik} modelType={'project'} />
              </Grid>
            )}
            {/* Cover Picture */}

            {/* project file and video */}
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Card>
                <CardContent>
                  <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                    {translate('project.videoAndPdf')}
                  </Typography>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <TextField
                      name="project_pdf_url"
                      label={translate('project.projectPdfUrl')}
                      value={formik.values.project_pdf_url}
                      fullWidth
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <TextField
                      name="virtual_tour_url"
                      label={translate('project.virtualTourUrl')}
                      value={formik.values.virtual_tour_url}
                      fullWidth
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="youtube_video_url"
                      label={translate('unit.youtubeVideoUrl')}
                      value={formik.values.youtube_video_url}
                      fullWidth
                      onChange={handleYouTubeChange}
                      helperText="https://www.youtube.com/embed/VIDEO_ID"
                      disabled={isDisabled}
                    />
                  </Grid>
                  {youtubeId && (
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <iframe
                        style={{ width: '100%', height: '350px' }}
                        src={`https://www.youtube.com/embed/${youtubeId}/?rel=0&autoplay=0&mute=1&controls=0&showinfo=0&modestbranding=0&loop=0`}
                        frameBorder="0"
                        title="YouTube Video Preview"
                      />
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
            {/* project file and video */}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={2}>
              {/* website */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                      {translate('unit.publishedStatus')}
                    </Typography>

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label={translate('unit.publishedOnWebsite')}
                          control={
                            <Checkbox name="published_on_website" checked={formik.values.published_on_website} />
                          }
                          onChange={formik.handleChange}
                          disabled={isDisabled}
                        />
                      </Grid>
                      {formik?.values?.id && (
                        <Grid item xs={12}>
                          {workspace?.client_domain && (
                            <Box>
                              <Typography variant="subtitle2" paragraph>
                                {translate('project.standaloneUrl')}
                              </Typography>
                              <Box display="flex" justifyContent="center" alignItems="center">
                                <Button
                                  component="a"
                                  href={url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  startIcon={<Iconify icon="icon-park-outline:share" />}
                                  sx={{ fontSize: '0.75rem' }}
                                  color="secondary"
                                >
                                  {translate('project.navToProjectPage')}
                                </Button>
                                <Tooltip
                                  title={copied ? translate('project.copiedUrl') : translate('project.copyUrl')}
                                  onClose={() => setCopied(false)}
                                >
                                  <Button
                                    onClick={handleCopyClick}
                                    startIcon={<FileCopyIcon />}
                                    sx={{ fontSize: '0.75rem' }}
                                    color="secondary"
                                  >
                                    {translate('project.copyUrl')}
                                  </Button>
                                </Tooltip>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      )}
                      {/* <Grid item xs={12}>
                      <FormControlLabel
                        label={translate('unit.publishedOnNuzulApp')}
                        control={<Checkbox name="published_on_app" checked={formik.values.published_on_app} />}
                        onChange={formik.handleChange}
                      />
                    </Grid> */}
                      <Grid item xs={12}>
                        <TextField
                          name="sort"
                          label={translate('unit.sort')}
                          value={formik.values.sort}
                          fullWidth
                          onChange={formik.handleChange}
                          helperText={translate('unit.sortHelperText')}
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>

                    <Typography variant="h6" paragraph sx={{ mb: 4, mt: 2 }}>
                      {translate('unit.advertiser.title')}
                    </Typography>

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormControl fullWidth error={formik.touched.advertiser_type && formik.errors.advertiser_type}>
                          <InputLabel id="advertiser-type" shrink>
                            {translate('unit.advertiser.name')}
                          </InputLabel>
                          <Select
                            labelId="advertiser-type"
                            value={formik.values.advertiser_type || 'owner'}
                            onChange={formik.handleChange}
                            input={
                              <OutlinedInput notched name="advertiser_type" label={translate('unit.advertiser.name')} />
                            }
                            disabled={isDisabled}
                          >
                            <MenuItem value="advertiser">{translate('unit.advertiser.advertiser')}</MenuItem>
                            <MenuItem value="broker">{translate('unit.advertiser.broker')}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="rega_advertiser_number"
                          label={translate('unit.advertiser.licenseNumber')}
                          value={formik.values.rega_advertiser_number}
                          fullWidth
                          onChange={formik.handleChange}
                          disabled={isDisabled}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="rega_ad_number"
                          label={translate('unit.advertiser.adNumber')}
                          value={formik.values.rega_ad_number}
                          fullWidth
                          onChange={formik.handleChange}
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ mb: 1, mt: 1 }}>
                      <FormControlLabel
                        label={translate('unit.isWafiAd')}
                        control={<Checkbox name="is_wafi_ad" checked={formik.values.is_wafi_ad} />}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                      />
                    </Grid>

                    {formik.values.is_wafi_ad && (
                      <Grid item xs={12}>
                        <TextField
                          name="wafi_license_number"
                          label={translate('unit.wafiLicenseNumber')}
                          value={formik.values.wafi_license_number}
                          fullWidth
                          onChange={formik.handleChange}
                          disabled={isDisabled}
                        />
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              {/* website */}

              {/* statues */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                      {translate('unit.availability')}
                    </Typography>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="availability-status" shrink>
                          {translate('project.availabilityStatus')}
                        </InputLabel>
                        <Select
                          labelId="availability-status"
                          name="availability_status"
                          label={translate('availability-status')}
                          value={formik.values.availability_status}
                          error={formik.touched.availability_status && formik.errors.availability_status}
                          onChange={formik.handleChange}
                          fullWidth
                          disabled={isDisabled}
                          input={
                            <OutlinedInput
                              notched
                              name="availability_status"
                              label={translate('unit.availabilityStatus')}
                            />
                          }
                        >
                          <MenuItem value="unavailable">{translate('unit.unavailable')}</MenuItem>
                          <MenuItem value="available">{translate('unit.available')}</MenuItem>
                          <MenuItem value="sold">{translate('unit.sold')}</MenuItem>
                          <MenuItem value="soon">{translate('unit.soon')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {/* statues */}

              {/* location */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                      {translate('unit.location')}
                    </Typography>

                    <Box>
                      <LocationSelector
                        formik={formik}
                        onLocation={handleChangeLocation}
                        onMapClick={handleMapClick}
                        markerPosition={markerPosition}
                        onPlaceChanged={onPlaceChanged}
                        isDisabled={isDisabled}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              {/* location */}
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabIdx} index={1}>
        {/* project units */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" paragraph sx={{ mb: 0 }}>
                  {translate('project.units')}
                </Typography>

                {!isDisabled && (
                  <Button variant="outlined" onClick={() => setShowPropertiesModal(true)}>
                    {translate('project.addUnit')}
                  </Button>
                )}
              </Box>

              <Grid container spacing={2}>
                {formik.values.properties &&
                  formik.values.properties.map((p, i) => (
                    <Grid item xs={12} md={4} xl={3} key={i}>
                      <PropertyCard property={p}>
                        <Divider />
                        {!isDisabled && (
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                color="#000000"
                                onClick={() => navigateToPropertyPage(p.id)}
                              >
                                {translate(`deal.view`)}
                              </Typography>
                              <Typography
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                color="error"
                                onClick={() => handleRemoveProperty(p)}
                                sx={{ mx: 1 }}
                              >
                                {translate('remove')}
                              </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography style={{ paddingInlineEnd: '3px' }} color="#000000">
                                {p.views}
                              </Typography>
                              <Visibility fontSize="small" />
                            </Box>
                          </Box>
                        )}
                      </PropertyCard>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* project units */}
      </TabPanel>
    </Box>
  );
};
export default ProjectForm;
