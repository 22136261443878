import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useLocales from '../../../hooks/useLocales';

const WorkspaceStatusButton = (props) => {
  const { status } = props;
  let color;

  switch (status) {
    case 'accepted':
      color = '#54D62C';
      break;
    case 'declined':
      color = '#FF4842';
      break;
    case 'expired':
      color = '#212B36';
      break;
    default:
      color = '#FFC107';
  }

  const theme = useTheme();
  const { translate } = useLocales();

  return (
    <Button
      disabled
      variant={'outlined'}
      sx={{
        marginLeft: '1%',
        [theme.breakpoints.down]: {
          fontSize: '0.6rem',
        },
        '&:disabled': {
          color,
          borderColor: color,
        },
      }}
      color={'error'}
      {...props}
    >
      {translate(`invitation.${status}`)}
    </Button>
  );
};

export default WorkspaceStatusButton;
