import React, { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Box, Button, Typography } from '@mui/material';
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import GuestForm from './GuestForm';
import { dispatch } from '../../../redux/store';
import * as contactActions from '../../../models/contacts/actions';
import { Loader } from '../../../components/Loader';

export const addContactSchema = Yup.object({
  code: Yup.string().required('Required'),
  phone: Yup.string()
    .required('Required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(1)
    .max(20),
  name: Yup.string().required('Required'),
  email: Yup.string().optional(),
  gender: Yup.string().required().oneOf(['male', 'female']),
});

const AddGuest = () => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const callback = (type, response) => {
    if (type === 'success') {
      toast.success(translate('addContactScreen.successCreation'));
      navigate(`/siyaha/crm/guests/${response.data.id}`);
    } else {
      if (response.status === 422) {
        const errorMessage = response.data.message;
        toast.error(translate(`addContactScreen.${errorMessage}`));
      }
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      code: '+966',
      phone: '',
      gender: 'male',
      email: '',
      note: '',
      is_property_owner: false,
      is_property_buyer: false,
      is_property_renter: false,
      is_property_agent: false,
      is_property_broker: false,
      is_property_representative: false,
      is_siyaha: true,
    },
    validationSchema: addContactSchema,
    isInitialValid: false,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(contactActions.createContactRequest({ ...values, callback }));
    },
  });

  return (
    <Page title={translate('guest.createPageTitle')}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        <Typography variant="h3" component="h1">
          {translate('guests')}
        </Typography>

        <Button
          variant="contained"
          sx={{ p: '11px 22px', color: 'white', width: '176px', height: '48px' }}
          onClick={formik.submitForm}
          disabled={!formik.isValid || loading}
        >
          {!loading && translate('guest.createButton')}
          {loading && <Loader />}
        </Button>
      </Box>
      <GuestForm formik={formik} />
    </Page>
  );
};

export default AddGuest;
