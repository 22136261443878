import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Box, Button, Dialog, Divider, FormControl, Grid, TextField, Typography } from '@mui/material';
import useLocales from '../../hooks/useLocales';
import tPropertyRequestServices from '../../models/t-property-requests/services';

const confirmationSchema = Yup.object({
  closing_reason: Yup.string().min(3).required('Required'),
});

const ConfirmationModal = ({ open, onClose, actionType, requestId }) => {
  const { translate } = useLocales();

  const formik = useFormik({
    initialValues: {
      closing_reason: null,
    },
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: actionType === 'close' ? confirmationSchema : null,
    onSubmit: (values) => {
      if (actionType === 'close') {
        tPropertyRequestServices.setPropertyRequestStatus(requestId, { ...values, status: 'closed' }).then(() => {
          toast(translate('request.closedSuccessfully'), { type: 'success' });
          onClose();
        });
      }
      if (actionType === 'convert') {
        tPropertyRequestServices.setPropertyRequestStatus(requestId, { ...values, status: 'converted' }).then(() => {
          toast(translate('request.convertedSuccessfully'), { type: 'success' });
          onClose();
        });
      }
    },
  });

  return (
    <Dialog open={open}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: 466, p: 2 }}>
        {actionType === 'close' && (
          <>
            <Typography sx={{ p: 2 }} variant="h5">
              {translate('request.closeRequestConfirmation')}
            </Typography>

            <Grid item xs={12}>
              <TextField
                name="closing_reason"
                label={translate('request.closingReason')}
                value={formik.values.closing_reason}
                onChange={formik.handleChange}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
          </>
        )}

        {actionType === 'convert' && (
          <Typography sx={{ p: 2 }} variant="h5">
            {translate('request.convertToDealRequestConfirmation')}
          </Typography>
        )}

        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ width: 1 }} />

          <Divider sx={{ mt: 2 }} />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button variant="outlined" sx={{ color: 'black', borderColor: '#919EAB66', mr: 2 }} onClick={onClose}>
              {translate('request.cancelButton')}
            </Button>

            {actionType === 'close' && (
              <Button
                disabled={!formik.isValid}
                variant="contained"
                sx={{ color: 'white' }}
                onClick={formik.submitForm}
              >
                {translate('request.confirmCloseRequest')}
              </Button>
            )}

            {actionType === 'convert' && (
              <Button variant="contained" sx={{ color: 'white' }} onClick={formik.submitForm}>
                {translate('request.confirmConvertToDeal')}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
