import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import useLocales from '../../hooks/useLocales';
import * as teamActions from '../../models/team/actions';
import OptionsMenu from '../../components/OptionsMenu';

const InvitationOptionsPopup = ({ invitation }) => {
  const { translate } = useLocales();
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();

  const handleCancelInvitation = () => {
    dispatch(teamActions.cancelInvitationRequest(invitation));
    dispatch(teamActions.getInvitationsRequest());
    setShowMenu(false);
  };

  return (
    <OptionsMenu>
      <MenuItem onClick={handleCancelInvitation}>{translate('Cancel')}</MenuItem>
    </OptionsMenu>
  );
};

export default InvitationOptionsPopup;
