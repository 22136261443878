import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
  loading: false,
  members: null,
  member: null,
  invitations: null,
};

const reducer = createReducer(initialState, {
  [actions.getMembersRequest]: (state) => {
    console.log('members requested');
    state.loading = true;
  },
  [actions.getMembersSuccess]: (state, action) => {
    console.log('members success');
    state.loading = false;
    state.members = action.payload.data;
  },
  [actions.getMembersFailure]: (state, action) => {
    console.log('members error');
    state.loading = false;
  },
  [actions.getMemberByIdRequest]: (state) => {
    state.loading = true;
  },
  [actions.getMemberByIdSuccess]: (state, action) => {
    state.loading = false;
    state.member = action.payload.data;
  },
  [actions.getMemberByIdFailure]: (state) => {
    state.loading = false;
  },
  [actions.getInvitationsRequest]: (state) => {
    console.log('invitations requested');
    state.loading = true;
  },
  [actions.getInvitationsSuccess]: (state, action) => {
    console.log('invitations success');
    state.loading = false;
    state.invitations = action.payload.data;
  },
  [actions.getInvitationsFailure]: (state, action) => {
    console.log('invitations error');
    state.loading = false;
  },
  [actions.cancelInvitationRequest]: (state) => {
    state.loading = true;
  },
  [actions.cancelInvitationSuccess]: (state) => {
    state.loading = false;
  },
  [actions.cancelInvitationFailure]: (state) => {
    state.loading = false;
  },
  [actions.removeMemberRequest]: (state) => {
    state.loading = true;
  },
  [actions.removeMemberSuccess]: (state) => {
    state.loading = false;
  },
  [actions.removeMemberFailure]: (state) => {
    state.loading = false;
  },
  [actions.changeRoleRequest]: (state) => {
    state.loading = true;
  },
  [actions.changeRoleSuccess]: (state) => {
    state.loading = false;
  },
  [actions.changeRoleFailure]: (state) => {
    state.loading = false;
  },
  [actions.editShortLinkRequest]: (state) => {
    state.loading = true;
  },
  [actions.editShortLinkSuccess]: (state) => {
    state.loading = false;
  },
  [actions.editShortLinkFailure]: (state) => {
    state.loading = false;
  },
  [actions.inviteMemberRequest]: (state) => {
    state.loading = true;
  },
  [actions.inviteMemberSuccess]: (state) => {
    state.loading = false;
  },
  [actions.inviteMemberFailure]: (state) => {
    state.loading = false;
  },

  [actions.searchTeamByNameRequest]: (state) => {
    state.loading = true;
  },
  [actions.searchTeamByNameFailure]: (state) => {
    state.loading = false;
  },
  [actions.searchTeamByNameSuccess]: (state, action) => {
    state.loading = false;
    state.search = action.payload.data;
  },
});

const loading = (state) => state.team.loading;
const members = (state) => state.team.members;
const invitations = (state) => state.team.invitations;
const search = (state) => state.team.search;
const member = (state) => state.team.member;

const selectors = {
  loading,
  members,
  invitations,
  search,
  member,
};

export { initialState, reducer, selectors };
export default reducer;
