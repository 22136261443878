import { Button, styled, Typography, Box, Card, Radio } from '@mui/material';

const ListItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: 'solid 1px #919EAB52',
  marginTop: '2%',
}));

const WorkspaceTitleButton = styled(Button)(({ theme }) => ({
  color: 'black',
  borderColor: '#919EAB52',
}));

export { WorkspaceTitleButton, ListItem };
