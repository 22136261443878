import { Button, styled, Typography, Box, Card, Radio } from '@mui/material';
import { useLocation } from 'react-router-dom';

import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';

const HowToPayOuterBoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}));

const HowToPaySubTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '24px',
  color: '#212B36',
}));

const HowToPayRadioBoxStyled = styled(Box)(({ theme, solution = useLocation().pathname.split('/').pop() }) => ({
  minHeight: '75px',
  backgroundColor: '#fff',
  padding: '11px 13px',
  borderRadius: '7px',
  marginBottom: '25px',
  display: 'flex',
  justifyContent: 'space-between',
  transition: 'all 0.5s ease',
  '&:hover': {
    boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.16)',
    border: `1px solid ${solution === 'siyaha' ? theme.palette.siyaha.main : theme.palette.primary.main}`,
    backgroundColor: '#fff',
  },
}));

const HowToPayRadioButtonStyled = styled(Radio)(({ theme, solution = useLocation().pathname.split('/').pop() }) => ({
  color: '#919EAB',
  '&.Mui-checked': { color: solution === 'siyaha' ? theme.palette.siyaha.main : theme.palette.primary.main },
  '&.Mui-checked .payment-item-selected': {
    boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.16)',
    border: `1px solid ${solution === 'siyaha' ? theme.palette.siyaha.main : theme.palette.primary.main}`,
  },
}));

const PayNowRadioButtonStyled = styled(Button)(({ theme }) => ({
  width: '100%',
  color: 'white',
  borderRadius: '8px',
  paddingBlock: '15px',
  marginTop: '48px',
  textTransform: 'none',
  backgroundColor: 'black',
  boxShadow: 'none',
  '&:hover': {
    color: 'white',
    backgroundColor: '#212B36',
    borderColor: 'inherit',
    boxShadow: 'none',
  },
}));

const TermsNconditionsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '24px',
  flexWrap: 'wrap',
}));

const TermsNconditionsTypography = styled(Typography)(({ theme }) => ({
  color: '#919EAB',
  lineHeight: '18px',
  fontSize: '12px',
  fontWeight: '400',
}));

const TermsNconditionsLink = styled(Typography)(({ theme }) => ({
  marginLeft: '4px',
  color: '#919EAB',
  lineHeight: '18px',
  fontSize: '12px',
  fontWeight: '400',
  textDecoration: 'underline',
}));

const PaymentSummaryBoxStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  padding: '26px 32px',
  backgroundColor: '#fff',
  borderRadius: '24px',
  boxShadow: '0px 0px 17px rgba(175, 175, 175, 0.3)',
  position: 'relative',
}));

const PaymentSummaryTypographyStyled = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '30px',
}));

const BillingPeriodTypographyStyled = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: '#FFF7CD',
  fontWeight: '400',
}));

const PaymentSummaryTitleTypographyStyled = styled(Typography)(({ theme }) => ({
  marginTop: '24px',
  color: 'white',
  fontWeight: '700',
  marginBottom: '11px',
}));

const Tab = styled(TabUnstyled)(
  ({ theme, solution = useLocation().pathname.split('/').pop() }) => `
  color: #000;
  cursor: pointer;
  font-size: 0.875rem;
  width: 100%;
  padding: 1rem;
  margin: 2px 2px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${solution === 'siyaha' ? theme.palette.siyaha.main : theme.palette.primary.main};
    color:#fff
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${solution === 'siyaha' ? theme.palette.siyaha.main : theme.palette.primary.main};
    color: #fff;
    font-weight: 600;
  };
  }
`
);

const TabPanel = styled(TabPanelUnstyled)(
  ({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  background: #fff;
  border-radius: 24px;
  left: 0;
  z-index: -1;
  `
);

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  background-color: #efefef;
  border-radius: 7px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  border: solid 1px #DFE3E8;
  `
);

const PackageDetailsBox = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  padding: '26px 32px',
  backgroundColor: 'white',
  borderRadius: '17px',
  border: '1px solid #eee',
}));

const PackageTotalPriceBox = styled(Box)(({ theme }) => ({
  borderBottom: '1px solid #DFE3E8',
  paddingBottom: '18px',
}));

const PackageTotalPriceRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '5px',
}));

const PackagePriceSummaryRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export {
  HowToPayOuterBoxStyled,
  HowToPaySubTitleStyled,
  HowToPayRadioBoxStyled,
  HowToPayRadioButtonStyled,
  PayNowRadioButtonStyled,
  TermsNconditionsBox,
  TermsNconditionsTypography,
  TermsNconditionsLink,
  PaymentSummaryBoxStyled,
  PaymentSummaryTypographyStyled,
  BillingPeriodTypographyStyled,
  PaymentSummaryTitleTypographyStyled,
  Tab,
  TabPanel,
  TabsList,
  PackageDetailsBox,
  PackageTotalPriceBox,
  PackageTotalPriceRow,
  PackagePriceSummaryRow,
};
