// @mui
import { Box, Grid, Link, useTheme } from '@mui/material';
// components
import { Heading, SubTitle, FieldsContainer } from './AuthBaseLayout.elements';
import useLocales from '../hooks/useLocales';

import NuzulLogo from '../assets/logo/logo_full.svg';
import NuzulLogoYellow from '../assets/logo/logo_full_yellow.svg';

const AuthBaseLayout = ({ children, heading, subtitle }) => {
  const theme = useTheme();
  const { onChangeLang, currentLang } = useLocales();

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={9}>
        <FieldsContainer>
          <Box sx={{ width: '90%' }}>
            <img
              src={theme.palette.mode === 'light' ? NuzulLogo : NuzulLogoYellow}
              alt="Nuzul Logo"
              style={{ marginBottom: '35px', width: '150px' }}
            />
            <Heading
              variant="h2"
              style={{
                color: theme.palette.secondary.main,
              }}
            >
              {heading}
            </Heading>
            <SubTitle variant="subtitle1">{subtitle}</SubTitle>
          </Box>
          <Box sx={{ width: '90%' }}>{children}</Box>
          <Link
            component="button"
            variant="caption"
            type="button"
            onClick={(event) => {
              event.preventDefault();
              onChangeLang(currentLang.value === 'ar' ? 'en' : 'ar');
            }}
            sx={{ textDecoration: 'underline', color: '#0066FF', marginTop: '30px' }}
          >
            {currentLang.value === 'ar' ? 'English' : 'اللغة العربية'}
          </Link>
        </FieldsContainer>
      </Grid>
    </Grid>
  );
};

export default AuthBaseLayout;
