import * as Yup from 'yup';
import { Box, Button, Container, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../components/Loader';
import useLocales from '../../../hooks/useLocales';
import Page from '../../../components/Page';
import ListingsForm from './ListingsForm';
import { selectors as propertySelectors } from '../../../models/properties/reducers';
import * as propertyActions from '../../../models/properties/actions';

const propertyEditSchema = Yup.object({
  min_price: Yup.number().positive(),
  max_price: Yup.number().positive().moreThan(Yup.ref('min_price'), 'Max price must be more than Min price.'),
  facade: Yup.string().oneOf([
    'north',
    'east',
    'south',
    'west',
    'north_east',
    'north_south',
    'east_west',
    'north_west',
    'south_east',
    'south_west',
    'north_east_west',
    'north_south_east',
    'north_south_west',
    'south_east_west',
    'north_south_east_west',
  ]),
  min_area: Yup.number(),
  max_area: Yup.number().moreThan(Yup.ref('min_area'), 'Max area must be more than Min area.'),
  country_id: Yup.number(),
  city_id: Yup.number(),
  district_id: Yup.number(),
});

const EditListings = () => {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { id } = useParams();
  const property = useSelector(propertySelectors.property);
  const updateLoading = useSelector(propertySelectors.loading);

  useEffect(() => {
    dispatch(propertyActions.getPropertyRequest(id));
  }, [id]);

  const updateCallback = (t, data) => {
    if (t === 'success') {
      toast(translate('Successfully updated'), { type: 'success', autoClose: 700 });
    } else {
      toast('Failed to update property', { type: 'error' });
    }
  };

  const toggleArchive = () => () => {
    dispatch(propertyActions.toggleArchivePropertyRequest({ id, callback: updateCallback }));
  };

  const formik = useFormik({
    initialValues: {
      id: property?.id,
      category: property?.category,
      purpose: property?.purpose,
      type: property?.type,
      year_built: property?.year_built || 0,
      street_width: property?.street_width || '',
      street_width_east: property?.street_width_east || '',
      street_width_south: property?.street_width_south || '',
      street_width_west: property?.street_width_west || '',
      selling_price: property?.selling_price || '',
      fee: property?.fee || '',
      vat: property?.vat || '',
      rent_price_daily: property?.rent_price_daily || '',
      rent_price_monthly: property?.rent_price_monthly || '',
      rent_price_quarterly: property?.rent_price_quarterly || '',
      rent_price_semi_annually: property?.rent_price_semi_annually || '',
      rent_price_annually: property?.rent_price_annually || '',
      area: property?.area || '',
      longitude: property?.longitude || '',
      latitude: property?.latitude || '',
      number_of_floors: property?.number_of_floors || 0,
      unit_floor_number: property?.unit_floor_number || 0,
      king_beds: property?.king_beds || 0,
      single_beds: property?.single_beds || 0,
      number_of_guests: property?.number_of_guests || 0,
      number_of_kids: property?.number_of_kids || 0,
      bedrooms: property?.bedrooms || 0,
      bathrooms: property?.bathrooms || 0,
      dining_rooms: property?.dining_rooms || 0,
      living_rooms: property?.living_rooms || 0,
      majlis_rooms: property?.majlis_rooms || 0,
      maid_rooms: property?.maid_rooms || 0,
      driver_rooms: property?.driver_rooms || 0,
      mulhaq_rooms: property?.mulhaq_rooms || 0,
      storage_rooms: property?.storage_rooms || 0,
      basement_rooms: property?.basement_rooms || 0,
      elevators: property?.elevators || 0,
      pools: property?.pools || 0,
      balconies: property?.balconies || 0,
      kitchens: property?.kitchens || 0,
      gardens: property?.gardens || 0,
      parking_spots: property?.parking_spots || 0,
      facade: property?.facade || '',
      unit_number: property?.unit_number || 0,
      plan_number: property?.plan_number || '',
      plot_number: property?.plot_number || '',
      number_of_apartments: property?.number_of_apartments || 0,
      published_on_website: !!property?.published_on_website || false,
      published_on_app: !!property?.published_on_app || false,
      cover_image_url: property?.cover_image_url || '',
      is_kitchen_installed: property?.is_kitchen_installed || false,
      is_ac_installed: property?.is_ac_installed || false,
      is_parking_shade: property?.is_parking_shade || false,
      is_furnished: property?.is_furnished || false,

      country_id: property?.country?.id || '',
      city_id: property?.city?.id || '',
      district_id: property?.district?.id || '',

      photos: property?.images?.map((image) => image.url) || [],
      length: property?.length || '',
      width: property?.width || '',

      availability_status: property?.availability_status || '',
      availability_date: property?.availability_date,

      has_water: !!property?.has_water || false,
      has_electricity: !!property?.has_electricity || false,
      has_sewage: !!property?.has_sewage || false,

      advertiser_type: property?.advertiser_type || 'broker',

      rega_ad_number: property?.rega_ad_number || '',
      rega_advertiser_number: property?.rega_advertiser_number || '',

      apartments: property?.apartments || 0,
      stores: property?.stores || 0,

      is_for_farming: !!property?.is_for_farming || false,
      is_for_residential: !!property?.is_for_residential || false,
      is_for_commercial: !!property?.is_for_commercial || false,
      is_for_education: !!property?.is_for_education || false,
      is_for_health: !!property?.is_for_health || false,
      is_for_factory: !!property?.is_for_factory || false,

      owner_id: property?.owner?.id || '',
      owner: property?.owner?.name || '',

      is_wafi_ad: !!property?.is_wafi_ad || false,
      wafi_license_number: property?.wafi_license_number || '',
      sort: property?.sort || 0,

      amenities: property?.amenities || [],
    },
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: propertyEditSchema,
    onSubmit: (values) => {
      console.log('property:', values);
      dispatch(propertyActions.editPropertyRequest({ ...values, callback: updateCallback }));
    },
  });

  return (
    <Page title={translate('units')}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        <Typography variant="h4" paragraph sx={{ mb: 4 }}>
          {translate('unit.information')}
        </Typography>

        <Box>
          <Button
            onClick={toggleArchive()}
            variant="outlined"
            disabled={updateLoading}
            sx={{
              color: '#999',
              borderColor: '#999',
              marginLeft: 2,
              '&:hover': {
                color: '#999',
                borderColor: '#999',
                backgroundColor: 'transparent',
              },
            }}
          >
            {translate(property?.is_archived ? 'unit.unarchiveButton' : 'unit.archiveButton')}
          </Button>

          <Button
            onClick={formik.submitForm}
            variant="contained"
            sx={{ color: 'white', marginLeft: 2 }}
            disabled={updateLoading || !formik.dirty}
          >
            {!updateLoading && translate('unit.saveButton')}
            {updateLoading && <Loader />}
          </Button>
        </Box>

        {/* <Button
          variant="contained"
          sx={{ p: '11px 22px', color: 'white', width: '176px', height: '48px' }}
          onClick={formik.submitForm}
        >
          {translate('contact.saveButton')}
        </Button> */}
      </Box>
      <ListingsForm formik={formik} />
    </Page>
  );
};

export default EditListings;
