import React, { useEffect, useState } from 'react';

import { DataGrid } from '@mui/x-data-grid';
// @mui
import { Box, Card, CardContent, Container, Divider, Grid, IconButton, Typography } from '@mui/material';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../../components/Iconify';
import QuickSearchToolbar from '../../../components/QuickSearchToolbar';
import useSettings from '../../../hooks/useSettings';
import useLocales from '../../../hooks/useLocales';

// components
import Page from '../../../components/Page';
import { selectors as reservationsSelectors } from '../../../models/t-reservations/reducers';
import { selectors as notificationsSelectors } from '../../../models/notifications/reducers';
import * as reservationsActions from '../../../models/t-reservations/actions';
import { hasPropertyReservationNotification } from '../../../models/notifications/utils';

// ----------------------------------------------------------------------

export default function ReservationsView() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useState({ include_archived: 0 });

  useEffect(() => {
    dispatch(reservationsActions.getReservationsRequest(queryParams));
  }, []);

  const reservations = useSelector(reservationsSelectors.reservations);
  const getReservationsLoading = useSelector(reservationsSelectors.getReservationsLoading);
  const notifications = useSelector(notificationsSelectors.notifications);

  const handleRow = (params) => {
    navigate(`/siyaha/reservations/${params.row.id}`);
  };

  const isUnseen = (id) => hasPropertyReservationNotification(id, notifications || []);

  const columns = [
    {
      field: 'id',
      headerName: '#',
      maxWidth: 100,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'guest_name',
      headerName: translate('siyaha.reservation.guestName'),
      maxWidth: 280,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row?.tenant_contact?.name,
    },
    {
      field: 'unit',
      headerName: translate('siyaha.reservation.unitNumber'),
      maxWidth: 280,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.property?.unit_number,
    },
    {
      field: 'check_in_date',
      headerName: translate('siyaha.reservation.checkInDate'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'check_out_date',
      headerName: translate('siyaha.reservation.checkOutDate'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: translate('siyaha.reservation.status'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => translate(`siyaha.reservation.statues.${params.row.status}`),
    },
  ];

  return (
    <Page title={translate('siyaha.reservations')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('siyaha.reservations')}
            </Typography>
            <IconButton
              sx={{ p: 1, border: '1px solid rgba(145, 158, 171, 0.32)', borderRadius: '8px', ml: 2 }}
              onClick={() => {
                navigate('/siyaha/reservations/calendar');
              }}
            >
              <Iconify icon={'tabler:calendar'} />
            </IconButton>
          </Box>
        </Box>
        <Card className="no-animation">
          <CardContent sx={{ p: 0 }}>
            <Box>
              <DataGrid
                loading={getReservationsLoading}
                onRowClick={handleRow}
                getRowClassName={(params) => `clickable ${isUnseen(params.row.id) ? 'unread' : ''}`}
                columns={columns}
                rows={reservations || []}
                disableColumnFilter
                disableColumnSelector
                components={{
                  Toolbar: QuickSearchToolbar,
                }}
                hideFooterSelectedRowCount
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
                pageSize={10}
                autoHeight
                sx={{
                  '& .datagrid-header': {
                    backgroundColor: '#F4F6F8',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: 0,
                  },
                  border: 'none',
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
