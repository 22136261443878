import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Button,
  debounce,
  CardHeader,
  Link,
  IconButton,
  Tooltip,
  InputAdornment,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '../../components/Icons/SearchIcon';
import useLocales from '../../hooks/useLocales';
import NumberInput from '../../components/NumberInput';
import PropertyImagesSelector from './PropertyImagesSelector';
import CoverPictureSelector from './CoverPictureSelector';
import LocationSelector from '../../components/LocationSelector';
import { selectors as userSelectors } from '../../models/user/reducers';
import Notes from '../notes/Notes';
import Iconify from '../../components/Iconify';
import { TENANT_ROLES } from '../../utils/constants';

import { selectors as contactSelectors } from '../../models/contacts/reducers';
import { selectors as propertySelectors } from '../../models/properties/reducers';
import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';
import * as contactActions from '../../models/contacts/actions';
import ClickToCopy from '../../components/ClickToCopy';

const commonFieldsProps = {
  InputLabelProps: {
    shrink: true,
  },
};

const serializeOption = (opt) => `${opt.name} - ${opt.mobile_number}`;

const PropertyForm = ({ formik, filters, id }) => {
  const [markerPosition, setMarkerPosition] = useState(null);
  const dispatch = useDispatch();
  const search = useSelector(contactSelectors.search);
  const searchLoading = useSelector(contactSelectors.loading);
  const userInfo = useSelector(userSelectors.me);
  const workspace = useSelector(userSelectors.workspace);
  const property = useSelector(propertySelectors.property);

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const [youtubeLink, setYoutubeLink] = useState('');
  const [youtubeId, setYoutubeId] = useState('');

  const handleSearch = debounce((e) => {
    const input = e.target.value;
    dispatch(contactActions.searchContactByNameRequest({ input, filters }));
  }, 500);

  const handleYouTubeChange = (event) => {
    const inputUrl = event.target.value;
    setYoutubeLink(inputUrl);

    // Regular expression to extract the YouTube video ID from various YouTube URL formats
    const youtubeIdRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = inputUrl.match(youtubeIdRegex);

    if (match && match[1]) {
      setYoutubeId(match[1]);
      formik.setFieldValue('youtube_video_url', `https://www.youtube.com/embed/${match[1]}`);
    } else {
      formik.setFieldValue('youtube_video_url', event.target.value);
      setYoutubeId('');
    }
  };

  useEffect(() => {
    if (formik.values.youtube_video_url) {
      setYoutubeLink(formik.values.youtube_video_url);
      setYoutubeId(formik.values.youtube_video_url.split('/').pop());
    }
  }, []);

  useEffect(() => {
    handleSearch({ target: { value: '' } });
  }, []);

  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  useEffect(() => {
    if (formik.values.latitude && formik.values.longitude) {
      setMarkerPosition({
        lat: parseFloat(formik.values.latitude),
        lng: parseFloat(formik.values.longitude),
      });
    }
  }, [formik.values.longitude, formik.values.latitude]);

  const numberInputFeatures = [
    {
      name: 'bedrooms',
      label: 'unit.bedrooms',
    },
    {
      name: 'bathrooms',
      label: 'unit.bathrooms',
    },
    {
      name: 'number_of_floors',
      label: 'unit.floors',
    },
    {
      name: 'unit_floor_number',
      label: 'unit.floorNumber',
    },
    {
      name: 'living_rooms',
      label: 'unit.dailyLivingRoom',
    },
    {
      name: 'dining_rooms',
      label: 'unit.diningRooms',
    },
    {
      name: 'maid_rooms',
      label: 'unit.maidRooms',
    },
    {
      name: 'driver_rooms',
      label: 'unit.driverRooms',
    },
    {
      name: 'majlis_rooms',
      label: 'unit.majlisRooms',
    },
    {
      name: 'storage_rooms',
      label: 'unit.storageRooms',
    },
    {
      name: 'basement_rooms',
      label: 'unit.basement',
    },
    { name: 'pools', label: 'unit.pool' },
    {
      name: 'balconies',
      label: 'unit.balconies',
    },
    {
      name: 'kitchens',
      label: 'unit.kitchens',
    },
    {
      name: 'gardens',
      label: 'unit.gardens',
    },
    {
      name: 'mulhaq_rooms',
      label: 'unit.mulhaqRooms',
    },
    {
      name: 'elevators',
      label: 'unit.elevators',
    },
    {
      name: 'parking_spots',
      label: 'unit.parkingBay',
    },
  ];

  const handleChangeLocation = ({ districtId }) => {
    formik.setFieldValue('district_id', districtId);
  };

  const updateLatLng = ({ lat, lng }) => {
    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);

    setMarkerPosition({
      lat,
      lng,
    });
  };

  const handleMapClick = (mapEvent) => {
    updateLatLng(mapEvent);
  };

  const onPlaceChanged = (autoComplete) => {
    const place = autoComplete.getPlace();
    const { lat, lng } = place.geometry.location;
    updateLatLng({ lat: lat(), lng: lng() });
  };

  const startYear = new Date().getFullYear() - 100;
  const currentYear = new Date().getFullYear();

  const yearsArray = [];
  // eslint-disable-next-line for-direction
  for (let year = currentYear; year >= startYear; year -= 1) {
    yearsArray.push(year);
  }

  const yearBuilt = formik.values.year_built.toString() === '0' ? '' : formik.values.year_built;

  return (
    <Grid container spacing={2} component="form" onSubmit={formik.handleSubmit}>
      <button type="submit" style={{ display: 'none' }}>
        Submit
      </button>
      <Grid item xs={12} lg={8}>
        <Grid container spacing={2}>
          {/* facts */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                  {translate('unit.facts')}
                </Typography>

                <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="type"
                        label={translate('unit.listingType')}
                        value={translate(`unit.${formik.values.type}`)}
                        disabled
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="category"
                        label={translate('unit.category')}
                        value={translate(`unit.${formik.values.category}`)}
                        disabled
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="purpose"
                        label={translate('unit.purpose')}
                        value={translate(`unit.${formik.values.purpose}`)}
                        disabled
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="unit_number"
                        label={translate('unit.unit_number')}
                        value={formik.values.unit_number}
                        fullWidth
                        onChange={formik.handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <ClickToCopy
                                text={formik.values.unit_number}
                                skeleton={formik.initialValues.unit_number !== formik.values.unit_number}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="plan_number"
                        label={translate('unit.planNumber')}
                        value={formik.values.plan_number}
                        fullWidth
                        onChange={formik.handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="plot_number"
                        label={translate('unit.plotNumber')}
                        value={formik.values.plot_number}
                        fullWidth
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* facts */}

          <Grid item xs={12}>
            <Card>
              <CardContent>
                {/* rent */}
                {formik.values.purpose === 'rent' && (
                  <>
                    <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                      {translate('unit.rentingPrice')}
                    </Typography>

                    <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                      <Grid container spacing={2} rowSpacing={6}>
                        <Grid item xs={12} sm={6} md={4}>
                          <NumberInput
                            name="rent_price_monthly"
                            label={translate('unit.monthlyPrice')}
                            showControls={false}
                            formik={formik}
                            fullWidth
                            helperText={translate('Saudi Riyals')}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <NumberInput
                            name="rent_price_quarterly"
                            label={translate('unit.quarterlyPrice')}
                            formik={formik}
                            showControls={false}
                            fullWidth
                            helperText={translate('Saudi Riyals')}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <NumberInput
                            name="rent_price_semi_annually"
                            label={translate('unit.semiAnnuallyPrice')}
                            formik={formik}
                            showControls={false}
                            fullWidth
                            helperText={translate('Saudi Riyals')}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <NumberInput
                            name="rent_price_annually"
                            label={translate('unit.annuallyPrice')}
                            formik={formik}
                            showControls={false}
                            fullWidth
                            helperText={translate('Saudi Riyals')}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
                {/* sell */}
                {formik.values.purpose === 'sell' && (
                  <>
                    <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                      {translate('unit.sellingPrice')}
                    </Typography>

                    <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                      <Grid container spacing={4}>
                        <Grid item xs={4}>
                          <NumberInput
                            name="selling_price"
                            label={translate('unit.sellingPrice')}
                            showControls={false}
                            formik={formik}
                            helperText={translate('Saudi Riyals')}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                  {translate('unit.features')}
                </Typography>

                <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                  <Grid container spacing={2} rowSpacing={6}>
                    <Grid item xs={12} sm={6} md={4}>
                      <NumberInput
                        name="area"
                        label={translate('unit.area')}
                        showControls={false}
                        fullWidth
                        formik={formik}
                        helperText={translate('sqm')}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <InputLabel id="deal-facade">{translate('unit.facade')}</InputLabel>
                        <Select
                          name="facade"
                          label={translate('unit.facade')}
                          labelId="deal-facade"
                          value={formik.values.facade}
                          error={formik.touched.facade && formik.errors.facade}
                          onChange={formik.handleChange}
                        >
                          <MenuItem value="north">{translate('unit.north')}</MenuItem>
                          <MenuItem value="east">{translate('unit.east')}</MenuItem>
                          <MenuItem value="west">{translate('unit.west')}</MenuItem>
                          <MenuItem value="south">{translate('unit.south')}</MenuItem>
                          <MenuItem value="east_west">{translate('unit.eastWest')}</MenuItem>
                          <MenuItem value="north_east">{translate('unit.northEast')}</MenuItem>
                          <MenuItem value="north_west">{translate('unit.northWest')}</MenuItem>
                          <MenuItem value="north_south">{translate('unit.northSouth')}</MenuItem>
                          <MenuItem value="south_east">{translate('unit.southEast')}</MenuItem>
                          <MenuItem value="south_west">{translate('unit.southWest')}</MenuItem>
                          <MenuItem value="north_east_west">{translate('unit.northEastWest')}</MenuItem>
                          <MenuItem value="north_south_east">{translate('unit.northSouthEast')}</MenuItem>
                          <MenuItem value="north_south_west">{translate('unit.northSouthWest')}</MenuItem>
                          <MenuItem value="south_east_west">{translate('unit.southEastWest')}</MenuItem>
                          <MenuItem value="north_south_east_west">{translate('unit.northSouthEastWest')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <NumberInput
                        showControls={false}
                        label={translate('unit.length')}
                        name="length"
                        fullWidth
                        formik={formik}
                        helperText={translate('meter')}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <NumberInput
                        name="width"
                        showControls={false}
                        label={translate('unit.width')}
                        formik={formik}
                        fullWidth
                        helperText={translate('meter')}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <NumberInput
                          showControls={false}
                          label={translate('unit.streetWidthNorth')}
                          name="street_width"
                          fullWidth
                          formik={formik}
                          helperText={translate('meter')}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <NumberInput
                          showControls={false}
                          label={translate('unit.streetWidthEast')}
                          name="street_width_east"
                          fullWidth
                          formik={formik}
                          helperText={translate('meter')}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <NumberInput
                          showControls={false}
                          label={translate('unit.streetWidthSouth')}
                          name="street_width_south"
                          fullWidth
                          formik={formik}
                          helperText={translate('meter')}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <NumberInput
                          showControls={false}
                          label={translate('unit.streetWidthWest')}
                          name="street_width_west"
                          fullWidth
                          formik={formik}
                          helperText={translate('meter')}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <InputLabel id="unit-year-built">{translate('unit.yearBuilt')}</InputLabel>
                        <Select
                          name="year_built"
                          label={translate('unit.yearBuilt')}
                          labelId="unit-year-built"
                          value={yearBuilt}
                          error={formik.touched.year_built && formik.errors.year_built}
                          onChange={formik.handleChange}
                        >
                          {yearsArray.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {['building', 'mansion', 'duplex', 'townhouse', 'villa', 'resort'].find(
                      (t) => t === formik.values.type
                    ) && (
                      <Grid item xs={12} sm={6} md={3}>
                        <NumberInput
                          name="apartments"
                          label={translate('unit.apartments')}
                          formik={formik}
                          showControls
                          positive
                        />
                      </Grid>
                    )}

                    {['building'].find((t) => t === formik.values.type) && (
                      <Grid item xs={12} sm={6} md={3}>
                        <NumberInput
                          name="stores"
                          label={translate('unit.stores')}
                          formik={formik}
                          showControls
                          positive
                        />
                      </Grid>
                    )}

                    {[
                      'tower_apartment',
                      'mansion',
                      'duplex',
                      'building_apartment',
                      'townhouse',
                      'villa',
                      'villa_apartment',
                      'villa_floor',
                      'resort',
                    ].find((t) => t === formik.values.type) &&
                      numberInputFeatures.map((props, i) => (
                        <Grid item xs={12} sm={6} md={3} key={i}>
                          <NumberInput fullWidth showControls positive formik={formik} {...props} />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 3 }}>
                  {translate('unit.usageAndServices')}
                </Typography>

                <Box sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {translate('unit.services')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <FormControlLabel
                        label={translate('unit.electricity')}
                        control={<Checkbox name="has_electricity" checked={formik.values.has_electricity} />}
                        onChange={formik.handleChange}
                      />
                      <FormControlLabel
                        label={translate('unit.water')}
                        control={<Checkbox name="has_water" checked={formik.values.has_water} />}
                        onChange={formik.handleChange}
                      />
                      <FormControlLabel
                        label={translate('unit.sewage')}
                        control={<Checkbox name="has_sewage" checked={formik.values.has_sewage} />}
                        onChange={formik.handleChange}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {translate('unit.usage')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 3 }}>
                      <FormControlLabel
                        label={translate('unit.residential')}
                        control={<Checkbox name="is_for_residential" checked={formik.values.is_for_residential} />}
                        onChange={formik.handleChange}
                      />
                      <FormControlLabel
                        label={translate('unit.commercial')}
                        control={<Checkbox name="is_for_commercial" checked={formik.values.is_for_commercial} />}
                        onChange={formik.handleChange}
                      />
                      <FormControlLabel
                        label={translate('unit.farming')}
                        control={<Checkbox name="is_for_farming" checked={formik.values.is_for_farming} />}
                        onChange={formik.handleChange}
                      />
                      <FormControlLabel
                        label={translate('unit.education')}
                        control={<Checkbox name="is_for_education" checked={formik.values.is_for_education} />}
                        onChange={formik.handleChange}
                      />
                      <FormControlLabel
                        label={translate('unit.health')}
                        control={<Checkbox name="is_for_health" checked={formik.values.is_for_health} />}
                        onChange={formik.handleChange}
                      />
                      <FormControlLabel
                        label={translate('unit.factory')}
                        control={<Checkbox name="is_for_factory" checked={formik.values.is_for_factory} />}
                        onChange={formik.handleChange}
                      />
                    </Box>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <CoverPictureSelector formik={formik} modelType={'property'} />
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                  {translate('unit.photos')}
                </Typography>
                <PropertyImagesSelector formik={formik} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                  {translate('unit.videoAndVirtualTour')}
                </Typography>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <TextField
                    name="virtual_tour_url"
                    label={translate('project.virtualTourUrl')}
                    value={formik.values.virtual_tour_url}
                    fullWidth
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...commonFieldsProps}
                    name="youtube_video_url"
                    label={translate('unit.youtubeVideoUrl')}
                    value={formik.values.youtube_video_url}
                    fullWidth
                    onChange={handleYouTubeChange}
                    helperText="https://www.youtube.com/embed/VIDEO_ID"
                  />
                </Grid>
                {youtubeId && (
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <iframe
                      style={{ width: '100%', height: '350px' }}
                      src={`https://www.youtube.com/embed/${youtubeId}/?rel=0&autoplay=0&mute=1&controls=0&showinfo=0&modestbranding=0&loop=0`}
                      frameBorder="0"
                      title="YouTube Video Preview"
                    />
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                  {translate('unit.availability')}
                </Typography>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="availability-status" shrink>
                      {translate('unit.availabilityStatus')}
                    </InputLabel>
                    <Select
                      labelId="availability-status"
                      name="availability_status"
                      label={translate('availability-status')}
                      value={formik.values.availability_status}
                      error={formik.touched.availability_status && formik.errors.availability_status}
                      onChange={formik.handleChange}
                      fullWidth
                      input={
                        <OutlinedInput
                          notched
                          name="availability_status"
                          label={translate('unit.availabilityStatus')}
                        />
                      }
                    >
                      <MenuItem value="unavailable">{translate('unit.unavailable')}</MenuItem>
                      <MenuItem value="available">{translate('unit.available')}</MenuItem>
                      <MenuItem value="reserved">{translate('unit.reserved')}</MenuItem>
                      {formik.values.purpose === 'sell' && <MenuItem value="sold">{translate('unit.sold')}</MenuItem>}
                      {formik.values.purpose === 'rent' && (
                        <MenuItem value="rented">{translate('unit.rented')}</MenuItem>
                      )}
                      <MenuItem value="soon">{translate('unit.soon')}</MenuItem>
                    </Select>

                    {/* <DatePicker
                        label={translate('unit.availabilityDate')}
                        name="availability_date"
                        // onChange={(newValue) => {
                        //   formik.values.availability_date = newValue;
                        //   console.log(formik.values);
                        // }}
                        onChange={formik.handleChange}
                        value={formik.values.availability_date}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                      /> */}
                  </FormControl>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      {translate('unit.owner')}
                    </Typography>
                    {formik.initialValues.owner_id &&
                      formik.values.owner_id &&
                      formik.initialValues.owner_id === formik.values.owner_id &&
                      !activeMaktbSubscription?.is_trial &&
                      !(
                        workspace?.role_id === TENANT_ROLES.companyMember &&
                        userInfo?.id !== property?.owner?.created_by
                      ) && (
                        <Link href={`/maktb/crm/contacts/${formik.values.owner_id}`} underline="hover">
                          <Tooltip title={translate('contact.navigateToContact')}>
                            <IconButton variant="outlined" color="secondary">
                              <Iconify icon="icon-park-outline:share" />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      )}
                  </Box>
                }
              />
              <CardContent>
                {formik.values.owner && (
                  <Grid item xs={12}>
                    <TextField
                      {...commonFieldsProps}
                      label={translate('unit.owner')}
                      value={formik.values.owner}
                      disabled
                      fullWidth
                      helperText={
                        <Button
                          onClick={() => {
                            formik.setFieldValue('owner_id', '');
                            formik.setFieldValue('owner', '');
                          }}
                        >
                          {translate('unit.changeOwner')}
                        </Button>
                      }
                    />
                  </Grid>
                )}

                {!formik.values.owner && (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Box sx={{ display: 'flex', w: 1, mb: 4 }}>
                        <Autocomplete
                          options={search || []}
                          loading={!!searchLoading}
                          getOptionLabel={serializeOption}
                          filterOptions={(x) => x}
                          fullWidth
                          onInputChange={handleSearch}
                          onChange={(e, v) => formik.setFieldValue('owner_id', v.id)}
                          isOptionEqualToValue={(opt, value) => serializeOption(opt) === serializeOption(value)}
                          renderInput={(params) => (
                            <TextField
                              name="owner_id"
                              error={formik.touched.owner_id && formik.errors.owner_id}
                              InputProps={{
                                ...(params.InputProps || {}),
                                startAdornment: <SearchIcon />,
                                type: 'search',
                              }}
                              value={formik.values.owner_id}
                              placeholder={translate('deal.searchLabel')}
                              label={translate('unit.owner')}
                              {...params}
                            />
                          )}
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                  {translate('unit.location')}
                </Typography>

                <Box>
                  <LocationSelector
                    formik={formik}
                    onLocation={handleChangeLocation}
                    onMapClick={handleMapClick}
                    markerPosition={markerPosition}
                    onPlaceChanged={onPlaceChanged}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                  {translate('unit.publishedStatus')}
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label={translate('unit.publishedOnWebsite')}
                      control={<Checkbox name="published_on_website" checked={formik.values.published_on_website} />}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                      <FormControlLabel
                        label={translate('unit.publishedOnNuzulApp')}
                        control={<Checkbox name="published_on_app" checked={formik.values.published_on_app} />}
                        onChange={formik.handleChange}
                      />
                    </Grid> */}
                  <Grid item xs={12}>
                    <TextField
                      {...commonFieldsProps}
                      name="sort"
                      label={translate('unit.sort')}
                      value={formik.values.sort}
                      fullWidth
                      onChange={formik.handleChange}
                      helperText={translate('unit.sortHelperText')}
                    />
                  </Grid>
                </Grid>

                <Typography variant="h6" paragraph sx={{ mb: 4, mt: 2 }}>
                  {translate('unit.advertiser.title')}
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth error={formik.touched.advertiser_type && formik.errors.advertiser_type}>
                      <InputLabel id="advertiser-type" shrink>
                        {translate('unit.advertiser.name')}
                      </InputLabel>
                      <Select
                        labelId="advertiser-type"
                        value={formik.values.advertiser_type || 'owner'}
                        onChange={formik.handleChange}
                        input={
                          <OutlinedInput notched name="advertiser_type" label={translate('unit.advertiser.name')} />
                        }
                      >
                        <MenuItem value="owner">{translate('unit.advertiser.advertiser')}</MenuItem>
                        <MenuItem value="broker">{translate('unit.advertiser.broker')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...commonFieldsProps}
                      name="rega_advertiser_number"
                      label={translate('unit.advertiser.licenseNumber')}
                      value={formik.values.rega_advertiser_number}
                      fullWidth
                      onChange={formik.handleChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      {...commonFieldsProps}
                      name="rega_ad_number"
                      label={translate('unit.advertiser.adNumber')}
                      value={formik.values.rega_ad_number}
                      fullWidth
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ mb: 1, mt: 1 }}>
                  <FormControlLabel
                    label={translate('unit.isWafiAd')}
                    control={<Checkbox name="is_wafi_ad" checked={formik.values.is_wafi_ad} />}
                    onChange={formik.handleChange}
                  />
                </Grid>

                {formik.values.is_wafi_ad && (
                  <Grid item xs={12}>
                    <TextField
                      {...commonFieldsProps}
                      name="wafi_license_number"
                      label={translate('unit.wafiLicenseNumber')}
                      value={formik.values.wafi_license_number}
                      fullWidth
                      onChange={formik.handleChange}
                    />
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Notes id={id} type={'property'} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PropertyForm;
