import { useMemo } from 'react';
// @mui
import { Card, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material';
// hooks
import { useSelector } from 'react-redux';
import useSettings from '../../hooks/useSettings';
import useLocales from '../../hooks/useLocales';
// components
import Page from '../../components/Page';

import { selectors as userSelectors } from '../../models/user/reducers';
import useDeferredState from '../../hooks/useDeferredState';
import UtilsServices from '../../models/utils/services';

// ----------------------------------------------------------------------

export default function Dashboard() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const userInfo = useSelector(userSelectors.me);

  return (
    <Page title={translate('dashboard')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {translate('hi')} {userInfo?.name}! {languageCode === 'ar' ? '' : translate('Welcome back')} 👋🏼
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} />

          <Grid item xs={12} sm={6} md={4} />

          <Grid item xs={12} sm={6} md={4} />

          <Grid item xs={12} sm={6} md={4} />

          <Grid item xs={12} md={6} />

          <Grid item xs={12} md={6} />
        </Grid>
      </Container>
    </Page>
  );
}
