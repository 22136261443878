import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
  getLoading: false,
  notifications: null,
  paginatedNotifications: null,
};

const reducer = createReducer(initialState, {
  [actions.getNotificationsRequest]: (state) => {
    state.getLoading = true;
  },
  [actions.getNotificationsSuccess]: (state, action) => {
    state.getLoading = false;
    state.notifications = action.payload.data;
  },
  [actions.getNotificationsFailure]: (state) => {
    state.getLoading = false;
  },
  [actions.getPaginatedNotificationsRequest]: (state) => {
    state.getLoading = true;
  },
  [actions.getPaginatedNotificationsSuccess]: (state, action) => {
    state.getLoading = false;
    state.paginatedNotifications = action.payload;
  },
  [actions.getPaginatedNotificationsFailure]: (state) => {
    state.getLoading = false;
  },
  [actions.markAsReadRequest]: (state) => {
    state.getLoading = true;
  },
  [actions.markAsReadSuccess]: (state, action) => {
    const updateReadStatus = (notifications) =>
      notifications.map((notification) => {
        if (notification.id === action.payload.id) {
          return { ...notification, read_at: new Date().toISOString() };
        }
        return notification;
      });

    state.notifications = updateReadStatus(state.notifications);

    if (state.paginatedNotifications) {
      state.paginatedNotifications = {
        ...state.paginatedNotifications,
        data: updateReadStatus(state.paginatedNotifications.data),
      };
    }

    state.getLoading = false;
  },
  [actions.markAsUnreadSuccess]: (state, action) => {
    const updateUnreadStatus = (notifications) =>
      notifications.map((notification) => {
        if (notification.id === action.payload.id) {
          return { ...notification, read_at: null };
        }
        return notification;
      });

    state.notifications = updateUnreadStatus(state.notifications);

    if (state.paginatedNotifications) {
      state.paginatedNotifications = {
        ...state.paginatedNotifications,
        data: updateUnreadStatus(state.paginatedNotifications.data),
      };
    }
  },
  [actions.deleteNotificationSuccess]: (state, action) => {
    const deleteNotification = (notifications) =>
      notifications.filter((notification) => notification.id !== action.payload.id);

    state.notifications = deleteNotification(state.notifications);

    if (state.paginatedNotifications) {
      state.paginatedNotifications = {
        ...state.paginatedNotifications,
        data: deleteNotification(state.paginatedNotifications.data),
      };
    }
  },
  [actions.deleteAllNotificationsSuccess]: (state) => {
    state.notifications = [];
    state.paginatedNotifications = null;
  },
  [actions.markAsReadFailure]: (state) => {
    state.getLoading = false;
  },
  [actions.markAllAsReadRequest]: (state) => {
    state.getLoading = true;
  },
  [actions.markAllAsReadSuccess]: (state) => {
    const markAllRead = (notifications) =>
      notifications.map((notification) => ({
        ...notification,
        read_at: new Date().toISOString(),
      }));

    state.notifications = markAllRead(state.notifications);

    if (state.paginatedNotifications) {
      state.paginatedNotifications = {
        ...state.paginatedNotifications,
        data: markAllRead(state.paginatedNotifications.data),
      };
    }

    state.getLoading = false;
  },
  [actions.markAllAsReadFailure]: (state) => {
    state.getLoading = false;
  },
});

const getLoading = (state) => state.notifications.getLoading;
const notifications = (state) => state.notifications?.notifications;
const paginatedNotifications = (state) => state.notifications?.paginatedNotifications;

const selectors = {
  getLoading,
  notifications,
  paginatedNotifications,
};

export { initialState, reducer, selectors };
export default reducer;
