import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
  loading: false,
  me: null,
  invitations: null,
  workspaces: null,
  workspace: null,
};

const reducer = createReducer(initialState, {
  [actions.getUserInformationRequest]: (state) => {
    state.loading = true;
  },
  [actions.getUserInformationSuccess]: (state, action) => {
    state.loading = false;
    state.me = action.payload.data;

    const { workspaces } = action.payload.data;
    localStorage.setItem('workspaces', JSON.stringify(workspaces));
    const tenantId = parseInt(localStorage.getItem('tenant_id'), 10);

    if (tenantId) {
      const currentWorkspace = workspaces.find((element) => element.id === tenantId);
      const defaultProduct = currentWorkspace.default_product;
      localStorage.setItem('tenant_url', currentWorkspace.domain);
      localStorage.setItem('tenant_role', currentWorkspace.company_role.role_id);
      localStorage.setItem('member_id', currentWorkspace.member_id);
      if (!localStorage.getItem('selected_product')) {
        if (defaultProduct) {
          localStorage.setItem('selected_product', defaultProduct === 'office' ? 'maktb' : 'siyaha');
        } else {
          localStorage.setItem('selected_product', 'maktb');
        }
      }
    } else {
      let defaultWorkspace = workspaces.filter((workspace) => workspace.is_default === true);
      if (!defaultWorkspace.length) {
        defaultWorkspace = workspaces?.[0];
      }
      if (defaultWorkspace) {
        localStorage.setItem('tenant_id', defaultWorkspace[0].id);
        localStorage.setItem('tenant_url', defaultWorkspace[0].domain);
        localStorage.setItem('tenant_role', defaultWorkspace[0].company_role.role_id);
        localStorage.setItem('member_id', defaultWorkspace[0].member_id);
        if (!localStorage.getItem('selected_product')) {
          const selectedProduct = defaultWorkspace[0].default_product;
          if (selectedProduct) {
            localStorage.setItem('selected_product', selectedProduct === 'office' ? 'maktb' : 'siyaha');
          } else {
            localStorage.setItem('selected_product', 'maktb');
          }
        }
      }
    }
  },
  [actions.getUserInformationFailure]: (state, action) => {
    state.loading = false;
    if (action.payload?.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user_role_id');
      localStorage.removeItem('tenant_role');
      localStorage.removeItem('workspaces');
      localStorage.removeItem('tenant_url');
      localStorage.removeItem('tenant_index');
      localStorage.removeItem('tenant_id');
      localStorage.removeItem('selected_product');
      window.location.reload(true);
    }
  },

  [actions.getUserInvitationsRequest]: (state) => {
    state.loading = true;
  },
  [actions.getUserInvitationsSuccess]: (state, action) => {
    state.loading = false;
    state.invitations = action.payload.data;
  },
  [actions.getUserInvitationsFailure]: (state) => {
    state.loading = false;
  },

  [actions.getUserWorkspacesRequest]: (state) => {
    state.loading = true;
  },
  [actions.getUserWorkspacesSuccess]: (state, action) => {
    state.loading = false;
    state.workspaces = action.payload.data;
  },
  [actions.getUserWorkspacesFailure]: (state) => {
    state.loading = false;
  },

  [actions.getWorkspaceByIdRequest]: (state) => {
    state.loading = true;
  },
  [actions.getWorkspaceByIdSuccess]: (state, action) => {
    state.loading = false;
    state.workspace = action.payload.data;
  },
  [actions.getWorkspaceByIdFailure]: (state) => {
    state.loading = false;
  },
  [actions.editProfileRequest]: (state) => {
    state.loading = true;
  },
  [actions.editProfileSuccess]: (state) => {
    state.loading = false;
  },
  [actions.editProfileFailure]: (state) => {
    state.loading = false;
  },
  [actions.leaveWorkspaceRequest]: (state) => {
    state.loading = true;
  },
  [actions.leaveWorkspaceSuccess]: (state) => {
    state.loading = false;
  },
  [actions.leaveWorkspaceFailure]: (state) => {
    state.loading = false;
  },
});

const loading = (state) => state.user.loading;
const me = (state) => state.user.me;
const invitations = (state) => state.user.invitations;
const workspaces = (state) => state.user.workspaces;
const workspace = (state) => state.user.workspace;

const selectors = {
  loading,
  me,
  invitations,
  workspaces,
  workspace,
};

export { initialState, reducer, selectors };
export default reducer;
