import * as React from 'react';
import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import useLocales from '../hooks/useLocales';
import { selectors as userSelectors } from '../models/user/reducers';

let options = localStorage.getItem('workspaces') || null;
// const tenantIndex = localStorage.getItem('tenant_index') || 0;
const selectedProduct = localStorage.getItem('selected_product');
options = options !== null ? JSON.parse(options) : null;

export default function ProductSwitcher({ isCollapse = false }) {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedIndex, setSelectedIndex] = useState(tenantIndex);

  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, product) => {
    localStorage.setItem('selected_product', product);
    window.location.href = '/';
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    (options && (
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <>
          <List
            component="nav"
            aria-label="Device settings"
            style={{
              marginTop: 12,
              borderStyle: !isCollapse ? 'dotted' : 'none',
              borderWidth: !isCollapse ? '1px' : 'none',
              borderColor: !isCollapse ? 'rgba(145, 158, 171, 0.17)' : 'none',
              borderRadius: 7,
              width: '90%',
              paddingTop: '0px',
              paddingBottom: '0px',
            }}
          >
            <ListItem
              button
              id="lock-button"
              aria-haspopup="listbox"
              aria-controls="lock-menu"
              aria-label="when device is locked"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClickListItem}
              style={{
                borderRadius: 7,
                backgroundColor: 'rgba(247, 247, 247,0.1)',
                justifyContent: 'center',
              }}
            >
              {/* {!isCollapse && (
                <Avatar
                  src="/assets/icons/navbar/ic_sq.svg"
                  alt="Nuzul"
                  sx={{ height: 20, width: 20, borderRadius: 0, opacity: '0.4' }}
                />
              )} */}

              {/* active product */}

              {!isCollapse && selectedProduct === 'maktb' && (
                <>
                  <Avatar
                    src="/assets/icons/navbar/ic_n.svg"
                    alt="Nuzul"
                    sx={{ height: 20, width: 20, borderRadius: 0.5 }}
                  />
                  <ListItemText
                    secondaryTypographyProps={{ fontSize: '14px' }}
                    primaryTypographyProps={{ fontSize: '10px' }}
                    secondary={languageCode === 'en' ? 'Nuzul Maktb' : 'نزل مكتب'}
                    // primary={translate('Workspaces')}
                    style={{ marginRight: 10, marginLeft: 20 }}
                  />
                </>
              )}

              {!isCollapse && selectedProduct === 'siyaha' && (
                <>
                  <Avatar
                    src="/assets/icons/navbar/ic_ns.svg"
                    alt="Nuzul"
                    sx={{ height: 20, width: 20, borderRadius: 0.5 }}
                  />
                  <ListItemText
                    secondaryTypographyProps={{ fontSize: '14px' }}
                    primaryTypographyProps={{ fontSize: '10px' }}
                    secondary={languageCode === 'en' ? 'Nuzul Siyaha' : 'نزل سياحة'}
                    // primary={translate('Workspaces')}
                    style={{ marginRight: 10, marginLeft: 20 }}
                  />
                </>
              )}

              {!isCollapse && selectedProduct === 'pay' && (
                <>
                  <Avatar
                    src="/assets/icons/navbar/ic_npay.svg"
                    alt="Nuzul"
                    sx={{ height: 20, width: 20, borderRadius: 0.5 }}
                  />

                  <ListItemText
                    secondaryTypographyProps={{ fontSize: '14px' }}
                    primaryTypographyProps={{ fontSize: '10px' }}
                    secondary={languageCode === 'en' ? 'Nuzul Pay' : 'نزل Pay'}
                    // primary={translate('Workspaces')}
                    style={{ marginRight: 10, marginLeft: 20 }}
                  />
                </>
              )}

              {/* active product */}
            </ListItem>
          </List>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}
          >
            <MenuItem
              key={'maktb'}
              selected={selectedProduct === 'maktb'}
              onClick={(event) => handleMenuItemClick(event, 'maktb')}
            >
              <div style={{}}>
                <Avatar src="/assets/icons/navbar/ic_n.svg" alt="Nuzul" sx={{ borderRadius: 1 }} />
              </div>
              <div style={{ marginRight: 10, marginLeft: 20 }}>
                {languageCode === 'en' ? 'Nuzul Maktb' : 'نزل مكتب'}
              </div>
            </MenuItem>

            <MenuItem
              key={'siyaha'}
              selected={selectedProduct === 'siyaha'}
              onClick={(event) => handleMenuItemClick(event, 'siyaha')}
              sx={{ borderTop: '1px solid rgba(145, 158, 171, 0.17)' }}
            >
              <div style={{}}>
                <Avatar src="/assets/icons/navbar/ic_ns.svg" alt="Nuzul Siyaha" sx={{ borderRadius: 1 }} />
              </div>
              <div style={{ marginRight: 10, marginLeft: 20 }}>
                {languageCode === 'en' ? 'Nuzul Siyaha' : 'نزل سياحة'}
              </div>
            </MenuItem>

            <MenuItem
              key={'pay'}
              disabled
              selected={selectedProduct === 'pay'}
              onClick={(event) => handleMenuItemClick(event, 'pay')}
              sx={{ borderTop: '1px solid rgba(145, 158, 171, 0.17)' }}
            >
              <div style={{}}>
                <Avatar src="/assets/icons/navbar/ic_npay.svg" alt="Nuzul Pay" sx={{ borderRadius: 1 }} />
              </div>
              <div style={{ marginRight: 10, marginLeft: 20 }}>{languageCode === 'en' ? 'Nuzul Pay' : 'نزل Pay'}</div>
            </MenuItem>
          </Menu>
        </>
      </div>
    )) || <></>
  );
}
