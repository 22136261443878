import React, { useEffect, useState } from 'react';

import { Box, Card, CardContent, Grid, TextField, Typography, debounce, Container, Chip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import PropertyCard from '../../../components/PropertyCard';
import { Loader } from '../../../components/Loader';
import useLocales from '../../../hooks/useLocales';

import Page from '../../../components/Page';

import useSettings from '../../../hooks/useSettings';

const commonFieldsProps = {
  InputLabelProps: {
    shrink: true,
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const calculateNights = (checkInDate, checkOutDate) => {
  const checkIn = new Date(checkInDate);
  const checkOut = new Date(checkOutDate);
  const diffTime = Math.abs(checkOut - checkIn);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const ReservationsForm = ({ formik, filters, isLoading }) => {
  const [tabIdx, setTabIdx] = useState(0);
  const { currentLang } = useLocales();
  const { themeStretch } = useSettings();

  const totalNights = calculateNights(formik.values.check_in_date, formik.values.check_out_date);

  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  return (
    <Page title={translate('siyaha.reservation.reservationDetails')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box
          sx={{
            '& div.MuiBox-root': {
              p: 0,
            },
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && (
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item xs={11} lg={8}>
                <Grid container spacing={2}>
                  {/* dates */}
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                          {translate('siyaha.reservation.dates')}
                        </Typography>

                        <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                {...commonFieldsProps}
                                label={translate('siyaha.reservation.checkInDate')}
                                value={formik.values.check_in_date || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                {...commonFieldsProps}
                                label={translate('siyaha.reservation.checkOutDate')}
                                value={formik.values.check_out_date || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                {...commonFieldsProps}
                                name="purpose"
                                label={translate('siyaha.reservation.numberOfNights')}
                                value={totalNights || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* guest */}
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                          {translate('guest.information')}
                        </Typography>

                        <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label={translate('siyaha.reservation.guestName')}
                                value={formik.values.tenant_contact?.name || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label={translate('siyaha.reservation.guestMobileNumber')}
                                value={formik.values.tenant_contact?.mobile_number || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label={translate('siyaha.reservation.guestEmail')}
                                value={formik.values.tenant_contact?.email || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={11} lg={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" paragraph sx={{ mb: 2 }}>
                          {translate('siyaha.reservation.status')}
                        </Typography>

                        <Grid item xs={12} sm={12} md={12}>
                          <Chip
                            sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                            label={translate(`siyaha.reservation.statues.${formik.values.status}`)}
                          />
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" paragraph sx={{ mb: 2 }}>
                          {translate('siyaha.reservation.propertyInformation')}
                        </Typography>
                        {formik.values.property && <PropertyCard property={formik.values.property} />}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default ReservationsForm;
