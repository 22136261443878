/* eslint-disable import/no-named-as-default */
import { takeLatest, all, call, put } from 'redux-saga/effects';
import * as actions from './actions';
import WorkspaceServices from './services';

export function* getWorkspaceInformationSaga() {
  try {
    const response = yield call(WorkspaceServices.getWorkspaceInformation);
    yield put(actions.getWorkspaceInformationSuccess(response.data));
  } catch (err) {
    yield put(actions.getWorkspaceInformationFailure(err));
  }
}

export function* editWorkspaceInformationSaga({ payload }) {
  try {
    const response = yield call(WorkspaceServices.updateWorkspaceInformation, payload);
    yield put(actions.editWorkspaceInformationSuccess(response.data.data));
    payload?.callback?.('success', response.data.data);
  } catch (err) {
    payload?.callback?.('error', err.response);
    yield put(actions.editWorkspaceInformationFailure(err));
  }
}

export function* workspaceSaga() {
  yield all([
    takeLatest(actions.getWorkspaceInformationRequest, getWorkspaceInformationSaga),
    takeLatest(actions.editWorkspaceInformationRequest, editWorkspaceInformationSaga),
  ]);
}
