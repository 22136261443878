import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
// @mui
import { Box, Button, Card, CardContent, Container, Grid, TextField, Typography } from '@mui/material';
// hooks
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import useLocales from '../../hooks/useLocales';

// components
import Page from '../../components/Page';

import * as userActions from '../../models/user/actions';
import * as subscriptionActions from '../../models/subscription/actions';
import * as productActions from '../../models/product/actions';
import * as orderActions from '../../models/order/actions';

import { selectors as userSelectors } from '../../models/user/reducers';
import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';
import { selectors as orderSelectors } from '../../models/order/reducers';
import './workspace.css';
import OrderOptionsMenu from './OrderOptionsMenu';
import LogoSelector from './LogoSelector';

// ----------------------------------------------------------------------

export default function Workspace() {
  const { translate } = useLocales();
  const dispatch = useDispatch();

  const { id } = useParams();

  const workspace = useSelector(userSelectors.workspace);
  const subscriptions = useSelector(subscriptionSelectors.subscriptions);

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const orders = useSelector(orderSelectors.orders);

  const activeSiyahaSubscription = activeSubscriptions?.find((sub) => sub.solution === 'siyaha');
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  useEffect(() => {
    dispatch(userActions.getWorkspaceByIdRequest(id));
    dispatch(subscriptionActions.getTenantSubscriptionsRequest());
    dispatch(subscriptionActions.getActiveSubscriptionRequest());
    dispatch(orderActions.getOrdersRequest());
    dispatch(productActions.getProductsRequest());
  }, []);

  const callback = (type) => {
    if (type === 'success') {
      toast('Success!', { type: 'success' });
    } else {
      toast('Error!', { type: 'error' });
    }
  };

  const formik = useFormik({
    onSubmit: (values) => {
      dispatch(userActions.editWorkspaceByIdRequest({ workspaceId: workspace.id, ...values, callback }));
    },
    initialValues: {
      id: workspace?.id,
      name_en: workspace?.name_en || '',
      name_ar: workspace?.name_ar || '',
      logo_url: workspace?.logo_url || null,
    },
    enableReinitialize: true,
  });

  const subscriptionColumns = [
    {
      field: 'id',
      headerName: '#',
      width: 90,
    },
    {
      field: 'status',
      headerName: translate('workspace.table.status'),
      width: 150,
      cellClassName: 'cell-status',
      valueGetter: (params) => translate(`workspace.table.${params.row.status}`),
      renderCell: (p) => {
        const { row } = p;
        if (row.status === 'active') {
          return <span className="cell-status cell-green">{p.formattedValue}</span>;
        }

        if (row.status === 'expired') {
          return <span className="cell-status cell-yellow">{p.formattedValue}</span>;
        }

        return <span>{p.formattedValue}</span>;
      },
    },
    {
      field: 'start_date',
      headerName: translate('workspace.table.startDate'),
      width: 180,
    },
    {
      field: 'end_date',
      headerName: translate('workspace.table.endDate'),
      width: 180,
    },
  ];

  const ordersColumns = [
    {
      field: 'id',
      headerName: '#',
      width: 90,
    },
    {
      field: 'status',
      headerName: translate('workspace.table.status'),
      width: 150,
      cellClassName: 'cell-status',
      renderCell: (p) => {
        const { row } = p;
        if (row.status === 'completed') {
          return <span className="cell-status cell-green">{p.formattedValue}</span>;
        }

        if (row.status === 'pending_payment') {
          return <span className="cell-status cell-yellow">{p.formattedValue}</span>;
        }

        return <span className="cell-status">{p.formattedValue}</span>;
      },
      valueGetter: (params) => translate(`workspace.table.${params.row.status}`),
    },
    {
      field: 'type',
      headerName: translate('workspace.table.type'),
      width: 150,
      valueGetter: (params) => translate(`workspace.table.${params.row.type}`),
    },
    {
      field: 'total_amount_with_tax',
      headerName: translate('workspace.table.total_amount_with_tax'),
      flex: true,
      maxWidth: 300,
    },
    {
      field: 'created_at',
      headerName: translate('workspace.table.orderDate'),
      width: 180,
    },
    {
      field: 'cancel_order',
      headerName: '',
      width: 50,
      renderCell: (c) => (c.status === 'pending' ? <OrderOptionsMenu order={c.row} /> : <span />),
    },
  ];

  return (
    <Page title={translate('workspaceTitle')}>
      <Container maxWidth={'md'}>
        <Typography variant="h3" component="h1" paragraph>
          {translate('workspaceTitle')}
        </Typography>
        <Card sx={{ p: 2, mb: 2 }}>
          <CardContent>
            <form onSubmit={formik.handleSubmit}>
              <Typography variant="h4" paragraph>
                {translate('workspace.generalInformation')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name_en"
                    label={translate('workspace.nameEn')}
                    value={formik.values.name_en}
                    onChange={formik.handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="name_ar"
                    label={translate('workspace.nameAr')}
                    value={formik.values.name_ar}
                    onChange={formik.handleChange}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {translate('workspace.save')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>

        <Card sx={{ p: 2, mb: 2 }}>
          <CardContent>
            <form onSubmit={formik.handleSubmit}>
              <Typography variant="h4" paragraph>
                {translate('workspace.companyLogo')}
              </Typography>

              <Grid
                item
                xs={12}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <LogoSelector formik={formik} />
                <Typography sx={{ color: '#637381', mt: 2 }}>
                  {translate(
                    'For best resolution and ratio use 500px X 500px as minimum 1:1 ratio. Only PNG and JPG files are supported.'
                  )}
                </Typography>
              </Grid>
            </form>
          </CardContent>
        </Card>

        <Card sx={{ p: 2 }}>
          <CardContent>
            {activeMaktbSubscription && (
              <Typography variant="h4" paragraph>
                {translate('workspace.plans')}
              </Typography>
            )}

            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
              {activeMaktbSubscription && (
                <>
                  <Box sx={{ display: 'flex' }}>
                    {/* <Card sx={{ ml: 2, width: 384, height: 130, border: '1px solid #919eab3d' }}>
                      <CardContent>
                        <Typography fontSize="12px" noWrap>
                          {translate('workspace.currentPlan')}
                        </Typography>
                        <Typography fontSize={28} fontWeight={700}>
                          {activeMaktbSubscription?.is_trial && 'Trial'}
                          {!activeMaktbSubscription?.is_trial && 'Paid'}
                        </Typography>
                      </CardContent>
                    </Card> */}

                    <Card sx={{ ml: 2, width: 230, height: 130, border: '1px solid #919eab3d' }}>
                      <CardContent>
                        <Typography fontSize="12px" noWrap>
                          {translate('workspace.daysLeftOnPlan')}
                        </Typography>
                        <Typography fontSize={28} fontWeight={700}>
                          {activeMaktbSubscription?.days_left}
                          {translate('workspace.day')}
                        </Typography>
                      </CardContent>
                    </Card>
                    {/* 
                    <Card sx={{ ml: 2, width: 384, height: 130 }}>
                      <CardContent>
                        <Typography fontSize="12px" noWrap>
                          {translate('workspace.numberOfUsers')}
                        </Typography>
                        <Typography fontSize={28} fontWeight={700}>
                          {activeMaktbSubscription?.days_left}/{activeMaktbSubscription?.days_left}
                        </Typography>
                      </CardContent>
                    </Card> */}
                  </Box>

                  <Box sx={{ height: 300, mt: 4, width: '100%', overflowX: 'auto' }}>
                    <DataGrid
                      columns={subscriptionColumns}
                      rows={subscriptions || []}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </Box>
                </>
              )}
              <Typography variant="h4" paragraph>
                {translate('workspace.paymentHistory')}
              </Typography>

              <Box sx={{ height: 300, mt: 4, width: '100%', overflowX: 'auto' }}>
                <DataGrid
                  columns={ordersColumns}
                  rows={orders || []}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
