import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
} from '@mui/material';

import { toast } from 'react-toastify';

// hooks
import { useFormik } from 'formik';
import useSettings from '../../hooks/useSettings';
import useLocales from '../../hooks/useLocales';

// components
import Page from '../../components/Page';

import * as userActions from '../../models/user/actions';
import { editProfileSchema } from './validationSchema';

import { selectors as userSelectors } from '../../models/user/reducers';

// ----------------------------------------------------------------------

export default function Profile() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const dispatch = useDispatch();

  const userInfo = useSelector(userSelectors.me);

  const callback = (status) => {
    if (status === 'success') {
      toast('Information updated successfully', { type: 'success' });
    } else {
      toast('Error updating your information', { type: 'error' });
    }
  };

  useEffect(() => {
    dispatch(userActions.getUserInformationRequest());
  }, []);

  const formik = useFormik({
    initialValues: {
      name: userInfo?.name || '',
      email: userInfo?.email || '',
      mobile_number: userInfo?.mobile_number || '',
      gender: userInfo?.gender || '',
    },
    editProfileSchema,
    onSubmit: (values) => {
      dispatch(userActions.editProfileRequest({ ...values, callback }));
      formik.setTouched({}, false);
    },
    enableReinitialize: true,
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Page title={translate('user.profile')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          {translate('user.profile')}
        </Typography>
        <Grid container justifyContent={'center'}>
          <Card sx={{ width: '80%', p: 2, mb: 2 }}>
            <CardContent>
              <form onSubmit={formik.handleSubmit} style={{ maxWidth: 420, margin: matches ? 'auto' : 'unset' }}>
                <button type="submit" style={{ display: 'none' }}>
                  Submit
                </button>
                <Typography variant="h4" paragraph>
                  {translate('user.personalInformation')}
                </Typography>

                <Grid container gap={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      onChange={formik.handleChange}
                      label={translate('user.name')}
                      value={formik.values.name}
                      error={formik.touched.name}
                      fullWidth
                      hiddenLabel
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      aria-label="Email"
                      type="email"
                      onChange={formik.handleChange}
                      label={translate('user.email')}
                      value={formik.values.email}
                      error={formik.touched.email}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="mobile_number"
                      onChange={formik.handleChange}
                      label={translate('user.mobileNumber')}
                      value={formik.values.mobile_number}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl error={formik.touched.gender} fullWidth>
                      <FormLabel>
                        <Typography color="#212B36">{translate('user.gender')}</Typography>
                      </FormLabel>
                      <RadioGroup row name="gender" onChange={formik.handleChange} value={formik.values.gender}>
                        <FormControlLabel value="male" control={<Radio />} label={translate('user.male')} />
                        <FormControlLabel value="female" control={<Radio />} label={translate('user.female')} />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      sx={{ color: 'white' }}
                      onClick={formik.submitForm}
                      disabled={!formik.dirty}
                    >
                      {translate('user.saveButton')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}
