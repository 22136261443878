import React, { useCallback, useEffect, useState } from 'react';

// @mui
import { Box, Button, Card, CardContent, Checkbox, Container, FormControlLabel, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// hooks
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useSettings from '../../../hooks/useSettings';
import useLocales from '../../../hooks/useLocales';

// components
import Page from '../../../components/Page';
import { selectors as contactSelectors } from '../../../models/contacts/reducers';
import * as contactActions from '../../../models/contacts/actions';
import QuickSearchToolbar from '../../../components/QuickSearchToolbar';

// ----------------------------------------------------------------------

export default function GuestsList() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getContactsLoading = useSelector(contactSelectors.getContactsLoading);
  const contacts = useSelector(contactSelectors.contacts);

  const [queryParams, setQueryParams] = useState({ include_archived: 0, is_siyaha: 1, is_office: 0 });

  const getContactType = useCallback((contact) => {
    const types = [
      'property_owner',
      'property_buyer',
      'property_renter',
      'property_agent',
      'property_broker',
      'property_representative',
    ];

    const result = types.filter((type) => contact[`is_${type}`]);

    const translatedTypes = result.map((type) => translate(`contact.${type}`)).join(' - ');

    return translatedTypes;
  }, []);

  const columns = [
    {
      field: 'id',
      headerName: '#',
      maxWidth: 100,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: translate('contact.name'),
      maxWidth: 380,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mobile_number',
      headerName: translate('contact.mobileNumber'),
      maxWidth: 200,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'created_by',
      headerName: translate('contact.createdBy'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => row.created_by || '-',
    },
  ];

  const handleRow = (params) => {
    navigate(`/siyaha/crm/guests/${params.row.id}`);
  };

  useEffect(() => {
    dispatch(contactActions.getContactsRequest({ ...queryParams }));
  }, [queryParams]);

  const handleCheckboxChange = () => {
    setQueryParams({ ...queryParams, include_archived: !queryParams.include_archived === true ? 1 : 0 });
  };

  return (
    <Page title={translate('guests')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('guests')}
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={() => navigate('/siyaha/crm/guests/add')}
          >
            {translate('guest.addGuest')}
          </Button>
        </Box>

        <Card className="no-animation">
          <CardContent sx={{ p: 0 }}>
            <Box sx={{}}>
              <FormControlLabel
                sx={{ ml: 2, mt: 2 }}
                label={translate('guest.includeArchived')}
                control={<Checkbox name="include_archived" checked={Boolean(queryParams.include_archived)} />}
                onChange={handleCheckboxChange}
              />
            </Box>
            <Box>
              <DataGrid
                onRowClick={handleRow}
                loading={getContactsLoading}
                columns={columns}
                rows={contacts || []}
                getRowClassName={(params) => `clickable ${params.row.is_archived ? 'archived' : ''}`}
                disableColumnFilter
                disableColumnSelector
                components={{
                  Toolbar: QuickSearchToolbar,
                }}
                hideFooterSelectedRowCount
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
                pageSize={10}
                autoHeight
                sx={{
                  '& .datagrid-header': {
                    backgroundColor: '#F4F6F8',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: 0,
                  },
                  border: 'none',
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
