import { Box, Container, Typography, Button, Grid, Paper } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';

export default function Upgrade() {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();
  const navigate = useNavigate();

  function PricingSection() {
    return (
      <Container
        style={{
          overflowX: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          padding: 0,
        }}
      >
        {/* Free Plan */}
        <Grid
          item
          style={{
            width: '50%',
            display: 'inline-block',
            marginRight: '8px',
          }}
          elevation={3}
        >
          <Paper style={{ padding: '20px', height: '500px' }}>
            <Typography variant="h5" gutterBottom>
              Free Plan
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Features included:
            </Typography>
            <ul>
              <li>2 published listings</li>
              <li>Unlimited clients</li>
              <li>Unlimited deals</li>
              <li>Unlimited team members</li>
            </ul>
          </Paper>
        </Grid>

        <Grid
          item
          style={{
            width: '50%',
            display: 'inline-block',
            marginLeft: '8px',
          }}
          elevation={3}
        >
          <Paper style={{ padding: '20px', height: '500px' }}>
            <Typography variant="h5" gutterBottom>
              Paid plan
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Features included:
            </Typography>
            <ul>
              <li>Unlimited published listings</li>
              <li>Unlimited clients</li>
              <li>Unlimited deals</li>
              <li>Unlimited team members</li>
              <li>team members permissions</li>
            </ul>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(`/cart/maktb`);
              }}
            >
              Upgrade now
            </Button>
          </Paper>
        </Grid>
      </Container>
    );
  }

  return (
    <Page title="Website">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('Upgrade')}
            </Typography>
          </Box>
        </Box>
        <PricingSection />
      </Container>
    </Page>
  );
}
