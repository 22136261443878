import { HOST_API, PORT_API, ENV } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class TServiceServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getServices = async () => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/t-services`, {
        headers: this.getHeaders(),
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getService = async (id) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/t-services/${id}`, {
        headers: this.getHeaders(),
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  createService = async (payload) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/t-services`, {
        ...payload,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  editService = async (id, values) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/t-services/${id}`, values);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  setCover = (id, data) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/t-services/${id}/cover`, data);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { TServiceServices };
export default new TServiceServices();
