import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Button, Dialog, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { MobileNumber } from '../auth/components';
import useLocales from '../../hooks/useLocales';
import * as teamActions from '../../models/team/actions';
import { TENANT_ROLES } from '../../utils/constants';
import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';

const inviteMemberSchema = Yup.object({
  code: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  role: Yup.number()
    .required()
    .oneOf([TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate, TENANT_ROLES.companyMember]),
});

const InviteMemberModal = ({ open, onClose, onSubmit }) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);

  const activeSiyahaSubscription = activeSubscriptions?.find((sub) => sub.solution === 'siyaha');
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const callback = (type, res) => {
    if (type === 'success') {
      dispatch(teamActions.getInvitationsRequest());
      toast(translate('teams.inviteScreen.successCreation'), { type: 'success' });
    } else {
      toast(translate(`teams.inviteScreen.${res.message}`), { type: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      code: '+966',
      phone: '',
      role: '',
    },
    isInitialValid: false,
    validationSchema: inviteMemberSchema,
    onSubmit: (values) => {
      dispatch(teamActions.inviteMemberRequest({ ...values, callback }));
      onSubmit(values);
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 370, width: 466, p: 2 }}>
        <Typography sx={{ p: 2 }} variant="h5">
          {translate('member.inviteTitle')}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <button type="submit" style={{ display: 'none' }}>
            Submit
          </button>
          <Box sx={{ mt: 0, mb: 2 }}>
            <MobileNumber formik={formik} />
          </Box>

          <FormControl sx={{ width: 1 }}>
            <InputLabel id="role-select-label">{translate('teams.role')}</InputLabel>
            <Select
              name="role"
              label={translate('teams.role')}
              labelId="role-select-label"
              value={formik.values.role}
              onChange={formik.handleChange}
              error={!!formik.errors.role}
              required
            >
              <MenuItem value={TENANT_ROLES.companyManager}>{translate('teams.managerRole')}</MenuItem>
              <MenuItem disabled={activeMaktbSubscription?.is_trial} value={TENANT_ROLES.companyAssociate}>
                {translate('teams.associateRole')}
                {activeMaktbSubscription?.is_trial && (
                  <span
                    style={{
                      backgroundColor: '#ddd',
                      margin: '0 7px',
                      padding: '3px 7px',
                      borderRadius: 5,
                    }}
                  >
                    {translate('paidPlan')}
                  </span>
                )}
              </MenuItem>
              <MenuItem disabled={activeMaktbSubscription?.is_trial} value={TENANT_ROLES.companyMember}>
                {translate('teams.memberRole')}
                {activeMaktbSubscription?.is_trial && (
                  <span
                    style={{
                      backgroundColor: '#ddd',
                      margin: '0 7px',
                      padding: '3px 7px',
                      borderRadius: 5,
                    }}
                  >
                    {translate('paidPlan')}
                  </span>
                )}
              </MenuItem>
            </Select>
          </FormControl>

          <Divider sx={{ mt: 2 }} />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button variant="outlined" sx={{ color: 'black', borderColor: '#919EAB66', mr: 2 }} onClick={onClose}>
              {translate('member.cancel')}
            </Button>
            <Button disabled={!formik.isValid} variant="contained" sx={{ color: 'white' }} onClick={formik.submitForm}>
              {translate('member.invite')}
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default InviteMemberModal;
