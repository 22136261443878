import * as Yup from 'yup';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  Divider,
  Tab,
  Tabs,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import useSettings from '../../hooks/useSettings';
import useLocales from '../../hooks/useLocales';
import Page from '../../components/Page';
import PropertyForm from './PropertyForm';
import { selectors as propertySelectors } from '../../models/properties/reducers';
import * as propertyActions from '../../models/properties/actions';
import Notes from '../notes/Notes';
import { downloadPdf } from '../../utils/domUtils';
import Iconify from '../../components/Iconify';
import DealsList from '../../components/DealsList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const propertyEditSchema = Yup.object({
  min_price: Yup.number().positive(),
  max_price: Yup.number().positive().moreThan(Yup.ref('min_price'), 'Max price must be more than Min price.'),
  facade: Yup.string().oneOf([
    'north',
    'east',
    'south',
    'west',
    'north_east',
    'north_south',
    'east_west',
    'north_west',
    'south_east',
    'south_west',
    'north_east_west',
    'north_south_east',
    'north_south_west',
    'south_east_west',
    'north_south_east_west',
  ]),
  min_area: Yup.number(),
  max_area: Yup.number().moreThan(Yup.ref('min_area'), 'Max area must be more than Min area.'),
  country_id: Yup.number(),
  city_id: Yup.number(),
  district_id: Yup.number(),
});

const EditProperty = () => {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { id } = useParams();
  const property = useSelector(propertySelectors.property);
  const updateLoading = useSelector(propertySelectors.loading);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const [tabIdx, setTabIdx] = useState(0);

  useEffect(() => {
    dispatch(propertyActions.getPropertyRequest(id));
  }, [id]);

  const downloadAsPdf = () => {
    setIsPdfLoading(true);

    dispatch(
      propertyActions.exportPropertyAsPdfRequest({
        id,
        callback: (type, res) => {
          setIsPdfLoading(false);
          if (type === 'success') {
            downloadPdf(res, 'property');
          } else {
            toast.error('Failed to export property as pdf');
          }
        },
      })
    );
  };

  const updateCallback = (t, data) => {
    if (t === 'success') {
      toast(translate('Successfully updated'), { type: 'success', autoClose: 700 });
    } else {
      toast('Failed to update property', { type: 'error' });
    }
  };

  const toggleArchive = () => () => {
    dispatch(propertyActions.toggleArchivePropertyRequest({ id, callback: updateCallback }));
  };

  const formik = useFormik({
    initialValues: {
      id: property?.id,
      category: property?.category,
      purpose: property?.purpose,
      type: property?.type,
      year_built: property?.year_built || 0,
      street_width: property?.street_width || '',
      street_width_east: property?.street_width_east || '',
      street_width_south: property?.street_width_south || '',
      street_width_west: property?.street_width_west || '',
      selling_price: property?.selling_price || '',
      fee: property?.fee || '',
      vat: property?.vat || '',
      rent_price_monthly: property?.rent_price_monthly || '',
      rent_price_quarterly: property?.rent_price_quarterly || '',
      rent_price_semi_annually: property?.rent_price_semi_annually || '',
      rent_price_annually: property?.rent_price_annually || '',
      area: property?.area || '',
      longitude: property?.longitude || '',
      latitude: property?.latitude || '',
      number_of_floors: property?.number_of_floors || 0,
      unit_floor_number: property?.unit_floor_number || 0,
      bedrooms: property?.bedrooms || 0,
      bathrooms: property?.bathrooms || 0,
      dining_rooms: property?.dining_rooms || 0,
      living_rooms: property?.living_rooms || 0,
      majlis_rooms: property?.majlis_rooms || 0,
      maid_rooms: property?.maid_rooms || 0,
      driver_rooms: property?.driver_rooms || 0,
      mulhaq_rooms: property?.mulhaq_rooms || 0,
      storage_rooms: property?.storage_rooms || 0,
      basement_rooms: property?.basement_rooms || 0,
      elevators: property?.elevators || 0,
      pools: property?.pools || 0,
      balconies: property?.balconies || 0,
      kitchens: property?.kitchens || 0,
      gardens: property?.gardens || 0,
      parking_spots: property?.parking_spots || 0,
      facade: property?.facade || '',
      unit_number: property?.unit_number || 0,
      plan_number: property?.plan_number || '',
      plot_number: property?.plot_number || '',
      number_of_apartments: property?.number_of_apartments || 0,
      published_on_website: !!property?.published_on_website || false,
      published_on_app: !!property?.published_on_app || false,
      cover_image_url: property?.cover_image_url || '',
      is_kitchen_installed: property?.is_kitchen_installed || false,
      is_ac_installed: property?.is_ac_installed || false,
      is_parking_shade: property?.is_parking_shade || false,
      is_furnished: property?.is_furnished || false,

      country_id: property?.country?.id || '',
      city_id: property?.city?.id || '',
      district_id: property?.district?.id || '',

      photos: property?.images?.map((image) => image.url) || [],
      length: property?.length || '',
      width: property?.width || '',

      availability_status: property?.availability_status || '',
      availability_date: property?.availability_date,

      has_water: !!property?.has_water || false,
      has_electricity: !!property?.has_electricity || false,
      has_sewage: !!property?.has_sewage || false,

      advertiser_type: property?.advertiser_type || 'broker',

      rega_ad_number: property?.rega_ad_number || '',
      rega_advertiser_number: property?.rega_advertiser_number || '',

      apartments: property?.apartments || 0,
      stores: property?.stores || 0,

      is_for_farming: !!property?.is_for_farming || false,
      is_for_residential: !!property?.is_for_residential || false,
      is_for_commercial: !!property?.is_for_commercial || false,
      is_for_education: !!property?.is_for_education || false,
      is_for_health: !!property?.is_for_health || false,
      is_for_factory: !!property?.is_for_factory || false,

      owner_id: property?.owner?.id || '',
      owner: property?.owner?.name || '',

      is_wafi_ad: !!property?.is_wafi_ad || false,
      wafi_license_number: property?.wafi_license_number || '',
      sort: property?.sort || 0,
      youtube_video_url: property?.youtube_video_url || '',
      virtual_tour_url: property?.virtual_tour_url || '',
    },
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: propertyEditSchema,
    onSubmit: (values) => {
      dispatch(propertyActions.editPropertyRequest({ ...values, callback: updateCallback }));
    },
  });

  const handleTabChange = (evt, value) => {
    setTabIdx(value);
  };

  return (
    <Page title={translate('units')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Typography variant="h4" paragraph sx={{ mb: 4 }}>
            {translate('unit.information')}
          </Typography>

          <Box>
            <Button
              onClick={downloadAsPdf}
              variant="outlined"
              disabled={isPdfLoading}
              sx={{
                display: 'none',
                color: '#999',
                borderColor: '#999',
                marginLeft: 2,
                '&:hover': {
                  color: '#999',
                  borderColor: '#999',
                  backgroundColor: 'transparent',
                },
              }}
            >
              {translate('unit.downloadAsPdf')}
              {isPdfLoading && <CircularProgress sx={{ ml: 1 }} size={20} />}
              {!isPdfLoading && <Iconify icon="mdi:file-pdf" sx={{ ml: 1 }} />}
            </Button>
            <Button
              onClick={toggleArchive()}
              variant="outlined"
              disabled={updateLoading}
              sx={{
                color: '#999',
                borderColor: '#999',
                marginLeft: 2,
                '&:hover': {
                  color: '#999',
                  borderColor: '#999',
                  backgroundColor: 'transparent',
                },
              }}
            >
              {translate(property?.is_archived ? 'unit.unarchiveButton' : 'unit.archiveButton')}
            </Button>

            <Button
              onClick={formik.submitForm}
              variant="contained"
              sx={{ color: 'white', marginLeft: 2 }}
              disabled={updateLoading || !formik.dirty}
            >
              {!updateLoading && translate('unit.saveButton')}
              {updateLoading && <Loader />}
            </Button>
          </Box>

          {/* <Button
          variant="contained"
          sx={{ p: '11px 22px', color: 'white', width: '176px', height: '48px' }}
          onClick={formik.submitForm}
        >
          {translate('contact.saveButton')}
        </Button> */}
        </Box>
        <PropertyForm
          filters={{
            is_office: true,
          }}
          formik={formik}
          id={id}
        />

        {/* -------------------------- */}
        {/* taps on bottom of the page */}
        {/* -------------------------- */}
        {/* <PropertyForm
          filters={{
            is_office: true,
          }}
          formik={formik}
          id={id}
        />
        <Tabs value={tabIdx} onChange={handleTabChange} sx={{ pt: 2 }}>
          <Tab label={`${translate('deals')} (${property?.total_deals})`} {...a11yProps(0)} />
        </Tabs>

        <Divider sx={{ mb: 2 }} />
        <TabPanel value={tabIdx} index={0}>
          {property?.total_deals > 0 ? (
            <DealsList id={property?.id} idKey="property_id" />
          ) : (
            <Card>
              <CardContent>
                <Typography variant="subtitle1">{translate('unit.noDeals')}</Typography>
              </CardContent>
            </Card>
          )}
        </TabPanel> */}
        {/* -------------------------- */}
        {/* taps on bottom of the page */}
        {/* -------------------------- */}

        {/* ----------------------- */}
        {/* taps on top of the page */}
        {/* ----------------------- */}
        {/* <Tabs value={tabIdx} onChange={handleTabChange} sx={{ pt: 2 }}>
          <Tab label={`الوحدة`} {...a11yProps(0)} />
          <Tab label={`${translate('deals')} (${property?.total_deals})`} {...a11yProps(1)} />
        </Tabs>

        <Divider sx={{ mb: 2 }} />
        <TabPanel value={tabIdx} index={0}>
          <PropertyForm
            filters={{
              is_office: true,
            }}
            formik={formik}
            id={id}
          />
        </TabPanel>
        <TabPanel value={tabIdx} index={1}>
          {property?.total_deals > 0 ? (
            <DealsList id={property?.id} idKey="property_id" />
          ) : (
            <Card>
              <CardContent>
                <Typography variant="subtitle1">{translate('unit.noDeals')}</Typography>
              </CardContent>
            </Card>
          )}
        </TabPanel> */}
        {/* ----------------------- */}
        {/* taps on top of the page */}
        {/* ----------------------- */}
      </Container>
    </Page>
  );
};

export default EditProperty;
