import React, { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Container, Box, Button, Typography } from '@mui/material';
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import ServiceForm from './ServiceForm';
import { Loader } from '../../components/Loader';
import TServices from '../../models/t-services/services';

export const addServiceSchema = Yup.object({
  is_free: Yup.boolean(),
  published_on_website: Yup.boolean(),
  name_ar: Yup.string().required('Required'),
});

const AddService = () => {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const callback = (type, response) => {
    setLoading(false);

    if (type === 'success') {
      toast.success(translate('service.successCreation'));
      navigate(`/maktb/services/${response.data.data.id}`);
    } else {
      if (response.status === 422) {
        const errorMessage = response.data.message;
        toast.error(errorMessage);
      }
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      is_free: false,
      published_on_website: false,
      name_ar: '',
      description_ar: '',
      name_en: '',
      description_en: '',
      base_price: '',
      tax_percentage: '',
      cover_image_url: '',
      is_archived: false,
    },
    validationSchema: addServiceSchema,
    isInitialValid: false,
    onSubmit: (values) => {
      setLoading(true);
      TServices.createService(values).then((response) => {
        if (response.status === 201) {
          callback('success', response);
        }
      });
    },
  });

  return (
    <Page title={translate('service.createPageTitle')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h3" component="h1">
            {translate('services')}
          </Typography>

          <Button
            variant="contained"
            sx={{ p: '11px 22px', color: 'white', width: '176px', height: '48px' }}
            onClick={formik.submitForm}
            disabled={!formik.isValid || loading}
          >
            {!loading && translate('service.createButton')}
            {loading && <Loader />}
          </Button>
        </Box>
        <ServiceForm formik={formik} />
      </Container>
    </Page>
  );
};

export default AddService;
