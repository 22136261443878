import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import parsePhoneNumber from 'libphonenumber-js';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography, Container } from '@mui/material';
import { toast } from 'react-toastify';
import Page from '../../../components/Page';
import useSettings from '../../../hooks/useSettings';
import useLocales from '../../../hooks/useLocales';
import { selectors as contactSelectors } from '../../../models/contacts/reducers';
import * as contactActions from '../../../models/contacts/actions';
import ContactForm from './GuestForm';
import { Loader } from '../../../components/Loader';
import Notes from '../../notes/Notes';

const EditGuest = () => {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const dispatch = useDispatch();
  const contact = useSelector(contactSelectors.contact);
  const [loading, setLoading] = useState(false);

  /* looks ugly, but without a rerender is better */
  let code = '';
  let phone = '';

  if (contact) {
    const parsedPhoneNumber = parsePhoneNumber(`+${contact.mobile_number}`);
    code = parsedPhoneNumber?.countryCallingCode || '';
    phone = parsedPhoneNumber?.nationalNumber || '';
  }
  const callback = (type) => {
    if (type === 'success') {
      toast.success(translate('addContactScreen.successUpdate'));
      setLoading(false);
    } else {
      toast(translate('Error'), { type: 'error' });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      phone,
      code,
      name: contact?.name || '',
      gender: contact?.gender || '',
      email: contact?.email || '',
      is_property_owner: !!contact?.is_property_owner,
      is_property_buyer: !!contact?.is_property_buyer,
      is_property_renter: !!contact?.is_property_renter,
      is_property_broker: !!contact?.is_property_broker,
      is_property_agent: !!contact?.is_property_agent,
      is_property_representative: !!contact?.is_property_representative,
      note: contact?.note || '',
      is_siyaha: true,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      const { ...rest } = values;
      dispatch(
        contactActions.editContactRequest({
          id: contact.id,
          callback,
          values: rest,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(contactActions.getContactByIdRequest(id));
  }, []);

  const toggleArchive = () => () => {
    dispatch(contactActions.toggleArchiveContactRequest({ id: contact.id, callback }));
  };

  return (
    <Page title={translate('guest.information')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
          <Typography variant="h3" component="h1">
            {translate('guests')}
          </Typography>
          <Box>
            <Button
              onClick={toggleArchive()}
              variant="outlined"
              sx={{
                color: '#999',
                borderColor: '#999',
                marginLeft: 2,
                '&:hover': {
                  color: '#999',
                  borderColor: '#999',
                  backgroundColor: 'transparent',
                },
              }}
              disabled={loading}
            >
              {!loading && translate(contact?.is_archived ? 'contact.unarchiveButton' : 'contact.archiveButton')}
              {loading && <Loader />}
            </Button>
            <Button
              variant="contained"
              sx={{ color: 'white', marginLeft: 2 }}
              onClick={formik.submitForm}
              disabled={loading || !formik.dirty}
            >
              {!loading && translate('guest.saveButton')}
              {loading && <Loader />}
            </Button>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <ContactForm formik={formik} phoneNumberDisabled />
          <Grid item xs={12} lg={4}>
            <Notes id={id} type={'contact'} product={'siyaha'} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default EditGuest;
