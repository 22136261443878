import PropTypes from 'prop-types';
import { Box, Card, CardContent, Typography, Link, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import BedIcon from './Icons/BedIcon';
import BathIcon from './Icons/BathIcon';
import useLocales from '../hooks/useLocales';
import useSettings from '../hooks/useSettings';
import Iconify from './Iconify';

import { selectors as userSelectors } from '../models/user/reducers';

const PropertyCard = ({
  property,
  row = false,
  showLink = false,
  children,
  cardHeight = '300px',
  clickable = false,
  condensed = false,
  selected,
  onClick,
}) => {
  const {
    currentLang: { value: languageCode },
  } = useLocales();
  const { themeDirection } = useSettings();
  const isArabic = themeDirection === 'rtl';

  const theme = useTheme();

  const districtName = languageCode === 'ar' ? property.district?.name_ar : property.district?.name_en;
  const cityName = languageCode === 'ar' ? property.city?.name_ar : property.city?.name_en;
  const countryName = languageCode === 'ar' ? property.country?.name_ar : property.country?.name_en;
  const { translate } = useLocales();

  const navigate = useNavigate();

  return (
    <Card
      sx={{
        border: ` ${selected && clickable ? `1px solid ${theme.palette.primary.main}` : ''}`,
        cursor: clickable ? 'pointer' : 'default',
        '&:hover': clickable && {
          border: `1px solid ${theme.palette.primary.main}`,
        },
      }}
      onClick={clickable ? onClick : undefined}
    >
      <CardContent
        sx={{
          p: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Box sx={{ display: 'flex', position: 'relative', flexDirection: row ? 'row' : 'column' }}>
          {showLink && (
            <Link
              href={`/maktb/properties/${property.id}`}
              style={{
                position: 'absolute',
                top: 0,
                ...(isArabic
                  ? {
                      left: 0,
                    }
                  : {
                      right: 0,
                    }),
              }}
            >
              <Tooltip title={translate('unit.navigateToUnit')}>
                <IconButton variant="outlined" color="secondary">
                  <Iconify icon="icon-park-outline:share" />
                </IconButton>
              </Tooltip>
            </Link>
          )}
          <div
            className="image-container"
            style={{
              backgroundImage: property.cover_image_url
                ? `url(${property.cover_image_url})`
                : 'url(/assets/placeholder.svg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: cardHeight,
              ...(condensed && {
                position: 'absolute',
                inset: 0,
                zIndex: -1,
                opacity: 0.3,
                height: '100%',
                width: '100%',
                backgroundSize: '100% 100%',
                filter: 'blur(1px)',
                maskImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%)',
              }),
            }}
          />

          <Box sx={{ p: 2, width: '100%' }}>
            <Typography variant="subtitle1">
              {translate(`deal.properties.${property.type}`)} {translate(`for.${property.purpose}`)}
            </Typography>

            <Typography variant="caption">
              {translate('unit.unit_number')}: {property.unit_number}
            </Typography>

            {!districtName && (
              <Typography paragraph variant="body1" sx={{ fontSize: '1rem' }}>
                -
              </Typography>
            )}

            {districtName && cityName && countryName && (
              <Typography paragraph variant="body1" sx={{ fontSize: '1rem' }}>
                {districtName}, {cityName}, {countryName}
              </Typography>
            )}

            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex' }}>
                {[
                  'villa',
                  'building_apartment',
                  'villa_apartment',
                  'duplex',
                  'townhouse',
                  'mansion',
                  'villa_floor',
                  'tower_apartment',
                ].find((t) => t === property.type) && (
                  <>
                    <BedIcon />
                    <Typography paragraph>{property.bedrooms}</Typography>
                    <Typography paragraph sx={{ ml: 0.5 }}>
                      {translate(`unit.bedrooms`)}
                    </Typography>
                  </>
                )}
              </Box>

              <Box sx={{ display: 'flex' }}>
                {[
                  'villa',
                  'building_apartment',
                  'villa_apartment',
                  'duplex',
                  'townhouse',
                  'mansion',
                  'villa_floor',
                  'tower_apartment',
                ].find((t) => t === property.type) && (
                  <>
                    <BathIcon />
                    <Typography paragraph>{property.bathrooms}</Typography>
                    <Typography paragraph sx={{ ml: 0.5 }}>
                      {translate(`unit.bathrooms`)}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>

            {children}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

PropertyCard.propTypes = {
  property: PropTypes.object.isRequired,
};

export default PropertyCard;
