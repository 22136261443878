import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography, Container } from '@mui/material';
import { toast } from 'react-toastify';
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import ServiceForm from './ServiceForm';
import { Loader } from '../../components/Loader';

import TServices from '../../models/t-services/services';

const EditService = () => {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const [service, setService] = useState({});
  const [loading, setLoading] = useState(false);

  const callback = (type) => {
    if (type === 'success') {
      toast.success(translate('service.successUpdate'));
      setLoading(false);
    } else {
      toast(translate('Error'), { type: 'error' });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id,
      name_ar: service.name_ar || '',
      description_ar: service.description_ar || '',
      name_en: service.name_en || '',
      description_en: service.description_en || '',
      is_free: !!service.is_free,
      published_on_website: !!service.published_on_website,
      is_archived: !!service.is_archived,
      base_price: service.base_price || '',
      tax_percentage: service.tax_percentage || 0,
      cover_image_url: service.cover_image_url,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      TServices.editService(id, values).then((response) => {
        if (response.status === 200) {
          callback('success', response);
        }
      });
    },
  });

  useEffect(() => {
    TServices.getService(id).then((response) => {
      setService(response.data.data);
    });
  }, []);

  return (
    <Page title={translate('service.editPageTitle')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h3" component="h1">
            {translate('services')}
          </Typography>
          <Button
            variant="contained"
            sx={{ p: '11px 22px', color: 'white', width: '176px', height: '48px' }}
            onClick={formik.submitForm}
            disabled={loading}
          >
            {!loading && translate('service.saveButton')}
            {loading && <Loader />}
          </Button>
        </Box>

        <ServiceForm formik={formik} />
      </Container>
    </Page>
  );
};

export default EditService;
