import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import RequestForm from './RequestForm';
import { Loader } from '../../components/Loader';

import tPropertyRequestServices from '../../models/t-property-requests/services';
import * as notificationsActions from '../../models/notifications/actions';
import { selectors as notificationsSelectors } from '../../models/notifications/reducers';
import ConfirmationModal from './ConfirmationModal';
import {
  isNotificationManuallyControlled,
  hasPropertyRequestNotification,
  findPropertyRequestNotification,
} from '../../models/notifications/utils';

const EditRequest = () => {
  const { id } = useParams();

  const { translate } = useLocales();
  const dispatch = useDispatch();

  const notifications = useSelector(notificationsSelectors.notifications);

  const [propertyRequest, setPropertyRequest] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState('');

  const formik = useFormik({
    initialValues: {
      id,
      type: propertyRequest.type || '',
      propertyType: propertyRequest.property_type || '',
      status: propertyRequest.status || '',
      contact: propertyRequest.contact || '',
      is_archived: !!propertyRequest.is_archived,
      purpose: propertyRequest.purpose || '',
      category: propertyRequest.category || '',
      note: propertyRequest.note || '',
      closing_reason: propertyRequest.closing_reason || '',
      actioned_by: propertyRequest.actioned_by || '',
      districts: propertyRequest.districts || [],
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    tPropertyRequestServices.getPropertyRequest(id).then((response) => {
      setPropertyRequest(response.data.data);
    });
  }, [showConfirmationModal]);

  useEffect(() => {
    const isUnseen = hasPropertyRequestNotification(id, notifications || []);
    const notification = findPropertyRequestNotification(id, notifications || []);

    if (notification && isUnseen && !isNotificationManuallyControlled(notification)) {
      dispatch(
        notificationsActions.markAsReadRequest({
          id: notification.id,
        })
      );
    }
  }, [id, notifications]);

  return (
    <Page title={translate('request.editPageTitle')}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        <Typography variant="h4" paragraph sx={{ mb: 4 }}>
          {translate('request.editPageTitle')}
        </Typography>

        <ConfirmationModal
          open={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          onSubmit={() => setShowConfirmationModal(false)}
          actionType={actionType}
          requestId={id}
        />

        <Box>
          {/* {formik.values.status === 'closed' && (
            <Button
              // onClick={{}}
              variant="outlined"
              disabled={false}
              sx={{
                color: '#999',
                borderColor: '#999',
                marginLeft: 2,
                '&:hover': {
                  color: '#999',
                  borderColor: '#999',
                  backgroundColor: 'transparent',
                },
              }}
            >
              {translate(propertyRequest?.is_archived ? 'unit.unarchiveButton' : 'unit.archiveButton')}
            </Button>
          )}

          {formik.values.status === 'converted' && (
            <Button
              // onClick={{}}
              variant="outlined"
              disabled={false}
              sx={{
                color: '#999',
                borderColor: '#999',
                marginLeft: 2,
                '&:hover': {
                  color: '#999',
                  borderColor: '#999',
                  backgroundColor: 'transparent',
                },
              }}
            >
              {translate(propertyRequest?.is_archived ? 'unit.unarchiveButton' : 'unit.archiveButton')}
            </Button>
          )} */}

          {formik.values.status === 'new' && (
            <Button
              onClick={() => {
                setActionType('close');
                setShowConfirmationModal(true);
              }}
              variant="outlined"
              sx={{
                color: '#999',
                borderColor: '#999',
                marginLeft: 2,
                '&:hover': {
                  color: '#999',
                  borderColor: '#999',
                  backgroundColor: 'transparent',
                },
              }}
            >
              {true && translate('request.closeButton')}
              {false && <Loader />}
            </Button>
          )}

          {formik.values.status === 'new' && (
            <Button
              onClick={() => {
                setActionType('convert');
                setShowConfirmationModal(true);
              }}
              variant="contained"
              sx={{ color: 'white', marginLeft: 2 }}
            >
              {true && translate('request.convertToDeal')}
              {false && <Loader />}
            </Button>
          )}
        </Box>
      </Box>
      <RequestForm formik={formik} />
    </Page>
  );
};

export default EditRequest;
