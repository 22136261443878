import { Box, Card, CardContent, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';

import useLocales from '../../hooks/useLocales';
import NumberInput from '../../components/NumberInput';
import CoverPictureSelector from './CoverPictureSelector';

const ServiceForm = ({ formik = false }) => {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  return (
    <Grid container spacing={2}>
      <Grid item xs={11} lg={8}>
        <Card>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 3 }}>
              {translate('service.informationAr')}
            </Typography>

            <Box sx={{ display: 'flex' }}>
              <TextField
                name="name_ar"
                value={formik.values.name_ar}
                label={translate('service.nameAr')}
                fullWidth
                sx={{
                  mb: 2,
                  direction: languageCode === 'ar' ? 'ltr' : 'rtl',
                  textAlign: languageCode === 'ar' ? 'right' : 'right',
                }}
                onChange={formik.handleChange}
              />
            </Box>

            <TextField
              name="description_ar"
              type="text"
              multiline
              rows={4}
              label={translate('service.descriptionAr')}
              error={formik.touched.description_ar && formik.errors.description_ar}
              value={formik.values.description_ar}
              onChange={formik.handleChange}
              sx={{ mb: 2, direction: 'rtl', textAlign: 'right' }}
              fullWidth
            />

            <Typography variant="h6" sx={{ mb: 3 }}>
              {translate('service.informationEn')}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <TextField
                name="name_en"
                value={formik.values.name_en}
                label={translate('service.nameEn')}
                fullWidth
                sx={{ mb: 2, direction: 'ltr', textAlign: 'left' }}
                onChange={formik.handleChange}
              />
            </Box>
            <TextField
              name="description_en"
              type="text"
              multiline
              rows={4}
              label={translate('service.descriptionEn')}
              error={formik.touched.description_en && formik.errors.description_en}
              value={formik.values.description_en}
              onChange={formik.handleChange}
              sx={{ mb: 2, direction: 'ltr', textAlign: 'left' }}
              fullWidth
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={11} lg={4}>
        <Card>
          <CardContent>
            <Typography variant="h6">{translate('service.publishedStatus')}</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <FormControlLabel
                  label={translate('service.publishedOnWebsite')}
                  control={<Checkbox name="published_on_website" checked={formik.values.published_on_website} />}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h6">{translate('service.pricing')}</Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ mt: 1, mb: !formik.values.is_free ? 0 : 2 }}>
                <FormControlLabel
                  label={translate('service.isFree')}
                  control={<Checkbox name="is_free" checked={formik.values.is_free} />}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              {!formik.values.is_free && (
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <NumberInput
                    name="base_price"
                    label={translate('service.basePrice')}
                    formik={formik}
                    showControls={false}
                    fullWidth
                    helperText={translate('Saudi Riyals')}
                    disabled={formik.values.is_free}
                    value={formik.values.base_price}
                  />
                </Grid>
              )}

              {!formik.values.is_free && (
                <Grid item xs={12} sx={{}}>
                  <NumberInput
                    name="tax_percentage"
                    label={translate('service.taxPercentage')}
                    formik={formik}
                    showControls={false}
                    fullWidth
                    helperText={translate('% Tax')}
                    value={formik.values.tax_percentage}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <CoverPictureSelector formik={formik} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ServiceForm;
