import axios from '../../utils/axios';
import { HOST_API, PORT_API, ENV } from '../../config';

const tenantUrl = localStorage.getItem('tenant_url');

class CouponServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  //

  checkCoupon = async (data) =>
    axios.post(
      `${this.getUrl()}/api/coupons/check`,
      {
        code: data,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

  //
}

export { CouponServices };
export default new CouponServices();
