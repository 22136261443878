import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Container,
  Typography,
  Avatar,
  Button,
  Box,
  Divider,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useMemo, React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useSettings from '../../hooks/useSettings';
import useLocales from '../../hooks/useLocales';
// components
import Page from '../../components/Page';
import ChartDonut from '../maktb/ChartDonut';
import ChartLine from '../maktb/ChartLine';
import EditLinkModal from './MemberCardModal';

import { selectors as teamSelectors } from '../../models/team/reducers';
import useDeferredState from '../../hooks/useDeferredState';
import * as teamActions from '../../models/team/actions';
import MembersServices from '../../models/team/services';

const MemberDashboard = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const memberID = id || localStorage.getItem('member_id');

  const { themeDirection } = useSettings();
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const isArabic = themeDirection === 'rtl';

  const params = { is_office: 1, member_id: memberID };

  useEffect(() => {
    dispatch(teamActions.getMemberByIdRequest({ memberID }));
  }, [memberID]);

  const member = useSelector(teamSelectors.member);

  const [clientsCount, clientsLoading] = useDeferredState(
    async () => MembersServices.getTotalClients(params).then((r) => r.data),
    [memberID]
  );

  const [dealsCount, dealsLoading] = useDeferredState(
    async () => MembersServices.getTotalDeals(memberID).then((r) => r.data),
    [memberID]
  );

  const [propertiesCount, propertiesLoading] = useDeferredState(
    async () => MembersServices.getTotalProperties(params).then((r) => r.data),
    [memberID]
  );

  const [propertiesGrowth] = useDeferredState(
    async () => MembersServices.getPropertiesGrowth(params).then((r) => r.data),
    [memberID],
    []
  );

  const [listingsGrowthViews] = useDeferredState(
    async () => MembersServices.getListingsGrowthViews(memberID).then((r) => r.data),
    [memberID],
    []
  );

  const [dealsGrowth] = useDeferredState(
    async () => MembersServices.getDealsGrowth(memberID).then((r) => r.data),
    [memberID],
    []
  );

  const [clientsGrowth] = useDeferredState(
    async () => MembersServices.getClientsGrowth(params).then((r) => r.data),
    [memberID],
    []
  );

  const [dealsStats] = useDeferredState(
    async () => MembersServices.getDealStages(memberID).then((r) => r.data),
    [memberID],
    []
  );

  const lineSeries = useMemo(
    () => [
      {
        name: translate('clients'),
        data: Object.values(clientsGrowth),
        color: '#8165AF',
      },

      {
        name: translate('units'),
        data: Object.values(propertiesGrowth),
        color: '#EF9925',
      },

      {
        name: translate('deals'),
        data: Object.values(dealsGrowth),
        color: '#1FC6AC',
      },
    ],
    [translate, clientsGrowth, propertiesGrowth, dealsGrowth]
  );

  const viewsLineSeries = useMemo(
    () => [
      {
        name: translate('listingsViews'),
        data: Object.values(listingsGrowthViews),
        color: '#1FC6AC',
      },
    ],
    [listingsGrowthViews, translate]
  );

  const donutSeries = Object.values(dealsStats);

  const [showEditLinkModal, setShowEditLinkModal] = useState(false);

  return (
    <Page title={translate('dashboard')}>
      <EditLinkModal
        open={showEditLinkModal}
        onClose={() => setShowEditLinkModal(false)}
        onSubmit={() => setShowEditLinkModal(false)}
        member={member}
        memberID={memberID}
      />
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {translate('Member Insights Dashboard')} {member?.name}!
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar alt={isArabic ? member?.name : member?.name} style={{ margin: 10 }} />
                  <Typography style={{ margin: 10 }}>{isArabic ? member?.name : member?.name}</Typography>
                  <Button disabled variant="outlined" style={{ margin: 10 }}>
                    {isArabic ? member?.role.name_ar : member?.role.name_en}
                  </Button>
                  <Divider orientation="vertical" variant="middle" flexItem />
                </Grid>
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    sx={{ color: 'white', pt: 1, pb: 1, m: 1, mx: 2 }}
                    onClick={() => setShowEditLinkModal(true)}
                  >
                    {translate('member.card')}
                  </Button>

                  {/* <Tooltip title={translate('description')}>
                    <InfoOutlinedIcon color="action" />
                  </Tooltip>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      fontSize: 10,
                      padding: '7px 14px',
                      paddingRight: 0,
                      margin: 7,
                      marginRight: 5,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <p>{translate('member.memberLink')}</p>
                      <Typography
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        fontSize={11}
                        color="#000000"
                        onClick={() => {
                          setShowEditLinkModal(true);
                        }}
                      >
                        {translate(`deal.view`)}
                      </Typography>
                    </Box>
                    <a href={url} target="_blank" rel="noreferrer" style={{ fontSize: 12 }}>
                      {url}
                    </a>
                  </div> */}
                </Grid>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={12}>
            <Card dir="ltr">
              <CardHeader title={`${translate('listingsViews')}`} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              <CardContent
                sx={{
                  height: 420,
                }}
              >
                <ChartLine series={viewsLineSeries} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                py: 5,
                textAlign: 'center',
              }}
            >
              <Typography variant="h3">{dealsLoading ? '-' : dealsCount || 0}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
                {translate('deals')}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                py: 5,
                textAlign: 'center',
              }}
            >
              <Typography variant="h3">{propertiesLoading ? '-' : propertiesCount || 0}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
                {translate('units')}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                py: 5,
                textAlign: 'center',
              }}
            >
              <Typography variant="h3">{clientsLoading ? '-' : clientsCount || 0}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
                {translate('clients')}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card dir="ltr">
              <CardHeader title={`${translate('growth')} 2024`} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              <CardContent
                sx={{
                  height: 420,
                }}
              >
                <ChartLine series={lineSeries} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card dir="ltr">
              <CardHeader title={translate('deals')} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              <CardContent
                sx={{
                  height: 420,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ChartDonut series={donutSeries} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default MemberDashboard;
