// @mui
import { Container, Typography, Grid, Card } from '@mui/material';
// hooks
import { useNavigate } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';
import useLocales from '../../hooks/useLocales';

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

export default function PageOne() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const navigate = useNavigate();

  return (
    <Page title={translate('settings')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          {translate('settings')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                mt: 2,
                py: 2,
                textAlign: 'start',
                px: 4,
                cursor: 'pointer',
                borderRadius: '7px',
                border: 'solid rgba(255, 255, 255, 0.0)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.4)', // Example hover background color
                  border: 'solid white',
                },
              }}
              onClick={() => {
                navigate('account');
              }}
            >
              <Typography variant="subtitle2" color={'secondary'}>
                {translate('setting.accountInformationTitle')}
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.64 }}>
                {translate('setting.accountInformation')}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                mt: 2,
                py: 2,
                textAlign: 'start',
                px: 4,
                cursor: 'pointer',
                borderRadius: '7px',
                border: 'solid rgba(255, 255, 255, 0.0)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.4)', // Example hover background color
                  border: 'solid white',
                },
              }}
              onClick={() => {
                navigate('requests');
              }}
            >
              <Typography variant="subtitle2" color={'secondary'}>
                {translate('setting.propertyRequestsTitle')}
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.64 }}>
                {translate('setting.propertyRequests')}
              </Typography>
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                mt: 2,
                py: 2,
                textAlign: 'start',
                px: 4,
                cursor: 'pointer',
                borderRadius: '7px',
                border: 'solid rgba(255, 255, 255, 0.0)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.4)', // Example hover background color
                  border: 'solid white',
                },
              }}
              onClick={() => {
                navigate('subscriptions');
              }}
            >
              <Typography variant="subtitle2" color={'secondary'}>
                {translate('setting.accountSubscriptionTitle')}
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.64 }}>
                {translate('setting.accountSubscription')}
              </Typography>
            </Card>
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
