import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const setPreferredLanguageHeader = (lang) => {
  axiosInstance.defaults.headers.common['X-Preferred-Language'] = lang;
};

export default axiosInstance;
