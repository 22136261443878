/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';
// @mui
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Tooltip,
  MenuItem,
  ListItemText,
  TextField,
  debounce,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// hooks
import useSettings from '../../hooks/useSettings';
import useLocales from '../../hooks/useLocales';
import Iconify from '../../components/Iconify';

// components
import Page from '../../components/Page';
import * as dealsActions from '../../models/deals/actions';
import { selectors as dealsSelectors } from '../../models/deals/reducers';
import AddDealModal from './AddDealModal';
import Board from '../../components/Kanban/Board';
import { selectors as notificationsSelectors } from '../../models/notifications/reducers';
import { hasDealNotification } from '../../models/notifications/utils';
import SearchIcon from '../../components/Icons/SearchIcon';
import ExportAsSheetButton from '../../components/buttons/ExportAsSheetButton';
import { downloadExcel } from '../../utils/domUtils';
import PropertyCard from '../../components/PropertyCard';

// ----------------------------------------------------------------------

const dealCreationSchema = Yup.object({
  tenant_contact_id: Yup.string().required(),
  category: Yup.string().oneOf(['commercial', 'residential']).required('required'),
  purpose: Yup.string().oneOf(['rent', 'buy']).required('Required'),
  type: Yup.string()
    .oneOf([
      'villa',
      'tower_apartment',
      'building_apartment',
      'villa_apartment',
      'land',
      'duplex',
      'townhouse',
      'mansion',
      'villa_floor',
      'farm',
      'istraha',
      'store',
      'office',
      'storage',
      'building',
      'hotel',
      'resort',
      'compound',
    ])
    .required('Required'),
});

export const dealsPerCategory = (translate) => ({
  new: {
    title: translate('deal.stages.new'),
    borderColor: '#FFC107',
    palette: 'primary',
    items: [],
  },
  visit: {
    title: translate('deal.stages.visit'),
    borderColor: '#1890FF',
    palette: 'info',
    items: [],
  },
  negotiation: {
    title: translate('deal.stages.negotiation'),
    borderColor: '#9E005D',
    palette: 'secondary',
    items: [],
  },
  won: {
    title: translate('deal.stages.won'),
    borderColor: '#54D62C',
    palette: 'success',
    items: [],
  },
  lost: {
    title: translate('deal.stages.lost'),
    borderColor: '#FF5D33',
    palette: 'tertiary',
    items: [],
  },
});

export const dealsColumns = (translate, languageCode) => [
  {
    field: 'id',
    headerName: '#',
    maxWidth: 100,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'contact_name',
    headerName: translate('deal.contactName'),
    maxWidth: 280,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => row.contact?.name,
  },
  {
    field: 'property',
    headerName: translate('deal.property'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) =>
      row.property?.id ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Tooltip
            placement="left"
            arrow
            title={<PropertyCard showLink property={row.property} condensed />}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'white',
                  shadow: (theme) => theme.shadows[25],
                  p: 1,
                  borderRadius: 3,
                },
              },
              arrow: {
                sx: {
                  color: 'white',
                  shadow: (theme) => theme.shadows[25],
                },
              },
            }}
          >
            <Typography variant="body2">{row.property?.unit_number}</Typography>
          </Tooltip>
        </Box>
      ) : (
        '-'
      ),
  },
  {
    field: 'stage',
    headerName: translate('deal.stage'),
    maxWidth: 180,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => translate(`deal.stages.${row.stage}`),
  },
  {
    field: 'city',
    headerName: translate('deal.city'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => (languageCode === 'ar' ? row.city?.name_ar : row.city?.name_en || '-'),
  },
  {
    field: 'purpose',
    headerName: translate('deal.purpose'),
    maxWidth: 180,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => translate(`deal.${row.purpose}`),
  },
  {
    field: 'type',
    headerName: translate('deal.realEstateType'),
    headerClassName: 'datagrid-header',
    flex: true,
    align: 'center',
    headerAlign: 'center',
    valueGetter: ({ row }) => translate(`deal.properties.${row.type}`),
  },
  {
    field: 'sales_person',
    headerName: translate('deal.salesPerson'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => row.sales_person?.name,
  },
  {
    field: 't_notes_count',
    headerName: translate('notes.notes'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => row.t_notes_count || '-',
  },
];

const localStorageKey = `dealsQueryParams.${localStorage.getItem('tenant_id')}`;

export default function DealsView() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listView, setListView] = useState(false);
  const [showDealCreationModal, setShowDealCreationModal] = useState(false);

  const queryParamsFromStorage = JSON.parse(localStorage.getItem(localStorageKey) || '{}');

  const [queryParams, setQueryParams] = useState({
    per_page: queryParamsFromStorage.per_page || 99,
    page: queryParamsFromStorage.page || 1,
    include_archived: queryParamsFromStorage.include_archived || 0,
    stage: queryParamsFromStorage.stage || [],
    type: queryParamsFromStorage.type || [],
    purpose: queryParamsFromStorage.purpose || [],
    name: queryParamsFromStorage.name || null,
    sort_by: queryParamsFromStorage.sort_by || null,
    sort_order: queryParamsFromStorage.sort_order || null,
  });

  const formik = useFormik({
    validationSchema: dealCreationSchema,
    initialValues: {
      tenant_contact_id: '',
      category: '',
      purpose: '',
      type: '',
    },
    onSubmit: (values) => {
      dispatch(dealsActions.createDealRequest({ ...values, callback: handleCreateDealCallback }));
    },
    validateOnMount: true,
  });

  const handleCreateDealCallback = (type, res) => {
    if (type === 'success') {
      setShowDealCreationModal(true);
      navigate(`/maktb/crm/deals/${res.data.id}`);
    } else {
      toast.error(res.message);
    }
  };

  const handleRow = (params) => {
    navigate(`/maktb/crm/deals/${params.row.id}`);
  };

  const onViewChange = () => {
    const newListView = !listView;
    setListView(newListView);

    localStorage.setItem('listView', JSON.stringify(newListView));

    const urlSearchParams = new URLSearchParams(window.location.search);
    if (newListView) {
      urlSearchParams.delete('kanban');
    } else {
      urlSearchParams.set('kanban', '');
    }
    const newUrl = `${window.location.pathname}${urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : ''}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(queryParams));
  }, [queryParams]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const v = urlSearchParams.has('kanban');

    if (v) {
      setListView(false);
    } else {
      setListView(true);
    }

    const storedListView = JSON.parse(localStorage.getItem('listView'));
    if (storedListView !== null) {
      setListView(storedListView);
      if (storedListView) {
        urlSearchParams.delete('kanban');
      } else {
        urlSearchParams.set('kanban', '');
      }
      const newUrl = `${window.location.pathname}${urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : ''}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);
    }

    const normalizedQueryParams = { ...queryParams };

    ['stage', 'type', 'purpose'].forEach((key) => {
      if (normalizedQueryParams[key].length === 0) {
        delete normalizedQueryParams[key];
      } else {
        normalizedQueryParams[key] = normalizedQueryParams[key].join(',');
      }
    });

    if (!normalizedQueryParams.name) {
      delete normalizedQueryParams.name;
    }

    dispatch(dealsActions.getDealsRequest(normalizedQueryParams));
  }, [dispatch, queryParams]);

  const layoutDealsForBoard = () => {
    if (!deals) return [];

    return dealsPerCategory(translate);
  };

  const deals = useSelector(dealsSelectors.deals);
  const totalDeals = useSelector(dealsSelectors.totalDeals);
  const loading = useSelector(dealsSelectors.loading);
  const notifications = useSelector(notificationsSelectors.notifications);

  const columns = dealsColumns(translate, languageCode);

  const editStageCallback = (t) => {
    if (t === 'success') {
      toast(translate('Edited stage successfully'), { type: 'success', autoClose: 300 });
    } else {
      toast('Failed to edit stage', { type: 'error' });
    }
  };

  const handleBoardItemMovement = (itemId, from, to) => {
    dispatch(
      dealsActions.editDealRequest({
        id: itemId,
        callback: editStageCallback,
        from,
        stage: to,
      })
    );
  };

  const handleCheckboxChange = () => {
    setQueryParams({ ...queryParams, include_archived: !queryParams.include_archived === true ? 1 : 0 });
  };

  const handlePageChange = (newPage) => {
    setQueryParams({ ...queryParams, page: newPage + 1 });
  };

  const isUnseen = (id) => hasDealNotification(id, notifications || []);

  const onSearch = (e) => {
    const { value } = e.target;
    setQueryParams({ ...queryParams, name: value });
  };

  const handleSelect = (event, key) => {
    setQueryParams({
      ...queryParams,
      [key]: event.target.value,
    });
  };

  const handleUnselect = (key, value) => {
    setQueryParams({
      ...queryParams,
      [key]: queryParams[key].filter((item) => item !== value),
    });
  };

  const handleClearAll = (key) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      [key]: [],
    }));
  };

  const handleSortModelChange = (sortModel) => {
    setQueryParams({
      ...queryParams,
      ...(sortModel.length > 0 && {
        sort_by: sortModel[0].field,
        sort_order: sortModel[0].sort,
      }),
      ...(sortModel.length === 0 && {
        sort_by: null,
        sort_order: null,
      }),
    });
  };

  const exportAsSheet = () => {
    const normalizedQueryParams = { ...queryParams };

    ['stage', 'type', 'purpose'].forEach((key) => {
      if (normalizedQueryParams[key].length === 0) {
        delete normalizedQueryParams[key];
      } else {
        normalizedQueryParams[key] = normalizedQueryParams[key].join(',');
      }
    });

    if (!normalizedQueryParams.name) {
      delete normalizedQueryParams.name;
    }

    dispatch(
      dealsActions.exportDealsAsSheetRequest({
        ...normalizedQueryParams,
        callback: (type, res) => {
          if (type === 'success') {
            downloadExcel(res, 'deals');
          } else {
            toast.error('Failed to export deals as sheet');
          }
        },
      })
    );
  };

  const stages = [
    { value: 'new', text: translate('deal.stages.new') },
    { value: 'visit', text: translate('deal.stages.visit') },
    { value: 'negotiation', text: translate('deal.stages.negotiation') },
    { value: 'won', text: translate('deal.stages.won') },
    { value: 'lost', text: translate('deal.stages.lost') },
  ];

  const types = [
    { value: 'tower_apartment', text: translate('deal.properties.tower_apartment') },
    { value: 'building_apartment', text: translate('deal.properties.building_apartment') },
    { value: 'villa_apartment', text: translate('deal.properties.villa_apartment') },
    { value: 'villa_floor', text: translate('deal.properties.villa_floor') },
    { value: 'townhouse', text: translate('deal.properties.townhouse') },
    { value: 'duplex', text: translate('deal.properties.duplex') },
    { value: 'villa', text: translate('deal.properties.villa') },
    { value: 'building', text: translate('deal.properties.building') },
    { value: 'mansion', text: translate('deal.properties.mansion') },
    { value: 'store', text: translate('deal.properties.store') },
    { value: 'office', text: translate('deal.properties.office') },
    { value: 'storage', text: translate('deal.properties.storage') },
    { value: 'land', text: translate('deal.properties.land') },
    { value: 'farm', text: translate('deal.properties.farm') },
    { value: 'istraha', text: translate('deal.properties.istraha') },
    { value: 'resort', text: translate('deal.properties.resort') },
    { value: 'hotel', text: translate('deal.properties.hotel') },
    { value: 'compound', text: translate('deal.properties.compound') },
  ];

  const purposes = [
    { value: 'rent', text: translate('unit.rent') },
    { value: 'buy', text: translate('unit.buy') },
  ];

  return (
    <Page title={translate('deals')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <AddDealModal
          open={showDealCreationModal}
          onClose={() => setShowDealCreationModal(false)}
          formik={formik}
          title={translate('deal.creationTitle')}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('deals')}
            </Typography>

            <IconButton
              sx={{ p: 1, border: '1px solid rgba(145, 158, 171, 0.32)', borderRadius: '8px', ml: 2 }}
              onClick={onViewChange}
            >
              {listView ? <Iconify icon={'tabler:layout-kanban'} /> : <Iconify icon={'tabler:table'} />}
            </IconButton>
          </Box>
          <Button
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={() => setShowDealCreationModal(true)}
          >
            {translate('deal.createDealButton')}
          </Button>
        </Box>

        {listView ? (
          <Card className="no-animation">
            <CardContent sx={{ p: 0 }}>
              <Box sx={{}}>
                <FormControlLabel
                  sx={{ ml: 2, mt: 2 }}
                  label={translate('deal.includeArchived')}
                  control={<Checkbox name="include_archived" checked={Boolean(queryParams.include_archived)} />}
                  onChange={handleCheckboxChange}
                />
              </Box>
              <Grid container spacing={2} px={3}>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="select-stage">{translate('deal.stage')}</InputLabel>
                    <Select
                      sx={{ height: '60px' }}
                      label={translate('deal.stage')}
                      labelId="select-stage"
                      multiple
                      value={queryParams.stage}
                      onChange={(e) => handleSelect(e, 'stage')}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2, height: '32px', overflowY: 'auto' }}>
                          {selected.map((value) => (
                            <Chip
                              sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                              key={value}
                              label={translate(`deal.stages.${value}`)}
                              onDelete={() => handleUnselect('stage', value)}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                            />
                          ))}
                          {selected.length > 0 && (
                            <Tooltip title={translate('unit.clearAll')}>
                              <IconButton
                                sx={{ ml: 'auto' }}
                                size="small"
                                onClick={() => handleClearAll('stage')}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      )}
                    >
                      {stages.map((stage) => (
                        <MenuItem key={stage.value} value={stage.value}>
                          <Checkbox checked={queryParams.stage.indexOf(stage.value) > -1} />
                          <ListItemText primary={stage.text} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="select-type">{translate('unit.listingType')}</InputLabel>
                    <Select
                      sx={{ height: '60px' }}
                      label={translate('unit.listingType')}
                      labelId="select-type"
                      multiple
                      value={queryParams.type}
                      onChange={(e) => handleSelect(e, 'type')}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2, height: '32px', overflowY: 'auto' }}>
                          {selected.map((value) => (
                            <Chip
                              sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                              key={value}
                              label={translate(`deal.properties.${value}`)}
                              onDelete={() => handleUnselect('type', value)}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                            />
                          ))}
                          {selected.length > 0 && (
                            <Tooltip title={translate('unit.clearAll')}>
                              <IconButton
                                sx={{ ml: 'auto' }}
                                size="small"
                                onClick={() => handleClearAll('type')}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      )}
                    >
                      {types.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          <Checkbox checked={queryParams.type.indexOf(type.value) > -1} />
                          <ListItemText primary={type.text} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="select-purpose">{translate('unit.purpose')}</InputLabel>
                    <Select
                      sx={{ height: '60px' }}
                      label={translate('unit.purpose')}
                      labelId="select-purpose"
                      multiple
                      value={queryParams.purpose}
                      onChange={(e) => handleSelect(e, 'purpose')}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2, height: '32px', overflowY: 'auto' }}>
                          {selected.map((value) => (
                            <Chip
                              sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                              key={value}
                              label={translate(`unit.${value}`)}
                              onDelete={() => handleUnselect('purpose', value)}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                            />
                          ))}
                          {selected.length > 0 && (
                            <Tooltip title={translate('unit.clearAll')}>
                              <IconButton
                                sx={{ ml: 'auto' }}
                                size="small"
                                onClick={() => handleClearAll('purpose')}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      )}
                    >
                      {purposes.map((purpose) => (
                        <MenuItem key={purpose.value} value={purpose.value}>
                          <Checkbox checked={queryParams.purpose.indexOf(purpose.value) > -1} />
                          <ListItemText primary={purpose.text} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Box sx={{ p: 3 }}>
                <TextField
                  fullWidth
                  placeholder={translate('deal.searchByNames')}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                  defaultValue={queryParams.name}
                  onChange={debounce(onSearch, 200)}
                />
              </Box>
              {deals?.length > 0 && (
                <Box
                  sx={{
                    mx: 2,
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                  }}
                >
                  <ExportAsSheetButton count={totalDeals} onClick={exportAsSheet} />
                </Box>
              )}
              <Box sx={{ mt: 2 }}>
                <DataGrid
                  onRowClick={handleRow}
                  getRowClassName={(params) =>
                    `clickable ${isUnseen(params.row.id) ? 'unread' : params.row.is_archived ? 'archived' : ''}`
                  }
                  loading={loading}
                  columns={columns}
                  rows={deals || []}
                  disableColumnFilter
                  disableColumnSelector
                  // components={{
                  //   Toolbar: QuickSearchToolbar,
                  // }}
                  hideFooterSelectedRowCount
                  disableSelectionOnClick
                  rowsPerPageOptions={[10]}
                  autoHeight
                  pagination
                  page={queryParams.page - 1}
                  pageSize={queryParams.per_page}
                  rowCount={totalDeals || 0}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                  sortModel={
                    queryParams.sort_by && queryParams.sort_order
                      ? [{ field: queryParams.sort_by, sort: queryParams.sort_order }]
                      : []
                  }
                  sx={{
                    '& .datagrid-header': {
                      backgroundColor: '#F4F6F8',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      borderRadius: 0,
                    },
                    border: 'none',
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        ) : !loading ? (
          <Board data={layoutDealsForBoard()} onItemMovement={handleBoardItemMovement} />
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              height: 200,
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Page>
  );
}
