import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Card, CardContent, TextField, debounce } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as dealsActions from '../models/deals/actions';
import useLocales from '../hooks/useLocales';
import { selectors as dealsSelectors } from '../models/deals/reducers';
import SearchIcon from './Icons/SearchIcon';
import { dealsColumns } from '../pages/deals/DealsView';

export default function DealsList({ id, idKey }) {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deals = useSelector(dealsSelectors.deals);
  const totalDeals = useSelector(dealsSelectors.totalDeals);
  const loading = useSelector(dealsSelectors.loading);

  const [queryParams, setQueryParams] = useState({
    per_page: 99,
    page: 1,
    name: null,
    [idKey]: id,
    include_archived: true,
  });

  useEffect(() => {
    setQueryParams({ ...queryParams, [idKey]: id });
  }, [id]);

  useEffect(() => {
    if (queryParams[idKey]) {
      dispatch(dealsActions.getDealsRequest(queryParams));
    }
  }, [queryParams]);

  const onSearch = (e) => {
    const { value } = e.target;
    setQueryParams({ ...queryParams, name: value });
  };

  const handleRow = (params) => {
    navigate(`/maktb/crm/deals/${params.row.id}`);
  };

  const handlePageChange = (newPage) => {
    setQueryParams({ ...queryParams, page: newPage + 1 });
  };

  const columns = dealsColumns(translate, languageCode);

  return (
    <Card>
      <CardContent sx={{ p: 0 }}>
        <Box sx={{ p: 3 }}>
          <TextField
            fullWidth
            placeholder={translate('deal.searchByNames')}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            defaultValue={queryParams.name}
            onChange={debounce(onSearch, 200)}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            onRowClick={handleRow}
            getRowClassName={`clickable`}
            loading={loading}
            columns={columns}
            rows={deals || []}
            disableColumnFilter
            disableColumnSelector
            hideFooterSelectedRowCount
            disableSelectionOnClick
            rowsPerPageOptions={[10]}
            autoHeight
            pagination
            page={queryParams.page - 1}
            pageSize={queryParams.per_page}
            rowCount={totalDeals || 0}
            paginationMode="server"
            onPageChange={handlePageChange}
            sx={{
              '& .datagrid-header': {
                backgroundColor: '#F4F6F8',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: 0,
              },
              border: 'none',
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
