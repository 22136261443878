// @mui
import { Container, Typography, Box, Grid, Card, CardContent } from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
import useLocales from '../../../hooks/useLocales';
// components
import Page from '../../../components/Page';
import { ENV, CLIENT_PORT } from '../../../config';
// ----------------------------------------------------------------------

export default function DomainSettings() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const protocol = ENV === 'local' ? 'http' : 'https';

  const currentDomain = localStorage.getItem('tenant_url');
  const subDomain = currentDomain.split('.')[0];
  let url = '';
  if (ENV === 'local') {
    url = `${protocol}://${currentDomain}${CLIENT_PORT}`;
  } else {
    url = `${protocol}://${subDomain}${'.nuzul.app'}`;
  }

  //
  return (
    <Page title="Domain Settings">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('web.domain.title')}
            </Typography>
          </Box>
        </Box>
        <Card>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 3 }}>
              {translate('web.domain.information')}
            </Typography>
            <Typography variant="body1">{translate('web.domain.access')}</Typography>
            <Typography variant="body1">
              <a target="_blank" href={url} rel="noreferrer">
                {url}
              </a>
            </Typography>
            <br />
            {translate('web.domain.contact')}
            <br />
            <Typography variant="body1">
              <br />
              {translate('web.domain.option1')} <br />
              <span style={{ fontWeight: 'bold', color: 'blue' }}>yourcompany</span>.nuzul.app
              <br />
              <br />
              {translate('web.domain.option2')}
              <br />
              www.<span style={{ fontWeight: 'bold', color: 'blue' }}>yourcompany</span>.com
            </Typography>
            <br />
            {/* <Typography variant="body1">{translate('web.domain.contact')}</Typography> */}
            <span style={{ fontWeight: 'bold' }}>{translate('web.domain.email')}</span> <br /> hala@nuzul.app
            <br />
            {/* <span style={{ fontWeight: 'bold' }}>{translate('web.domain.whatsapp')}</span>
            <br /> +96650 */}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
