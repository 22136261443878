import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, List, Dialog, Divider, Button, Typography } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import { selectors as notificationsSelectors } from '../../models/notifications/reducers';
import { EmptyNotifications, NotificationItem } from '../../layouts/dashboard/header/NotificationsPopover';
import * as notificationsActions from '../../models/notifications/actions';
import useLocales from '../../hooks/useLocales';
import Iconify from '../../components/Iconify';
import Pagination from '../../components/Pagination';
import { isNotificationUnseen } from '../../models/notifications/utils';

const DeleteAllModal = ({ open, onClose, onSubmit }) => {
  const { translate } = useLocales();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ minHeight: 100, minWidth: 400, p: 2 }}>
        <Typography variant="h6" sx={{ p: 2, color: '#212B36' }}>
          {translate('notifications.deleteAllConfirmation')}
        </Typography>

        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end' }}>
          <Button autoFocus variant="outlined" sx={{ color: '#212B36', borderColor: '#919eab52' }} onClick={onClose}>
            {translate('cancel')}
          </Button>
          <Button variant="contained" color="error" sx={{ color: 'white', ml: 2 }} onClick={onSubmit}>
            <Iconify icon="eva:trash-2-outline" width={20} height={20} />
            {translate('notifications.deleteAll')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default function NotificationsView() {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const theme = useTheme();

  const notifications = useSelector(notificationsSelectors.paginatedNotifications);
  const getLoading = useSelector((state) => state.notifications.getLoading);

  const [openDeleteAllModal, setOpenDeleteAllModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const notificationsInterval = 1000 * 3;

  const paperBackground = theme.palette.background.paper;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(notificationsActions.getPaginatedNotificationsRequest({ page }));
  };

  const handleMarkAllAsRead = () => {
    dispatch(notificationsActions.markAllAsReadRequest());
  };

  const handleDeleteAll = () => {
    dispatch(notificationsActions.deleteAllNotificationsRequest());
    setOpenDeleteAllModal(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (document.visibilityState === 'visible' && !getLoading) {
        dispatch(notificationsActions.getPaginatedNotificationsRequest({ page: currentPage }));
      }
    }, notificationsInterval);

    return () => clearInterval(interval);
  }, [currentPage, getLoading]);

  useEffect(() => {
    dispatch(notificationsActions.getPaginatedNotificationsRequest({ page: currentPage }));
  }, []);

  return (
    <Box
      sx={{
        maxWidth: '48rem',
        margin: 'auto',
        '& .MuiListItemButton-root': {
          margin: '1rem 2rem',
          borderRadius: 1,
          boxShadow: 1,
        },
        background: alpha(paperBackground, 0.6),
        borderRadius: 1,
        boxShadow: 1,
      }}
    >
      <DeleteAllModal
        open={openDeleteAllModal}
        onClose={() => setOpenDeleteAllModal(false)}
        onSubmit={handleDeleteAll}
      />
      {(notifications?.data || []).length > 0 && (
        <>
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={handleMarkAllAsRead}
                sx={{
                  color:
                    (notifications?.data || []).filter((notification) => isNotificationUnseen(notification)).length > 0
                      ? 'primary'
                      : 'text.secondary',
                  borderColor:
                    (notifications?.data || []).filter((notification) => isNotificationUnseen(notification)).length > 0
                      ? 'primary'
                      : 'text.secondary',
                }}
                variant="outlined"
              >
                <Iconify icon="eva:radio-button-off-outline" width={25} height={25} />
                <Typography sx={{ p: 1 }} variant="subtitle2">
                  {translate('notifications.markAllAsRead')}
                </Typography>
              </Button>
            </Box>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={() => setOpenDeleteAllModal(true)} variant="outlined" color="error">
                <Iconify icon="eva:trash-2-outline" width={25} height={25} />
                <Typography sx={{ p: 1 }} variant="subtitle2">
                  {translate('notifications.deleteAll')}
                </Typography>
              </Button>
            </Box>
          </Box>
          <Divider />
        </>
      )}
      <List
        sx={{
          width: '100%',
          maxWidth: '100%',
        }}
      >
        {(notifications?.data || []).map((notification) => (
          <NotificationItem mode="full" key={notification.id} notification={notification} onClose={() => {}} />
        ))}
        {(notifications?.data || []).length === 0 && <EmptyNotifications />}
      </List>
      {notifications?.meta?.last_page > 1 && <Pagination meta={notifications?.meta} onPageChange={handlePageChange} />}
    </Box>
  );
}
