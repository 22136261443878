// @mui
import { Typography, Box, Avatar, Grid, Button } from '@mui/material';

// hooks
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useSettings from '../../../hooks/useSettings';
import useLocales from '../../../hooks/useLocales';

// components
import { ListItem } from './Workspaces.element';
import { TENANT_ROLES } from '../../../utils/constants';

import * as userActions from '../../../models/user/actions';
import ConfirmationDialog from './ConfirmationDialog';
// ----------------------------------------------------------------------

export default function WorkspaceListItem({ workspace }) {
  const { themeDirection } = useSettings();
  const { translate } = useLocales();

  const isArabic = themeDirection === 'rtl';
  const dispatch = useDispatch();
  const [awaitingConfirmation, setAwaitingConfirmation] = useState(false);

  const {
    company_role: { role_id: roleId, name_ar: roleAr, name_en: roleEn },
    name_ar: nameAr,
    name_en: nameEn,
    logo_url: logoUrl,
  } = workspace;

  const showConfirmationDialog = () => {
    setAwaitingConfirmation(true);
  };

  const handleLeaveWorkspace = (id) => {
    dispatch(userActions.leaveWorkspaceRequest(id));
    dispatch(userActions.getUserWorkspacesRequest());
    setAwaitingConfirmation(false);
  };

  return (
    <ListItem>
      {awaitingConfirmation && (
        <ConfirmationDialog
          workspace={workspace}
          open={awaitingConfirmation}
          onConfirm={() => handleLeaveWorkspace(workspace.id)}
          onClose={() => setAwaitingConfirmation(false)}
        />
      )}
      <Grid container>
        <Grid style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src={logoUrl} alt={isArabic ? nameAr : nameEn} style={{ margin: 10 }} />
          <Typography style={{ margin: 10 }}>{isArabic ? nameAr : nameEn}</Typography>
          <Button disabled variant="outlined" style={{ margin: 10 }}>
            {isArabic ? roleAr : roleEn}
          </Button>
        </Grid>
        <Grid style={{ display: 'flex', alignItems: 'center' }}>
          <Grid item>
            {!(roleId === TENANT_ROLES.companyOwner) && (
              <Button variant="contained" onClick={showConfirmationDialog} style={{ margin: 10 }}>
                {translate('workspace.leave')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
}
