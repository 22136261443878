import React, { useEffect, useState } from 'react';

// @mui
import { DataGrid } from '@mui/x-data-grid';
import { Box, Card, CardContent, TextField, debounce } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as propertiesActions from '../../models/properties/actions';
import useLocales from '../../hooks/useLocales';
import { selectors as propertiesSelectors } from '../../models/properties/reducers';

import SearchIcon from '../../components/Icons/SearchIcon';
import { propertiesColumns } from '../properties/PropertiesView';

export default function ContactOwnedPropertiesList({ contactId }) {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const properties = useSelector(propertiesSelectors.properties);
  const getPropertiesLoading = useSelector(propertiesSelectors.getPropertiesLoading);
  const totalProperties = useSelector(propertiesSelectors.totalProperties);

  const [queryParams, setQueryParams] = useState({
    per_page: 99,
    page: 1,
    search: null,
    owner_id: contactId,
    include_archived: true,
  });

  useEffect(() => {
    setQueryParams({ ...queryParams, owner_id: contactId });
  }, [contactId]);

  useEffect(() => {
    if (queryParams.owner_id) {
      dispatch(propertiesActions.getPropertiesRequest(queryParams));
    }
  }, [queryParams]);

  const onSearch = (e) => {
    const { value } = e.target;
    setQueryParams({ ...queryParams, search: value });
  };
  const handleRow = (params) => {
    navigate(`/maktb/properties/${params.row.id}`);
  };
  const handlePageChange = (newPage) => {
    setQueryParams({ ...queryParams, page: newPage + 1 });
  };

  const columns = propertiesColumns(translate, languageCode);

  return (
    <Card>
      <CardContent sx={{ p: 0 }}>
        <Box sx={{ p: 3 }}>
          <TextField
            fullWidth
            placeholder={translate('unit.searchLabel')}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            defaultValue={queryParams.search}
            onChange={debounce(onSearch, 200)}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            loading={getPropertiesLoading}
            onRowClick={handleRow}
            getRowClassName={`clickable`}
            columns={columns}
            rows={properties || []}
            disableColumnFilter
            disableColumnSelector
            hideFooterSelectedRowCount
            disableSelectionOnClick
            rowsPerPageOptions={[10]}
            autoHeight
            pagination
            page={queryParams.page - 1}
            pageSize={queryParams.per_page}
            rowCount={totalProperties || 0}
            paginationMode="server"
            onPageChange={handlePageChange}
            sx={{
              '& .datagrid-header': {
                backgroundColor: '#F4F6F8',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: 0,
              },
              border: 'none',
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
