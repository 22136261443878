/* eslint-disable import/no-named-as-default */
import { takeLatest, all, call, put } from 'redux-saga/effects';
import * as actions from './actions';
import CouponServices from './services';

export function* getCouponsSaga() {
  // try {
  //   const response = yield call(CouponServices.getProducts);
  //   yield put(actions.getProductsSuccess(response.data));
  // } catch (err) {
  //   yield put(actions.getProductsFailure(err));
  // }
}

export function* couponSaga() {
  yield all([]);
}
