import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Typography, Grid } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';

// components
import Page from '../../../components/Page';

import * as userActions from '../../../models/user/actions';
import { selectors as userSelectors } from '../../../models/user/reducers';
import { InvitationsContainer } from './Invitations.element';
import InvitationListItem from './ListItem';
// ----------------------------------------------------------------------

export default function Invitations() {
  const { translate } = useLocales();
  const dispatch = useDispatch();

  const userInvitations = useSelector(userSelectors.invitations);

  useEffect(() => {
    dispatch(userActions.getUserInvitationsRequest());
  }, [dispatch]);

  return (
    <Page title={translate('invitations')}>
      <Grid container>
        <Grid item md={2} lg={2} sm={2} xs={0.5} />
        <Grid item md={8} lg={8} sm={8} xs={11}>
          <Typography variant="h3" component="h1" paragraph>
            {translate('invitations')}
          </Typography>
          <InvitationsContainer>
            {userInvitations?.map((invitation) => (
              <InvitationListItem key={invitation.id} invitation={invitation} />
            ))}
          </InvitationsContainer>
        </Grid>
        <Grid item md={2} xl={2} lg={2} sm={2} xs={0.5} />
      </Grid>
    </Page>
  );
}
