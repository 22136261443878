import { Box, Button, Dialog, Divider, Typography, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as teamActions from '../../models/team/actions';

import useLocales from '../../hooks/useLocales';

const MemberRemoveModal = ({ open, onClose, onSubmit, member }) => {
  const { translate } = useLocales();
  const dispatch = useDispatch();

  const callback = (type) => {
    if (type === 'success') {
      toast(translate('member.successRemoveMember'), { type: 'success' });
      dispatch(teamActions.getMembersRequest());
    } else {
      toast(translate('member.failureRemoveMember'), { type: 'error' });
    }
  };

  const handleRemoveMember = (deleteAll) => {
    dispatch(teamActions.removeMemberRequest({ callback, member, deleteAll }));
  };

  return (
    <Dialog open={open}>
      <Box sx={{ minHeight: 100, minWidth: 400, p: 2 }}>
        <Typography variant="h6" sx={{ p: 2, color: '#212B36' }}>
          {translate('member.removeMemberConfirmation')}
        </Typography>

        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end' }}>
          <Button autoFocus variant="outlined" sx={{ color: '#212B36', borderColor: '#919eab52' }} onClick={onClose}>
            {translate('cancel')}
          </Button>
          <Button
            variant="outlined"
            color="error"
            sx={{ color: 'error', ml: 2 }}
            onClick={() => {
              handleRemoveMember(false);
              onSubmit();
            }}
          >
            {translate('remove')}
          </Button>
          {/* <Button
            variant="contained"
            color="error"
            sx={{ color: 'white', ml: 2 }}
            onClick={() => {
              handleRemoveMember(true);
              onSubmit();
            }}
          >
            {translate('teams.removeAll')}
            <Tooltip title={translate('teams.removeAllInfo')}>
              <InfoOutlinedIcon sx={{ ml: 1 }} />
            </Tooltip>
          </Button> */}
        </Box>
      </Box>
    </Dialog>
  );
};

export default MemberRemoveModal;
