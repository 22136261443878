import React, { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import ImageSelector from '../../../components/ImageSelector';
import useLocales from '../../../hooks/useLocales';
import useAsyncAction from '../../../hooks/useAsyncAction';
import UtilsServices from '../../../models/utils/services';
import UsersServices from '../../../models/user/services';
import WorkspaceService from '../../../models/workspace/services';

const LogoSelector = ({ formik }) => {
  const { translate } = useLocales();
  const [isLoading, setLoading] = useState(false);

  const [uploadCover, isUploading] = useAsyncAction(
    async (cover) =>
      // upload here
      UtilsServices.getPresignedUrl({ reference_id: formik.values.id, model: 'tenant', extension: 'png' })
        .then((response) => {
          setLoading(true);
          return response.data;
        })
        .then((data) => {
          UtilsServices.uploadingToAPresignedUrl(data.presigned_url, cover)
            .then((response) => response)
            .then((upload) => {
              setLoading(false);
              formik.setFieldValue('logo_url', data.url);

              console.log('Upload', upload);
              WorkspaceService.updateWorkspaceLogo({ logo_url: data.url });
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        })
        .catch((error) => {
          console.error('Error:', error);
        }),
    [formik.values.id, formik.values.logo_url]
  );

  const handleCoverUpload = (files) => {
    uploadCover(files[0]);
  };

  const handleCoverRemove = (e, id) => {
    e.stopPropagation();

    setLoading(true);
    WorkspaceService.updateWorkspaceLogo({ logo_url: null })
      .then((response) => response)
      .then((upload) => {
        setLoading(false);
        formik.setFieldValue('logo_url', null);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <ImageSelector
      name="cover"
      error={formik.touched.logo_url && formik.errors.logo_url}
      value={[]}
      onFiles={handleCoverUpload}
      multiple={false}
    >
      {(isDraggingOver, handleBrowse) => (
        <Box
          sx={{
            cursor: 'pointer',
            height: 250,
            width: 250,
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 1,
            border: '1px dashed #BCBCBC',
          }}
          onClick={handleBrowse}
        >
          {isLoading && <CircularProgress />}
          {formik.values.logo_url && (
            <>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  fontColor: 'white',
                  color: '#fff',
                  background: '#161C24',
                  opacity: 0.72,
                  height: 30,
                  width: 30,
                  mr: 0.5,
                  mt: 0.5,
                }}
                onClick={(e) => handleCoverRemove(e, formik.values.id)}
              >
                <CloseRoundedIcon sx={{ height: 16, width: 16 }} />
              </IconButton>
              <img
                style={{
                  borderRadius: 8,
                }}
                src={formik.values.logo_url}
                alt="logo"
              />
            </>
          )}
          {!formik.values.logo_url && (
            <Typography sx={{ color: '#637381' }}>
              {isDraggingOver ? 'Drop your image' : translate('Upload a logo')}
            </Typography>
          )}
        </Box>
      )}
    </ImageSelector>
  );
};

export default LogoSelector;
