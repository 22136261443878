import { HOST_API, PORT_API, ENV } from '../../config';
import axios from '../../utils/axios';
import utilsServices from '../utils/services';

const tenantUrl = localStorage.getItem('tenant_url');

class ContactServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getContacts = async (queryParams) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/contacts`, {
        params: queryParams,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getContact = async (id) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/contacts/${id}`, {
        headers: this.getHeaders(),
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  createContact = async ({ phone, code, ...payload }) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/contacts`, {
        ...payload,
        mobile_number: code.replace(/\+/, '') + phone,
        district_id: null,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  editContact = async ({ id, values }) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/contacts/${id}`, values);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  search = async (name, query) => {
    const params = new URLSearchParams({
      name,
      ...query,
    });

    try {
      return axios.get(`${this.getTenantUrl()}/api/contacts?${params.toString()}`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  toggleContactArchive = async (id) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/contacts/${id}/archive`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { ContactServices };
export default new ContactServices();
