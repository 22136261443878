import * as React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import useLocales from '../hooks/useLocales';
import { ENV, CLIENT_PORT, CLIENT_DOMAIN } from '../config';
import { selectors as userSelectors } from '../models/user/reducers';

export default function WorkspaceWebsiteUrl() {
  const protocol = ENV === 'local' ? 'http' : 'https';

  const workspace = useSelector(userSelectors.workspace);
  const clientDomain = workspace?.client_domain;

  let url = '';

  if (ENV === 'local') {
    url = `${protocol}://${clientDomain}${CLIENT_PORT}`;
  } else {
    url = `${protocol}://${clientDomain}`;
  }

  const { translate } = useLocales();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: 10,
        border: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        paddingLeft: 14,
        paddingRight: 14,
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 12,
        borderColor: 'white',
        backgroundColor: 'rgba(255, 255, 255,0.6)',
        minWidth: 200,
        minHeight: 50,
      }}
    >
      <p>{translate('Visit your website')}</p>
      {!workspace?.client_domain && <CircularProgress size={15} />}
      {workspace?.client_domain && (
        <a href={url} target="_blank" rel="noreferrer" style={{ fontSize: 12 }}>
          {url}
        </a>
      )}
    </div>
  );
}
