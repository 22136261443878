import { createAction } from '@reduxjs/toolkit';

export const getWorkspaceInformationRequest = createAction('GET_WORKSPACE_INFORMATION_REQUEST');

export const getWorkspaceInformationSuccess = createAction('GET_WORKSPACE_INFORMATION_SUCCESS');

export const getWorkspaceInformationFailure = createAction('GET_WORKSPACE_INFORMATION_FAILURE');

export const editWorkspaceInformationRequest = createAction('EDIT_WORKSPACE_INFORMATION_REQUEST');

export const editWorkspaceInformationSuccess = createAction('EDIT_WORKSPACE_INFORMATION_SUCCESS');

export const editWorkspaceInformationFailure = createAction('EDIT_WORKSPACE_INFORMATION_FAILURE');
