import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Typography, Grid, Container, Card, CardContent } from '@mui/material';

// hooks
import useLocales from '../../../hooks/useLocales';

// components
import Page from '../../../components/Page';

import * as userActions from '../../../models/user/actions';

import { selectors as userSelectors } from '../../../models/user/reducers';
import WorkspaceListItem from './ListItem';
// ----------------------------------------------------------------------

export default function Workspaces() {
  const { translate } = useLocales();
  const dispatch = useDispatch();

  const userWorkspaces = useSelector(userSelectors.workspaces);

  useEffect(() => {
    dispatch(userActions.getUserWorkspacesRequest());
  }, [dispatch]);

  return (
    <Page title={translate('workspaces')}>
      <Container maxWidth={'md'}>
        <Typography variant="h3" component="h1" paragraph>
          {translate('workspaces')}
        </Typography>
        <Card sx={{ p: 2, mb: 2 }}>
          <CardContent>
            {userWorkspaces?.map((workspace) => (
              <WorkspaceListItem key={workspace.id} workspace={workspace} />
            ))}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
