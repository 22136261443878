import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
  createLoading: false,
  getLoading: false,
  notes: null,
  note: null,
};

const reducer = createReducer(initialState, {
  [actions.createNoteRequest]: (state) => {
    state.createLoading = true;
  },
  [actions.createNoteSuccess]: (state, action) => {
    state.createLoading = false;
    state.note = action.payload.data;
  },
  [actions.createNoteFailure]: (state) => {
    state.createLoading = false;
  },
  [actions.getNotesRequest]: (state) => {
    state.getLoading = true;
  },
  [actions.getNotesSuccess]: (state, action) => {
    state.getLoading = false;
    state.notes = action.payload.data;
  },
  [actions.getNotesFailure]: (state) => {
    state.getLoading = false;
  },
  [actions.editNoteRequest]: (state) => {
    state.getLoading = true;
  },
  [actions.editNoteSuccess]: (state) => {
    state.getLoading = false;
  },
  [actions.editNoteFailure]: (state) => {
    state.getLoading = false;
  },
  [actions.deleteNoteRequest]: (state) => {
    state.getLoading = true;
  },
  [actions.deleteNoteSuccess]: (state) => {
    state.getLoading = false;
  },
  [actions.deleteNoteFailure]: (state) => {
    state.getLoading = false;
  },
});

const createLoading = (state) => state.notes.createLoading;
const getLoading = (state) => state.notes.getLoading;
const notes = (state) => state.notes.notes;
const note = (state) => state.notes.note;

const selectors = {
  createLoading,
  getLoading,
  notes,
  note,
};

export { initialState, reducer, selectors };
export default reducer;
