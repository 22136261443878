import { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  Link,
  IconButton,
  Tooltip,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { ar, enUS } from 'date-fns/locale';
import { Loader } from '../../components/Loader';
import ImageSelector from '../../components/ImageSelector';
import Iconify from '../../components/Iconify';
import useLocales from '../../hooks/useLocales';
import { selectors as notesSelectors } from '../../models/notes/reducers';
import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';
import * as notesActions from '../../models/notes/actions';
import useSettings from '../../hooks/useSettings';
import EditNoteModal from './EditNoteModal';
import DeleteNoteModal from './DeleteNoteModal';
// eslint-disable-next-line import/no-named-as-default
import UtilServices from '../../models/utils/services';

const Notes = ({ id, type, product = 'office' }) => {
  const { translate } = useLocales();
  const createLoading = useSelector(notesSelectors.createLoading);
  const dispatch = useDispatch();
  const notes = useSelector(notesSelectors.notes);
  const getLoading = useSelector(notesSelectors.getLoading);

  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [isAttachingFiles, setIsAttachingFiles] = useState(false);
  const selectedProduct = localStorage.getItem('selected_product');

  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState(null);

  const { themeDirection } = useSettings();
  const isArabic = themeDirection === 'rtl';

  const [initialAttachments, setInitialAttachments] = useState([]);
  const pendingAttachmentsLocalStorageKey = `attachments-${id}-${type}-${product}`;

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);

  const hasActiveSubscription = activeSubscriptions?.find((sub) => sub.is_trial === false);

  useEffect(() => {
    const savedAttachments = JSON.parse(localStorage.getItem(pendingAttachmentsLocalStorageKey)) || [];
    setInitialAttachments(savedAttachments);
  }, [pendingAttachmentsLocalStorageKey]);

  useEffect(() => {
    localStorage.setItem(pendingAttachmentsLocalStorageKey, JSON.stringify(initialAttachments));
  }, [initialAttachments, pendingAttachmentsLocalStorageKey]);

  const callback = (resultType, error) => {
    if (resultType === 'success') {
      toast(translate('notes.noteSent'), {
        type: 'success',
        autoClose: 700,
      });
      formik.resetForm();
      dispatch(notesActions.getNotesRequest({ type, id, product }));
      setInitialAttachments([]);
    } else {
      toast('Failed to send note', { type: 'error' });
    }
  };

  const editCallback = (resultType, error) => {
    if (resultType === 'success') {
      toast(translate('notes.noteUpdated'), {
        type: 'success',
        autoClose: 700,
      });
      formik.resetForm();
      dispatch(notesActions.getNotesRequest({ type, id, product }));
    } else {
      toast('Failed to update note', { type: 'error' });
    }
  };

  const deleteCallback = (resultType, error) => {
    if (resultType === 'success') {
      toast(translate('notes.noteDeleted'), {
        type: 'success',
        autoClose: 700,
      });
      formik.resetForm();
      dispatch(notesActions.getNotesRequest({ type, id, product }));
    } else {
      toast('Failed to delete note', { type: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      note: '',
      attachment: initialAttachments,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      note: Yup.string().required(),
    }),
    onSubmit: (values) => {
      dispatch(
        notesActions.createNoteRequest({
          id,
          type,
          product: selectedProduct === 'maktb' ? 'office' : selectedProduct,
          ...values,
          callback,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(notesActions.getNotesRequest({ type, id, product }));
  }, [id]);

  const handleUpdateNote = (content) => {
    if (!(content.note === noteToEdit.content))
      dispatch(notesActions.editNoteRequest({ id: noteToEdit.id, content, editCallback }));

    setShowEditNoteModal(false);
  };

  const handleDeleteNote = () => {
    dispatch(notesActions.deleteNoteRequest({ id: noteToDelete.id, deleteCallback }));
    setShowDeleteNoteModal(false);
  };

  const handleAttachmentUpload = (files) => {
    setIsAttachingFiles(true);

    UtilServices.uploadFiles(files)
      .then((response) => {
        setIsAttachingFiles(false);
        const newAttachments = [...formik.values.attachment, ...response.data.map((x) => x.url)];
        setInitialAttachments(newAttachments);
        formik.setFieldValue('attachment', newAttachments);
      })
      .catch(() => {
        setIsAttachingFiles(false);
        toast('Failed to upload attachment', { type: 'error' });
      });
  };

  const handleDeleteAttachment = (url) => {
    const updatedAttachments = formik.values.attachment.filter((x) => x !== url);
    setInitialAttachments(updatedAttachments);
    formik.setFieldValue('attachment', updatedAttachments);
  };

  const groupedNotes = notes?.reduce((acc, note) => {
    const date = format(new Date(note.created_at), 'M/d/yyyy');
    if (!acc[date]) acc[date] = [];
    acc[date].push(note);
    return acc;
  }, {});

  return (
    <Grid item xs={12}>
      <DeleteNoteModal
        open={showDeleteNoteModal}
        onClose={() => {
          setShowDeleteNoteModal(false);
          setNoteToDelete(null);
        }}
        onSubmit={handleDeleteNote}
      />
      <EditNoteModal
        open={showEditNoteModal}
        onClose={() => setShowEditNoteModal(false)}
        note={noteToEdit}
        onSubmit={handleUpdateNote}
      />
      <Card sx={{ backgroundColor: '#EEEEEE', border: '1px solid #D9D9D9' }}>
        <CardContent sx={{ p: 2 }}>
          <Typography variant="h6">{translate('notes.notes')}</Typography>

          <Typography variant="caption" paragraph sx={{ color: '#919EAB' }}>
            {translate('notes.notesDescription')}
          </Typography>

          <TextField
            name="note"
            type="text"
            multiline
            rows={4}
            sx={{ mb: 2 }}
            fullWidth
            value={formik.values.note}
            onChange={formik.handleChange}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Box>
              <ImageSelector
                name="note_attachment"
                error={formik.touched.attachment && formik.errors.attachment}
                value={[]}
                onFiles={handleAttachmentUpload}
                multiple
              >
                {(isDraggingOver, handleBrowse) => (
                  <Button
                    variant="outlined"
                    sx={{
                      color: '#212B36',
                      borderColor: '#919eab52',
                      fontWeight: 'normal',
                      display: !hasActiveSubscription ? 'none' : 'flex',
                    }}
                    onClick={handleBrowse}
                  >
                    <Iconify icon="mdi:paperclip" style={{ color: '#919EAB', fontSize: '20px', marginRight: '5px' }} />
                    {translate('attachFile')}
                  </Button>
                )}
              </ImageSelector>
            </Box>
            <Button
              variant="contained"
              sx={{ color: 'white', marginLeft: 2 }}
              onClick={formik.submitForm}
              disabled={!formik.isValid || !formik.dirty || createLoading}
            >
              {!createLoading && translate('notes.logNoteButton')}
              {createLoading && <Loader />}
            </Button>
          </Box>
          {formik.values.attachment.length > 0 && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(60px,1fr))',
                gap: 1,
                mt: 1,
              }}
            >
              {isAttachingFiles && <Loader />}
              {formik.values.attachment.map((url, i) => (
                <Box
                  sx={{
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 1,
                    p: 1,
                    boxShadow: (theme) => theme.shadows[1],
                  }}
                  key={i}
                >
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      fontColor: 'white',
                      color: '#fff',
                      background: '#161C24',
                      opacity: 0.72,
                      height: 30,
                      width: 30,
                    }}
                    onClick={() => handleDeleteAttachment(url)}
                  >
                    <CloseRoundedIcon sx={{ height: 16, width: 16 }} />
                  </IconButton>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#919EAB', textDecoration: 'underline' }}
                    >
                      <img src={url} alt="attachment" style={{ maxWidth: '50px', maxHeight: '50px' }} />
                    </a>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            {!getLoading && notes?.length > 0 && (
              <Box sx={{ backgroundColor: '#EEEEEE', mt: 2, maxHeight: '400px', overflowY: 'auto' }}>
                <Box sx={{ p: 2 }}>
                  {Object.keys(groupedNotes)
                    .sort((a, b) => new Date(b) - new Date(a))
                    .map((date) => (
                      <Box key={date} sx={{ mb: 2 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 'normal',
                            color: '#919EAB',
                            mb: 1,
                            textAlign: 'start',
                            borderBottom: '1px solid #D9D9D9',
                          }}
                        >
                          {format(parse(date, 'M/d/yyyy', new Date()), 'd MMMM yyyy', { locale: isArabic ? ar : enUS })}
                        </Typography>
                        {groupedNotes[date]
                          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                          .map((note) => (
                            <Box
                              key={note.id}
                              sx={{
                                mb: 2,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom: '1px solid #D9D9D9',
                              }}
                            >
                              <Avatar sx={{ mr: 2 }}>
                                {note.user.name[0] + (note.user.name.split(' ')[1] || ' ')[0]}
                              </Avatar>
                              <Box sx={{ width: '100%' }}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <Typography variant="subtitle2" sx={{ color: '#919EAB' }}>
                                    {note.user.name}
                                  </Typography>
                                  <Typography variant="subtitle2" sx={{ color: '#919EAB' }}>
                                    -
                                  </Typography>
                                  <Typography variant="subtitle2" sx={{ color: '#919EAB', fontWeight: 'normal' }}>
                                    <span style={{ color: '#919EAB' }}>
                                      {format(new Date(note.created_at), 'h:mm a')}
                                    </span>
                                  </Typography>
                                </Box>
                                <Typography sx={{ marginTop: 1, minHeight: '30px' }} variant="body1">
                                  {note.content}
                                </Typography>

                                {note.uploads?.length > 0 && (
                                  <Box
                                    sx={{
                                      display: 'grid',
                                      gap: 1,
                                      mt: 1,
                                      mb: 2,
                                      borderRadius: 1,
                                      p: 1,
                                      gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
                                    }}
                                  >
                                    {note.uploads.map(({ url }, index) => (
                                      <Link
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          bgcolor: (theme) => alpha(theme.palette.grey[100], 0.5),
                                          '&:hover': {
                                            bgcolor: (theme) => theme.palette.action.hover,
                                          },
                                          p: 1,
                                          textDecoration: 'underline',
                                        }}
                                        key={url}
                                      >
                                        <Iconify
                                          icon="mdi:paperclip"
                                          style={{ color: '#919EAB', fontSize: '20px', marginRight: '5px' }}
                                        />
                                        <img
                                          src={url}
                                          alt="attachment"
                                          style={{ maxWidth: '70px', maxHeight: '70px' }}
                                        />
                                      </Link>
                                    ))}
                                  </Box>
                                )}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Box>
                                    {note.edited && (
                                      <Tooltip
                                        title={format(new Date(note.updated_at), 'd MMMM yyyy h:mm a', {
                                          locale: isArabic ? ar : enUS,
                                        })}
                                      >
                                        <Typography variant="caption" sx={{ color: '#919EAB' }}>
                                          {translate('notes.noteEdited')}
                                        </Typography>
                                      </Tooltip>
                                    )}
                                  </Box>
                                  {note.can_delete && (
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                      <Button
                                        size="small"
                                        sx={{
                                          color: '#808080',
                                          textDecoration: 'underline',
                                          fontWeight: 'bold',
                                          textTransform: 'none',
                                        }}
                                        onClick={() => {
                                          setNoteToEdit(note);
                                          setShowEditNoteModal(true);
                                        }}
                                      >
                                        {translate('notes.editNote')}
                                      </Button>
                                      <Button
                                        size="small"
                                        sx={{
                                          color: '#808080',
                                          textDecoration: 'underline',
                                          fontWeight: 'normal',
                                          textTransform: 'none',
                                        }}
                                        onClick={() => {
                                          setNoteToDelete(note);
                                          setShowDeleteNoteModal(true);
                                        }}
                                      >
                                        {translate('notes.deleteNote')}
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    ))}
                </Box>
              </Box>
            )}
            {getLoading && <Loader />}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Notes;
