import { Box, Button, Card, CardContent, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ImageSelector from '../../../components/ImageSelector';
import SelectPhotosImage from '../../../components/SelectPhotosImage';
import utilsServices from '../../../models/utils/services';
import * as propertiesActions from '../../../models/properties/actions';
import propertiesService from '../../../models/properties/services';
import useLocales from '../../../hooks/useLocales';

const PropertyImagesSelector = ({ name = 'photos', formik }) => {
  const [loadingList, setLoadingList] = useState([]);
  const [urlsMap, setUrlsMap] = useState({});

  const dispatch = useDispatch();
  const { translate } = useLocales();

  const addUrl = (url) => {
    formik.setFieldValue(name, [...formik.values[name], url]);
  };

  const removeUrl = (url) => {
    const photos = formik.values[name];
    formik.setFieldValue(
      name,
      photos.filter((u) => u !== url)
    );
  };

  const setIndexLoading = (index, loading) => {
    const newList = loadingList.slice();
    newList[index] = loading;
    setLoadingList(newList);
  };

  const getPresigned = async () =>
    utilsServices
      .getPresignedUrl({ reference_id: formik.values.id, model: 'property', extension: 'png' })
      .then((response) => response.data);

  const uploadToPresigned = async (presignedUrl, image) => utilsServices.uploadingToAPresignedUrl(presignedUrl, image);

  const linkImageWithProperty = async (url) =>
    propertiesService.setImages(formik.values.id, [...formik.values.photos, url]);

  const upload = async (image, i) => {
    getPresigned()
      .then((data) => {
        addUrl(data.url);

        return uploadToPresigned(data.presigned_url, image)
          .then(() => linkImageWithProperty(data.url))
          .catch((error) => {
            removeUrl(data.url);
            console.error('Error:', error);
          });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  const onFiles = async (files) => {
    console.log(`Upload started for ${files.length} images`);
    const currentImages = formik.values[name];

    // if you want a maximum number of images, do the validation here. TODO
    const images = [...files];
    const promises = [];

    setLoadingList([...Array(currentImages.length).fill(false), ...Array(images.length).fill(true)]);

    images.forEach((image, i) => {
      promises.push(upload(image, currentImages.length + i).then(() => setIndexLoading(i, false)));
    });

    await Promise.all(promises).then(() => {
      console.log(`Successfully uploaded ${files.length} images`);
    });
  };

  const BrowseButton = ({ handleBrowse }) => (
    <Button onClick={handleBrowse} sx={{ textDecoration: 'underline' }}>
      browse
    </Button>
  );

  const onUrlRemove = (t, { data: unit }, i, url) => {
    setIndexLoading(i, false);

    if (unit.images.length === formik.values.photos.length) {
      toast(translate("API error, couldn't remove"), { type: 'error' });
      return;
    }

    if (t === 'error') {
      toast(translate("Couldn't remove image"), { type: 'error' });
    } else {
      removeUrl(url);
    }
  };

  const handlePropertyRemove = (id, i, url) => {
    setIndexLoading(i, true);

    dispatch(
      propertiesActions.removeUrlsRequest({
        callback: (t, data) => onUrlRemove(t, data, i, url),
        urls: [url],
        id,
      })
    );
  };

  return (
    <ImageSelector
      onFiles={onFiles}
      name={name}
      value={[]}
      error={formik.touched[name] && formik.errors[name]}
      multiple
    >
      {(isDraggingOver, handleBrowse) => (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              width: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px dashed #BCBCBC',
              borderRadius: 2,
            }}
          >
            <Box height={120} width={160}>
              <SelectPhotosImage />
            </Box>
            <Box>
              {isDraggingOver ? (
                <Typography>LEAVE THE CURSOR TO DROP</Typography>
              ) : (
                <>
                  <Typography variant="h6">Select photos</Typography>
                  <Typography sx={{ color: '#637381' }}>
                    Drop files here or click to <BrowseButton handleBrowse={handleBrowse} /> through your machine
                  </Typography>
                </>
              )}
            </Box>
          </Box>

          <br />
          <Grid container spacing={2}>
            {formik.values[name].map((url, i) => (
              <Grid key={i} item>
                {loadingList[i] ? (
                  <Skeleton variant="rectangular" width={72} height={72} />
                ) : (
                  <Card sx={{ width: 72, height: 72 }}>
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        fontColor: 'white',
                        color: '#fff',
                        background: '#161C24',
                        opacity: 0.72,
                        height: 30,
                        width: 30,
                        mr: 0.5,
                        mt: 0.5,
                      }}
                      onClick={() => handlePropertyRemove(formik.values.id, i, url)}
                    >
                      <CloseRoundedIcon sx={{ height: 16, width: 16 }} />
                    </IconButton>
                    <CardContent sx={{ p: '0 !important', border: '1px dashed #BCBCBC', height: 1 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          borderRadius: 1,
                          height: 1,
                        }}
                      >
                        <img
                          style={{
                            borderRadius: 8,
                          }}
                          src={url}
                          alt="property"
                        />
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </ImageSelector>
  );
};

export default PropertyImagesSelector;
