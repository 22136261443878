import { Box, Input } from '@mui/material';
import { useRef, useState } from 'react';

const ImageSelector = ({ name, value, error, children, onDragOver, onFiles, multiple = false }) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const fileInputRef = useRef(null);

  const handleBrowse = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    setIsDraggingOver(true);

    if (onDragOver) {
      onDragOver(e);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const { files } = e.dataTransfer;
    onFiles(files);
  };

  const handleUpload = async (e) => {
    await onFiles(e.target.files);
  };

  return (
    <Box onDragOver={handleDragOver} onDragLeave={() => setIsDraggingOver(false)} onDrop={handleDrop}>
      <Input
        inputProps={{ multiple, accept: 'image/*' }}
        inputRef={fileInputRef}
        value={value}
        name={name}
        error={error}
        type="file"
        onChange={handleUpload}
        style={{ display: 'none' }}
        aria-hidden
        hidden
      />
      {children(isDraggingOver, handleBrowse)}
    </Box>
  );
};

export default ImageSelector;
